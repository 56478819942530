.topLoader {
  text-align: center;
  padding: 10px;
}

.chatContainerArea {
  height: 100%;
  width: 100%;
  display: block;
}
.chatContainArea {
  height: 100%;
  width: 100% !important;
  /* margin-left: 70px;
  margin-right: 70px; */
  padding: 0px 0px 0px;
}

/* left side chat container start*/

.chatContactPanel {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
}
.chatLftContHead {
  display: flex;
  width: auto;
  padding: 10px 16px 10px 70px;
  align-items: center;
  gap: 24px;
  background: #1c1c1c;
}
.inchat {
  display: flex;
  align-items: center;
  gap: 24px;
  width: 100%;
}
.inchatImg {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #606060;
  background-color: #323232;
}
.inchatImg img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  object-fit: cover;
  border: 1px solid #606060;
  background-color: #323232;
}
.searchUser {
  display: flex;
  width: 100%;
  padding: 5px 9px;
  align-items: center;
  gap: 20px;
  border-radius: 8px;
  background: #272727;
}
.searchIcon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}
.searchIcon svg {
  color: #a8a8a8;
}
.serchInput {
  width: 100%;
  flex-shrink: 0;
  flex: 1;
  color: #ffffff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  background-color: transparent;
  outline: none;
  box-shadow: none;
  border: none;
}
.inChatAct {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
}
.inChatAct svg {
  font-size: 14px;
  color: #ffffff;
  height: 22px;
  width: 22px;
}
.chatCnctLstCont {
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: #121212;
  width: 100%;
}
.contactListGroup {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  overflow-y: overlay;
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 0px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.chatFrwUsrLstArea::-webkit-scrollbar,
.chatGrpDtlArea::-webkit-scrollbar,
.selectedPartList::-webkit-scrollbar,
.createGroupCont::-webkit-scrollbar,
.chatConvoMsg::-webkit-scrollbar,
.contactListGroup::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}
.chatFrwUsrLstArea::-webkit-scrollbar-thumb,
.chatGrpDtlArea::-webkit-scrollbar-thumb,
.selectedPartList::-webkit-scrollbar-thumb,
.createGroupCont::-webkit-scrollbar-thumb,
.chatConvoMsg::-webkit-scrollbar-thumb,
.contactListGroup::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 1);
}
.chatFrwUsrLstArea::-webkit-scrollbar-track,
.chatGrpDtlArea::-webkit-scrollbar-track,
.selectedPartList::-webkit-scrollbar-track,
.createGroupCont::-webkit-scrollbar-track,
.chatConvoMsg::-webkit-scrollbar-track,
.contactListGroup::-webkit-scrollbar-track {
  background: rgb(18 18 18 / 70%);
}

.chatContactList {
  display: flex;
  width: calc(100% - 70px);
  padding: 0px 0px 0px 70px;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  transition: all 0.5s;
  position: relative;
}
.chatContactList:hover,
.chatContactList.activeChat {
  background-color: #000000;
}
.contUserImg {
  width: 52px;
  height: 52px;
  position: relative;
}
.usrActStatus {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  top: 3px;
  right: 2px;
  position: absolute;
  background-color: #00eb1f;
}
.unRdMsgCunt {
  background-color: #00a884;
  border-radius: 1rem;
  padding: 0.3rem 0.4rem;
  display: inline-block;
  font-size: 13px;
  line-height: 13px;
  font-weight: 600;
}
.contUserImg img {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  flex-shrink: 0;
  border: 1px solid #606060;
  background-color: #323232;
}
.userDtlWlMsg {
  display: flex;
  width: calc(100% - 85px);
  padding: 12px 20px 12px 0px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-bottom: 0.5px solid #545454;
}
.usrNmDT {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.usrNmIn {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  display: flex;
  align-items: center;
}
.usrNmIn svg {
  height: 18px;
  width: 18px;
  color: #ffffff;
  margin-left: 5px;
}
.msgDTIn {
  color: #707070;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.usrLstMsgDtStus {
  gap: 3px;
  display: flex;
  align-items: center;
  min-height: 20px;
  font-size: 13px;
  line-height: 20px;
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.usrStusIcon {
  display: flex;
  align-items: center;
}
.usrStusIcon svg {
  height: 20px;
  width: 20px;
  color: #707070;
}

.lstMsgShw {
  display: flex;
  flex: 1 1 auto;
  height: auto;
  min-width: 0;
  color: #a9a9a9;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.activeBtn {
  background-color: #606060 !important;
  border: 1px solid #606060;
  box-shadow: 1px 2px 6px -2px #1c1c1c;
}
/* left side chat container end*/

/* right side Conversation start */
.chatConversationPannel {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 80px);
  overflow: hidden;
  position: relative;
  border-left: 0.1px solid #545454;
  background-color: #1c1c1c;
}

.chatConvoContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #000000;
}

.chatConvoHeader {
  display: flex;
  width: auto;
  padding: 8px 70px 8px 16px;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  background: #1c1c1c;
  max-height: 62px;
  border-bottom: 1px solid #1c1c1c;
}
.chatConvoUserInfo {
  display: flex;
  align-items: center;
  gap: 12px;
}
.chatConvoUsrImg img {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  border: 1px solid #606060;
  background-color: #323232;
}
.chatConvoUsrDtl {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.ctCnvUNm {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 112.5% */
}
.ctCnvUDeg {
  color: #c0c0c0;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 116.667% */
}
.chatConvoUserActIcon {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chatConvoUserActIcon svg {
  color: #ffffff;
  height: 24px;
  width: 24px;
}

.chatConvstionConArea {
  flex: 1 1;
  order: 2;
  position: relative;
  background-image: url(../images/bg/chatbg.png);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  overflow-y: overlay;
}

.chatConvoFooter {
  min-height: 50px;
  background-color: #333;
  order: 3;
}
.chatConvoMsg {
  height: calc(100% - 20px);
  width: calc(100% - 86px);
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 10px 70px 10px 16px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.chtMsg {
  position: relative;
  margin-bottom: 10px;
  padding-left: 20px;
  padding-right: 10px;
}
.chtMsg .msg {
  position: relative;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
  max-width: 70%;
  display: inline-flex;
  padding: 6px 10px;
  flex-direction: column;
  align-items: flex-end;
  word-break: break-word;
}
.msg-in {
  float: left;
  border-radius: 0px 8px 8px 8px;
  background-color: #393939;
  color: #fff;
  margin-left: 6px;
}
.msg-in::before {
  content: "";
  position: absolute;
  left: -16px;
  top: 0;
  width: 0;
  height: 0;
  border-left: 16px solid transparent;
  border-right: 0px solid transparent;
  border-top: 9px solid #393939;
  clear: both;
}

.msg-out {
  float: right;
  border-radius: 8px 0px 8px 8px;
  background-color: #003b48;
  color: #fff;
  margin-right: 10px;
}
.msg-out::before {
  content: "";
  position: absolute;
  right: -16px;
  top: 0;
  width: 0;
  height: 0;
  border-left: 16px solid #003b48;
  border-right: 0px solid transparent;
  border-bottom: 9px solid transparent;
  clear: both;
}
.msg-in .msgTxt {
  float: left;
  width: 100%;
  text-align: left;
}
.msg-out .msgTxt {
  float: right;
  width: 100%;
  text-align: left;
}
.msgTxt {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 125% */
  word-break: break-word;
}
.msgMedia {
  height: 100%;
  max-width: 280px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.msgMedia img {
  width: 100%;
  height: 100%;
  max-height: 160px;
  object-fit: cover;
  object-position: top center;
}
.msgMediaTxt {
  text-wrap: wrap;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.msgRDt {
  display: flex;
  align-items: center;
  gap: 5px;
}
.msgDT {
  color: #bbb;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.msgRcpt {
  display: flex;
  align-items: center;
}
.msgRcpt svg {
  height: 20px;
  width: 20px;
  color: #717171;
}

.chatConvoFooter {
  display: inline-flex;
  padding: 5px 70px 5px 16px;
  align-items: center;
  gap: 16px;
}
.chatInputBox {
  width: 100%;
}
.chatInputBox input {
  border-radius: 8px;
  background: #272727;
  height: 30px;
  width: calc(100% - 40px);
  border: none;
  padding: 4px 20px;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0.8px;
}
.chatInputBox input:focus-visible {
  outline: 1px solid #575757;
}
.chatInputEmoje,
.chatInputAttachment,
.chatInputAudio {
  cursor: pointer;
  height: 24px;
  width: 24px;
}
.chatInputEmoje {
  display: none !important;
}
.chatInputEmoje svg,
.chatInputAttachment svg,
.chatInputAudio svg {
  height: 24px;
  width: 24px;
  color: #fff;
}
.topMtChtAra {
  flex: 1 1 auto;
  min-height: 12px;
}
.upImgBigView {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upImgBigView img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
/* right side Conversation End */

.fileUploadInputContainer {
  border: 1px solid #d1d1d1;
  border-radius: 0px;
  padding: 10px;
  position: relative;
  width: -webkit-fill-available;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #fff;
}
.filUpWarning {
  color: #606060;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  text-align: center;
}
.chatEmoji {
  width: 100%;
  height: auto;
}
.chatEmoji aside.EmojiPickerReact.epr-main {
  width: 100% !important;
  height: auto;
  border-radius: 0px;
  border: none;
}

.chatAtchImgArea {
  flex: 1 1;
  order: 2;
  position: relative;
  background-color: #000000;
  overflow: hidden;
  overflow-y: overlay;
}

.d-none {
  display: none !important;
}
.chatAtchImgContainer {
  display: flex;
  flex: 1;
  min-width: 500px;
  height: 100%;
  min-height: 400px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: nowrap;
  align-self: auto;
  order: 0;
}
.chtAtchImgContGroup {
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}
.imgUpDismis {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  padding: 8px 16px;
  flex-direction: row;
  flex-shrink: 1;
  flex-basis: auto;
  min-width: 0;
  order: 0;
  align-self: auto;
  flex-wrap: nowrap;
}
.imgDisWdth,
.imgDismisIoc {
  width: 60px;
  color: #ffffff;
}
.imgUpBView {
  margin: 16px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.chatAtchImgActionGrp {
  padding: 8px 0;
  margin: 0 70px;
  border-top: 1px solid #8696a026;
  display: flex;
  flex: 0 0 82px;
  align-items: center;
  justify-content: space-between;
}
.upldFlSmalPerview {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 0;
  min-width: 0;
  order: 0;
  align-self: auto;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 4px 16px 0;
  box-sizing: border-box;
  flex: 1 1 auto;
}
.dropToUupledImg {
  color: #7d7d7d;
  height: 50px;
  min-width: 150px;
  border: 1px dashed #7d7d7d;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  cursor: grab;
}
.uploadImgSendBtn {
  width: 60px;
  height: 60px;
}
.upldFlImgPrev {
  flex: 0 1 auto;
  min-width: 0;
  overflow-x: overlay;
  white-space: nowrap;
  visibility: visible;
}

.uplodMorImg {
  margin: 8px 5px !important;
  border: 1px solid #d1d7db !important;
  border-radius: 5px !important;
  padding: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  min-width: 52px !important;
  height: 52px !important;
}
.uplodMorImg svg {
  color: #fff;
  height: 28px;
  width: 28px;
}

.atchImgPlcHld {
  margin: 6px 3px 8px;
  border-radius: 5px;
  position: relative;
  z-index: var(--layer-1);
  box-sizing: border-box;
  display: inline-block;
  flex: none;
  overflow: hidden;
  vertical-align: middle;
  cursor: pointer;
}
.atchImgPHActive {
  border: 3px solid #009d7b;
}
.remvImg {
  position: absolute;
  top: -1px;
  z-index: 100;
  color: #e9edef;
  opacity: 0;
  right: -1px;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: right 75ms ease-out, top 75ms ease-out;
}
[role="button"] {
  cursor: pointer;
}
.upledImgShow {
  left: 0;
  overflow: hidden;
  position: relative;
  top: 0;
  z-index: 2;
  width: 52px;
  height: 52px;
}
.upledImgShow img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.upledImgShow:before {
  right: 0;
  background: linear-gradient(
    10deg,
    transparent,
    transparent 45%,
    rgba(10, 20, 26, 0.18) 75%,
    rgba(10, 20, 26, 0.4)
  );
  position: absolute;
  top: 0;
  width: 100%;
  height: 40px;
  content: "";
  opacity: 0;
  transition: opacity 0.1s ease-out;
}
.upledImgShow:after {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
}

.atchImgPlcHld:hover .remvImg {
  opacity: 1;
  background: radial-gradient(circle farthest-side, #00251d, #0045361c);
}
.imgSendBtn {
  border-radius: 50%;
  height: 56px;
  width: 56px;
  background-color: #1c1c1c;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgComment {
  padding: 8px 0;
  position: relative;
  min-height: 56px;
  flex-grow: 0;
  flex-direction: row;
  display: flex;
  flex-shrink: 1;
  flex-basis: auto;
  order: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.imgComment .chatInputBox {
  max-width: 650px;
  margin: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imgComment .chatInputBox input {
  height: 34px;
}
.noPriviewDoc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.noPriviewDoc .noPrevIco {
  max-height: 100px;
  margin-bottom: 20px;
}

.noPrevText {
  color: #545454;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
}

.noPrevFlNam {
  font-size: 14px;
  line-height: 16px;
  color: #545454;
}

/* create chat group start*/
.newChatGrpPanel {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
}
.newChatGrpPanel .chatLftContHead {
  max-height: 46px;
  font-size: 16px;
  color: #fff;
  height: 100%;
}
.chtsrchUsr {
  position: relative;
  z-index: 100;
  box-sizing: border-box;
  display: flex;
  flex: none;
  align-items: center;
  height: 50px;
  padding: 0 16px 0 70px;
}
.srchUsrIpG {
  position: relative;
  box-sizing: border-box;
  flex: 1;
  overflow: hidden;
}
.crtBtnGrp {
  position: relative;
  display: flex;
  flex: none;
  align-items: center;
  height: 72px;
  padding-bottom: 1px;
  color: #fff;
  transition: all 0.5s;
  padding: 0 0 0 70px;
  gap: 15px;
}
.crtBtnGrp:hover {
  background-color: #333333;
}
.cbtgIarea {
  display: flex;
  flex: none;
  justify-content: center;
  width: auto;
}
.crtGrpIcon {
  height: 50px;
  width: 50px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #606060;
  background-color: #606060;
  color: #ffffff;
}
.crtgta {
  padding-right: 30px;
  box-sizing: border-box;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  height: 100%;
  overflow: hidden;
  border-bottom: 1px solid #545454;
}

.txtSeprt {
  height: 72px;
  overflow: hidden;
  display: contents;
}
.txtSeprt p {
  padding: 30px 0 15px 70px;
  box-sizing: border-box;
  height: 72px;
  font-size: 16px;
  color: #606060;
  text-transform: uppercase;
  margin: 0;
}

.newChatGrpPanel .chatContactList:first-child .userDtlWlMsg {
  border-top: 0.5px solid #545454;
}
.createGroupCont {
  overflow: hidden;
  overflow-y: overlay;
}
/* create chat group end*/
/* create Chat Group Dtails start */
.cNwGrpImgNmContener {
  display: flex;
  flex-grow: 1;
  flex-basis: auto;
  position: relative;
  flex-direction: column;
  opacity: 1;
  height: auto;
  order: 0;
}
.cNwGrpImgNmArea {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin: 20px 16px 5px 70px;
  flex: none;
  flex-grow: 1;
  flex-basis: auto;
  flex-shrink: 0;
  gap: 30px;
}
.cNwGrpImgContain {
  /* margin: 0.5rem 2rem; */
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.cNwGrpImgArea {
  /* margin-right: auto;
  margin-left: auto; */
  position: relative;
  width: 150px;
  height: 150px;
}
.cNwGrpAddGrpIco {
  position: relative;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  background-color: #323232;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  border-radius: 50%;
  border: 3px solid #606060;
}
.cNwGrpSvg {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cNwGrpSvg svg {
  height: 90px;
  width: 90px;
  color: #7b7a7a;
}
.cNwGrpSvg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.ipChngGrpImg {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(0 0 0 / 30%);
}
.inputChangeGroupImg {
  display: none;
}
.ipChngGrpImg svg {
  color: #fff;
}
.labelInpImgChng {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.labelInpImgChng svg {
  height: 30px;
  width: 30px;
}
.imgchngHint {
  text-wrap: wrap;
  max-width: 90px;
  text-align: center;
  font-size: 14px;
}
.cNwGrpNmContain {
  flex: 1;
  width: 90%;
}
.chatGroupNmInput {
  width: 100%;
}
.chatGroupNmInput .chtGrpNmTextFild {
  color: #ffffff !important;
  width: 100%;
}
.chtGrpNmTextFild label {
  color: #a9a9a9 !important;
}
.chtGrpNmTextFild input {
  color: #ffffff;
  font-family: Inter;
  font-size: 18px;
}
.MuiInputLabel-root.Mui-focused {
  color: #fff !important;
}
.chtGrpNmTextFild .MuiInput-underline:after {
  border-bottom-color: #fff;
}
.chtGrpNmTextFild .MuiInput-underline:focus,
.chtGrpNmTextFild .MuiInput-underline:before {
  border-bottom-color: #a9a9a9 !important;
}
.chtGrpNmTextFild .MuiInputBase-root-MuiInput-root:before {
  border-bottom-color: #a9a9a9;
}

.grpCretFooter {
  min-height: 60px;
  background-color: #323232;
  display: flex;
  align-items: center;
  justify-content: center;
  order: 3;
}

.selectedPartList {
  min-height: 20px;
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
  padding: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 4px 16px 4px 70px;
  margin-top: 10px;
  flex: none;
  /* flex-grow: 1;
  flex-basis: auto;
  flex-shrink: 0; */
}
.slctedChtUserListGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.slctedChtUser {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  color: #fff;
  gap: 5px;
  margin-bottom: 6px;
  margin-right: 8px;
}
.scuProImg {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.scuProImg img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.scuNm {
  font-size: 12px;
  font-weight: 400;
  color: #a9a9a9;
  cursor: default;
}
.scuRmv {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  color: #a9a9a9;
}
.scuRmv:hover {
  background-color: #ffffff;
  color: #000;
}
.scuRmv svg {
  height: 18px;
  width: 18px;
}
.slctChtUserNone {
  font-size: 14px;
  color: #a9a9a9;
}
.cretGrpButton {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #00adef !important;
  color: #fff !important;
}
.cretGrpButton svg {
  height: 30px;
  width: 30px;
}
/* create Chat Group Dtails end */

/* chat group details start */
.chatFrwUsrLstContainer,
.chatGrpDtlContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 480px;
  background-color: #000000;
  border-left: 0.2px solid #545454;
}
.chatFrwUsrLstHeader,
.chatGrpDtlHeader {
  display: flex;
  width: auto;
  padding: 10px 16px;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  background: #1c1c1c;
  min-height: 46px;
}
.chtGrpHedText {
  font-size: 14px;
  color: #ffffff;
  display: flex;
  align-items: center;
}
.chtGrpHedText svg {
  cursor: pointer;
  margin-right: 5px;
}
.chatFrwUsrLstArea,
.chatGrpDtlArea {
  flex: 1 1;
  order: 2;
  position: relative;
  overflow: hidden;
  overflow-y: overlay;
}
.chatGrpInfoClosBtn button {
  color: #ffffff;
}

.chtGrpNmTextFild input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: rgba(255, 255, 255, 0.5);
}
.gnmTroggleBtn {
  color: #ffffff !important;
}
.gnmTroggleBtn svg {
  height: 18px;
  width: 18px;
}
.chatGrpDtlContainer .cNwGrpImgArea {
  position: relative;
  width: 100px;
  height: 100px;
}

.chatGrpDtlContainer .ipChngGrpImg {
  display: none;
  transition: all 0.5s;
}
.chatGrpDtlContainer .cNwGrpAddGrpIco:hover .ipChngGrpImg {
  display: flex;
}
.chatGrpDtlContainer .chatGrpDtlArea .cNwGrpImgNmArea {
  gap: 10px;
  margin: 20px 16px 5px 16px;
}
.chatGrpDtlContainer .chatContactList {
  padding: 0px 0px 0px 10px;
  width: calc(100% - 10px);
}
.chatGrpDtlContainer .chatGroupNmInput .Mui-disabled.chtGrpNmTextFild::before {
  border-bottom: none !important;
}
.noUnChAvaMsg {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  color: #a9a9a9;
  margin-top: 20%;
}
.clickFilter {
  font-size: 14px;
  cursor: pointer;
  background: #606060;
  border-radius: 4px;
  padding: 4px 8px;
  color: #fff;
  letter-spacing: 0.5px;
  font-weight: 400;
}
.grpMembrsCunt {
  text-align: left;
  font-size: 14px;
  color: #a9a9a9;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}
.addMemBerBtn {
  background-color: #00adef !important;
  color: #fff !important;
  padding: 4px 8px !important;
  border-radius: 6px !important;
  font-family: Inter !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}
.prtcAct {
  position: absolute;
  top: 1px;
  z-index: 100;
  color: #e9edef;
  opacity: 0;
  right: 1px;
  height: 24px;
  width: 24px;
  cursor: pointer;
  transition: right 75ms ease-out, top 75ms ease-out;
}
.chatContactList:hover .prtcAct {
  opacity: 1;
}
.shwGrpParticpent {
  margin-top: 10px;
}
.grpUsrActMenu .MuiPaper-root.MuiPaper-elevation {
  border-radius: 6px;
  border-color: #333;
  background-color: #333333;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}
.grpUsrActMenu ul.MuiList-root {
  background-color: #333333;
  border-radius: 6px;
}
.grpUsrActMenu .MuiButtonBase-root.MuiMenuItem-root {
  color: #ffffff;
  font-size: 14px;
}
.grpUsrActMenu .MuiButtonBase-root.MuiMenuItem-root:hover {
  background-color: #121212;
}

.chatGrpDtlArea .slctedChtUserListGrp {
  width: 100%;
  flex-wrap: wrap;
}
.chatGrpDtlArea .slctedChtUserListGrp .slctedChtUser {
  width: 100%;
  align-items: flex-start;
  gap: 2px;
  justify-content: space-between;
  background-color: #1a1a1a;
  padding: 6px 6px;
  border-radius: 6px;
}
.dlctUsrImgNmPerm {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}
.adNewPerDiv {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.usrPerLst .MuiFormControlLabel-root .MuiButtonBase-root.MuiCheckbox-root {
  padding: 0px;
  padding-left: 9px;
  padding-right: 5px;
}
.usrPerLst .MuiFormControlLabel-root .MuiTypography-root {
  font-size: 12px;
  color: #a9a9a9;
}

.adNewPerDiv .scuNm {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  cursor: default;
}
.adNewPerDiv .MuiSvgIcon-root {
  color: #a9a9a9;
  font-size: 16px;
}
.usrPerLst .MuiFormControlLabel-root {
  margin-right: 8px;
}
.usrPermisGrp {
  display: flex;
}
/* chat group details end */
/* chat foroward start */
/* .chtForwrdCheck .MuiButtonBase-root.MuiCheckbox-root {
   background-color: #fff; 
} */
.chtFrwUsrChck .MuiSvgIcon-root,
.chtForwrdCheck .MuiSvgIcon-root {
  color: #a9a9a9;
  font-size: 16px;
}

.chtCovAct {
  position: absolute;
  top: 1px;
  z-index: 100;
  color: #e9edef;
  opacity: 0;
  right: 1px;
  height: 24px;
  width: 24px;
  cursor: pointer;
  transition: right 75ms ease-out, top 75ms ease-out;
}

.chtMsg .msg:hover .chtCovAct {
  opacity: 1;
  background: radial-gradient(circle farthest-side, #5d5d5db8, #ffffff00);
}
.chtForwrdCheck {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.selectFrdMsg .msg-in {
  margin-left: 34px;
}

.chtMsg.seletedFrdMsg {
  background-color: rgb(149 149 149 / 10%);
  padding-top: 2px;
  padding-bottom: 2px;
}
.gMsgSndNm {
  display: block;
  width: 100%;
  margin-top: -2px;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #00adef;
}

.chtCovActMenu .MuiList-root .MuiButtonBase-root.MuiMenuItem-root {
  font-size: 14px;
}
.chtCovActMenu .MuiPaper-root.MuiPaper-elevation {
  background-color: #333333;
  color: rgb(255 255 255 / 87%);
  box-shadow: 0px 4px 22px -1px #00000017;
}

.chtMsg.selectFrdMsg .msg:hover .chtCovAct {
  opacity: 0;
  display: none;
}

.clsFrwBtn {
  order: 1;
  color: #fff;
}
.frwSlctMsgCount {
  order: 2;
  flex: 1;
  color: #fff;
}
.msgFrwdBtn {
  order: 3;
  color: #fff;
}
.msgFrwdBtn svg,
.clsFrwBtn svg {
  height: 24px;
  width: 24px;
  color: #fff;
}
.frwContLstSlct .chatContactList {
  width: calc(100% - 8px);
  padding: 0px 4px 0px 4px;
  align-items: center;
  gap: 6px;
}
.frwContLstSlct .userDtlWlMsg {
  width: calc(100% - 100px);
}

.chatConvoFooter.chatConvoRplyFoot {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.chtReplyInpt {
  width: 100%;
}
.chatRegularFoot {
  display: inline-flex;
  align-items: center;
  gap: 16px;
  flex: 1;
}
.chatConvoFooter.chatConvoRplyFoot .chatRegularFoot {
  width: 100%;
}
.chtReplyInptGrp {
  display: flex;
  align-items: center;
}
.chtRplyMsgDtlGrp {
  flex: 1 1;
  order: 1;
  background-color: #121212;
  color: #fff;
  border-radius: 4px;
  padding: 6px 10px;
  border-left: 4px solid #00adef;
  display: flex;
  align-items: center;
}
.chtRplyMsgDtlClsBtn {
  order: 2;
}
.chtRplyMsgUsrNm {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: #fff;
}
.chtRplyMsgInfo {
  font-size: 14px;
  line-height: 18px;
  color: #a9a9a9;
  font-weight: 400;
  word-break: break-all;
}

.chtRplyMsgDtlClsBtn svg {
  color: #fff;
}
.chtRplyTxt {
  order: 1;
  flex: 1;
}
.chtRplyImg {
  order: 2;
  height: 50px;
  width: auto;
  /* max-width: 50px; */
  max-height: 50px;
}
.chtRplyImg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.chtMsg .msg .chtRplyMsgDtlGrp {
  order: 0;
  width: calc(100% - 20px);
}
.delMsg {
  opacity: 0.5;
  cursor: not-allowed;
}
.delMsg .msgTxt {
  font-style: italic;
}
.center-progress {
  text-align: center;
}
.unread-count {
  color: #d7d7d7;
  text-align: center;
  font-size: 12px;
}
.chtForwarded {
  font-size: 12px;
  font-style: italic;
  width: 100%;
  text-align: left;
  color: #a5a5a5;
}
.msgMedia audio {
  width: 100%;
  min-width: 200px;
  max-width: 205px;
  height: 36px;
}

.rightShowDtlPanel .chatGrpDtlContainer {
  border-left: none;
}
.closeBtnForDashboard {
  display: none !important;
}
.mobChatBack .MuiButtonBase-root.MuiIconButton-root {
  background: #393939;
  color: #fff;
}
.inChatAct .MuiButtonBase-root.MuiIconButton-root {
  height: 36px;
  width: 36px;
  padding: 4px !important;
}
.mobChatBack {
  display: none;
}
.msgTxt a {
  color: #fff;
}
