@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");
:root {
  --primary-bg-color: "#1C1C1C";
  --e-global-color-primary: #ffffff;
  --e-global-color-accent: #8b8b8b;
  --primary-font-family: "Inter";
  --primary-font-weight: 800;
  --e-global-typography-secondary-font-family: "Inter";
  --e-global-typography-secondary-font-weight: 800;
  --e-global-typography-text-font-family: "Raleway";
  --secondary-text-font-family: "Raleway";
  --e-global-typography-text-font-weight: 400;
  --e-global-typography-accent-font-family: "Raleway";
  --e-global-typography-accent-font-weight: 400;
  --element-min-height: 175px;
  font-size: 14px !important;
  font-family: "Inter";

  /* troggle dark light*/
  --darkbg: #251d29;
  --darkt: #ffd1f7;
  --lightbg: #ffffff;
  --lightt: #000000;

  /* --toggleHeight: 16em;
  --toggleWidth: 30em;
  --toggleBtnRadius: 10em; */
  --toggleHeight: 10em;
  --toggleWidth: 20em;
  --toggleBtnRadius: 8em;

  --bgColor--night: #423966;
  --bgColor--day: #ffbf71;
  --mooncolor: #d9fbff;
}
body {
  height: 100vh;
  width: 100%;
  margin: 0;
  font-family: Inter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1c1c1c;
  position: relative;
}
img {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  user-drag: none;
  -webkit-touch-callout: none;
}
#root {
  height: 100vh;
  width: 100%;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.eventAlertWrapper {
  z-index: 1;
  position: absolute;
  top: -100%;
  left: 50%;
  opacity: 0;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 10px 14px;
  border-radius: 8px;
  gap: 6px;
  animation: smooth-appear 1s ease forwards;
  transition: all 2s;
  max-width: 80vw;
  width: max-content;
}
.evnAltIcon {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.evnAltIcon svg {
  height: 18px;
  width: 18px;
  color: #000000;
}
.evnDtlWTime {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-family: Inter;
  font-size: 14px;
  line-height: 18px;
}
span.evdH {
  font-weight: 700;
}
span.evdT {
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* max-width: 200px; */
}
span.evdTime {
  font-weight: 400;
}
.eveAltBtn {
  margin-left: 24px;
  display: flex;
  gap: 4px;
  align-items: stretch;min-width: 160px;

}
.eveAltBtn button {
  height: 24px !important;
  min-width: 72px !important;
  border-radius: 4px !important;
  border: 1px solid #707070 !important;
  padding: 0px 10px !important;
  font-family: Inter !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  text-transform: capitalize !important;
  color: #707070 !important;
}

@keyframes smooth-appear {
  to {
    top: 10px;
    opacity: 1;
  }
}
@-moz-keyframes smooth-appear {
  to {
    top: 10px;
    opacity: 1;
  }
}
@-webkit-keyframes smooth-appear {
  to {
    top: 10px;
    opacity: 1;
  }
}
@-o-keyframes smooth-appear {
  to {
    top: 10px;
    opacity: 1;
  }
}
@-ms-keyframes smooth-appear {
  to {
    top: 10px;
    opacity: 1;
  }
}

.bottomNavConatiner {
  z-index: 1;
  position: fixed;
  bottom: 10px;
  height: 30px;
  left: 50%;
  opacity: 0;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  background-color: #474747;
  padding: 10px 14px;
  border-radius: 40px;
  gap: 16px;
}
.bottomNavConatiner:hover {
  animation: smooth-appear-Menu 1s ease forwards;
  transition: all 2s;
}
/* .bottomNavConatiner {
  z-index: 1;
  position: fixed;
  bottom: -50px;
  height: 30px;
  left: 50%;
  opacity: 1;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  background-color: #474747;
  padding: 10px 14px;
  border-radius: 40px;
  gap: 16px;
  animation: smooth-appear-Menu 1s ease forwards;
  transition: all 2s;
} */
/* 
.bottomNavConatiner {
  z-index: 1;
  position: fixed;
  bottom: 5px;
  height: auto;
  width: 100%;
  left: 50%;
  opacity: 1;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.botNavConArea {
  background-color: #474747;
  padding: 10px 14px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  height: 30px;
  opacity: 0;
}
.bottomNavConatiner:hover .botNavConArea {
  animation: smooth-appear-Menu 1s ease forwards;
  transition: all 2s;
} */

@keyframes smooth-appear-Menu {
  to {
    bottom: 10px;
    opacity: 1;
  }
}
@-moz-keyframes smooth-appear-Menu {
  to {
    bottom: 10px;
    opacity: 1;
  }
}
@-webkit-keyframes smooth-appear-Menu {
  to {
    bottom: 10px;
    opacity: 1;
  }
}
@-o-keyframes smooth-appear-Menu {
  to {
    bottom: 10px;
    opacity: 1;
  }
}
@-ms-keyframes smooth-appear-Menu {
  to {
    bottom: 10px;
    opacity: 1;
  }
}
.quickAccessApp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
button.qaAppDtl {
  height: 30px !important;
  width: 30px !important;
  min-width: 0px !important;
  border-radius: 20px !important;
  background-color: #656565 !important;
  color: #ffffff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 14px;
  font-family: Inter;
  font-weight: 600;
  padding: 6px;
}
button.qaAppDtl:hover {
  background-color: #333333 !important;
}
.autocompleteSearch {
  min-width: 240px;
  max-width: 250px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
  padding: 0px 2px;
  border-bottom: 1px solid #707070;
}

.autocompleteSearch .MuiAutocomplete-root {
  width: 100%;
}
.autocompleteSearch .MuiInputBase-root.MuiOutlinedInput-root {
  padding: 0px;
}
.autocompleteSearch svg {
  color: #ffffff;
}
.autocompleteSearch
  .MuiInputBase-root.MuiOutlinedInput-root
  .MuiInputBase-input {
  font-size: 14px;
  color: #fff;
  -webkit-text-fill-color: #fff;
  line-height: 24px;
  padding: 6px;
}

.botmMenu .MuiButtonBase-root.MuiIconButton-root {
  color: #ffffff;
  height: 42px;
  width: 42px;
  padding: 4px;
}
.botmMenu .MuiButtonBase-root.MuiIconButton-root svg {
  height: 32px;
  width: 32px;
}

.bottomMnuPopover {
  min-width: 450px;
  padding: 30px;
  max-width: 450px;
  min-height: 200px;
  position: absolute;
  bottom: 53px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #323232;
  z-index: 9999;
  border-radius: 8px;
}
.pRelative {
  position: relative !important;
}
.loaderSmall {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 1024;
  background: #1c1c1cad !important;
}
.appLoader {
  height: 100%;
  width: 100%;
  z-index: 1024;

  /* opacity: 0.8; */
  top: 0px;
  left: 0px;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  transform: unset;
  background: rgba(0, 0, 0, 0.7);
}
.appLoader.fullarea {
  position: fixed !important;
}
.appLoader.smallarea {
  position: absolute !important;
}
#logo_Animation {
  transform-origin: center center;
  transform: scale(40);
  animation: zoomOut 0.6s ease-out 0.5s forwards;
}
#logo_AnimationSmall {
  transform-origin: center center;
  transform: scale(0.1);
  animation: zoomOut 0.6s ease-out 0.5s forwards;
}
svg#logo_AnimationSmall path {
  animation: color-animation 4s linear infinite;
}
svg#logo_Animation path {
  animation: color-animation 4s linear infinite;
}
.colorSwap {
  --color-1: #ffffff;
  --color-2: #df8453;
}
@keyframes color-animation {
  0% {
    fill: var(--color-1);
  }
  60% {
    fill: var(--color-2);
  }
}

@keyframes zoomOut {
  100% {
    transform: scale(1);
  }
}

#eyeBlink {
  animation: squeeze 1.4s infinite;
  animation-delay: 1s;
  animation-iteration-count: 2;
}

@keyframes squeeze {
  90% {
    transform: none;
    animation-timing-function: ease-in;
  }
  93% {
    transform: translateY(28px) scaleY(0.2);
  }
  100% {
    animation-timing-function: ease-out;
  }
}

.circleProgress {
  color: #fff !important;
}
.circleProgress circle {
  stroke-width: 1.6;
}

.logoSmile {
  fill: #ffffff;
  animation: logoSmile 0.6s ease-in 1s 1 forwards,
    color-animation 4s linear infinite !important;
  opacity: 0;
}

@keyframes logoSmile {
  0% {
    transform: rotate(37deg) translate(-15px, -44px);
    fill: #ffffff;
    transform-origin: 21px 48px;
    opacity: 0;
  }
  100% {
    transform: rotate(0eg) translate(0px, 0px);
    fill: #ffffff;
    transform-origin: 0px 0px;
    animation-timing-function: ease-in;
    opacity: 1;
  }
}

/* .textLoader {
  font-size: 24px;
  color: #333;
  position: relative;
}

.textLoader::after {
  content: "";
  width: 1em;
  height: 1em;
  border: 2px solid #333;
  border-top: 2px solid transparent;
  border-radius: 50%;
  position: absolute;
  right: -1.5em;
  top: 0.15em;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.textLoader {
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 2px;
  color: #ffffff;
  transform: scale(0.1);
  animation: zoomOut 0.6s ease-out 0.5s forwards;
}

.textLoaderdot {
  animation: dotblink 1.4s infinite both;
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ffffff;
  margin-left: 6px;
}

.textLoaderdot:nth-child(2) {
  animation-delay: 0.2s;
}

.textLoaderdot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dotblink {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  20% {
    opacity: 1;
    transform: scale(1.3);
  }
  40% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

/* .audio-preview {
  display: none !important;
} */

.dNone {
  display: none !important;
}

.tab-content::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}
.tab-content::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 1);
}

.tab-content::-webkit-scrollbar-track {
  background: rgb(18 18 18 / 70%);
}

.trashIcon {
  color: red !important;
}
.landscapeContainer {
  display: none;
}

.confirmDlgTitle {
  padding: 0px 20px !important;
  font-size: 24px !important;
}

.confirmDlgContent {
  padding: 0px 20px !important;
}

.confirmDlgChildren {
  padding: 0px 20px !important;
}

/* troggle start */
.toggle-switch {
  /* position: absolute;
  z-index: 1024;
  bottom: 160px;
  left: 5px;
  margin: 0 auto;
  font-size: 30%;
  margin-top: 10em; */
  position: relative;
  font-size: 24%;
  height: var(--toggleHeight);
  width: var(--toggleWidth);
  border-radius: var(--toggleHeight);
  transition: all 500ms ease-in-out;
  background: var(--bgColor--night);
}
.toggle-container {
  width: var(--toggleWidth);
  height: var(--toggleHeight);
  border-radius: var(--toggleHeight);
  position: relative;
  margin: 20px auto;
  cursor: pointer;
  background: var(--bgColor--night);
  transition: background 0.4s ease-in-out;
}

body.lightMode .toggle-container {
  background: var(--bgColor--day);
}
.day {
  background: #ffbf71;
}
/* Moon Styling */
/* .moon {
  position: absolute;
  display: block;
  border-radius: 50%;
  transition: all 400ms ease-in-out;

  top: 3em;
  left: 3em;
  transform: rotate(-75deg);
  width: var(--toggleBtnRadius);
  height: var(--toggleBtnRadius);
  background: var(--bgColor--night);
  box-shadow: 3em 2.5em 0 0em var(--mooncolor) inset,
    rgba(255, 255, 255, 0.1) 0em -7em 0 -4.5em,
    rgba(255, 255, 255, 0.1) 3em 7em 0 -4.5em,
    rgba(255, 255, 255, 0.1) 2em 13em 0 -4em,
    rgba(255, 255, 255, 0.1) 6em 2em 0 -4.1em,
    rgba(255, 255, 255, 0.1) 8em 8em 0 -4.5em,
    rgba(255, 255, 255, 0.1) 6em 13em 0 -4.5em,
    rgba(255, 255, 255, 0.1) -4em 7em 0 -4.5em,
    rgba(255, 255, 255, 0.1) -1em 10em 0 -4.5em;
} */

.moon {
  position: absolute;
  display: block;
  border-radius: 50%;
  transition: all 400ms ease-in-out;
  top: 1em;
  left: 1em;
  transform: rotate(-75deg);
  width: var(--toggleBtnRadius);
  height: var(--toggleBtnRadius);
  background: var(--bgColor--night);
  box-shadow: 2em 2.5em 0 0em var(--mooncolor) inset,
    rgba(255, 255, 255, 0.1) 0em -4em 0 -2.5em,
    rgba(255, 255, 255, 0.1) 2em 6em 0 -2.5em,
    rgba(255, 255, 255, 0.1) 2em 12em 0 -3em,
    rgba(255, 255, 255, 0.1) 5em 2em 0 -3.1em,
    rgba(255, 255, 255, 0.1) 6em 8em 0 -3.5em,
    rgba(255, 255, 255, 0.1) 5em 11em 0 -3.2em,
    rgba(255, 255, 255, 0.1) -2em 7em 0 -3.5em,
    rgba(255, 255, 255, 0.1) -1em 10em 0 -3em;
}
/* .sun {
  top: 4.5em;
  left: 18em;
  transform: rotate(0deg);
  width: 7em;
  height: 7em;
  background: #fff;
  box-shadow: 3em 3em 0 5em #fff inset, 0 -5em 0 -2.7em #fff,
    3.5em -3.5em 0 -3em #fff, 5em 0 0 -2.7em #fff, 3.5em 3.5em 0 -3em #fff,
    0 5em 0 -2.7em #fff, -3.5em 3.5em 0 -3em #fff, -5em 0 0 -2.7em #fff,
    -3.5em -3.5em 0 -3em #fff;
} */
.sun {
  top: 1.5em;
  left: 11em;
  transform: rotate(0deg);
  width: 7em;
  height: 7em;
  background: #fff;
  box-shadow: 3em 3em 0 5em #fff inset, 0 -4.5em 0 -3em #fff,
    3.5em -3.5em 0 -3em #fff, 5em 0 0 -2.7em #fff, 3.5em 3.5em 0 -3em #fff,
    0 5em 0 -2.7em #fff, -3.5em 3.5em 0 -3em #fff, -5em 0 0 -2.7em #fff,
    -3.5em -3.5em 0 -3em #fff;
}

/* Transitions */
body.lightMode .toggle-icon {
  transform: translateX(200%);
}

body.darkMode .toggle-icon {
  transform: translateX(0%);
}
/* troggle end */

.react-joyride {
  position: fixed !important;
}

.gap20{
  gap: 20px !important;
}
.copybtn{
  color: #fff !important;
  background-color: #545454 !important;
}