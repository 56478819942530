.healthContainer .joinMetBtn {
  align-items: center !important;
  background: #00adef !important;
  border-radius: 4px !important;
  color: #fff !important;
  display: flex !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  gap: 6px !important;
  letter-spacing: 0.14px !important;
  line-height: 24px !important;
  padding: 6px 18px !important;
  text-transform: capitalize !important;
}
.healthContainer .tskInfoDtl {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 1;
}
.tskTitleGrpWthCenter {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.healthContainer .joinMetBtn svg {
  fill: #fff;
  height: 24px;
  width: 28px;
  font-size: 20px;
}
.vitalsGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  align-items: stretch;
}
.vitalsInd {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  background-color: #333;
  padding: 4px 6px;
  border-radius: 4px;
  transition: all 0.6s;
}
.vitalsInd:hover {
  background-color: #1d1d1d;
}
.vitIcon svg {
  fill: #949494;
  height: 21px;
  width: 21px;
}
.vitNm {
  color: #949494;
  font-size: 12px;
}
.vitValue {
  font-size: 16px;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 4px;
}
.pInfoGrp {
  color: #949494;
  font-size: 16px;
  margin-left: 10px;
}

.healthContainer .contMxWidthLim {
  display: flex;
  padding: 22px 0px 22px 10px;
  align-items: flex-start;
  gap: 14px;
  width: 100%;
  max-width: 810px;
}

.healthContainer .elemntFilNm {
  display: -webkit-box;
  max-width: 180px;
  width: auto;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #fff;
  opacity: 0.6;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
}
.docIcons {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background: #1c1c1c;
  display: flex;
  align-items: center;
  justify-content: center;
}
.docIcons svg {
  height: 24px;
  width: 24px;
  fill: #fff;
}
.patImgCircle {
  height: 80px;
  width: 80px;
  border-radius: 40px;
}
.patImgCircle img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.patientInfoWthImg {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}
.patientInfo {
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.complaintList ul {
  margin: 0px;
  padding-inline-start: 24px;
  margin-bottom: 16px;
  color: #fff;
  list-style-type: decimal;
}
.complaintList ul li {
  margin-bottom: 6px;
}
.compntName {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}
.complstDtl {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.compinfoGRP {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 6px;
}
.compStime,
.compSeverity {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  color: #cbcbcb;
  background-color: #333333;
  padding: 2px 6px;
  border-radius: 2px;
}

.compStime .data,
.compSeverity .data {
  color: #ffffff;
  word-break: break-all;
}
.pcenterNmHL {
  font-size: 12px;
  color: #ff9900;
  font-weight: 600;
  height: 12px;
}
.pDemography {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  row-gap: 4px;
  flex-wrap: wrap;
  width: 100%;
}
.pDemogInd {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.pDemogInd span {
  font-size: 16px;
  color: #999999;
}
.pDemogInd span.pDVlue {
  color: #fff;
  font-weight: 500;
}
.healthContainer .metRtTitle {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  cursor: pointer;
}
.tskPriotGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.statReview {
  background-color: #ab00ef;
}
.vRisk {
  color: #e92b2b;
}
.vModarate {
  color: #ff9900;
}
.vNormal {
  color: #0cb764;
}

/*********************************** bmi calculate start ********************************** */
.bmiGroup {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 1.1rem;
}
.c-bmi__group-text {
  display: none;
  font-size: small;
  order: 2;
  padding: 1rem 0;
  text-align: left;
  width: 100%;
}
.c-bmi__groups {
  border: 0;
  flex-wrap: wrap;
  font-size: x-small;
  padding: 0;
  text-align: center;
  width: 100%;
  flex: 1;
}
.c-bmi__groups label {
  display: block;
  padding: 0.1rem 1rem;
  position: relative;
}

[for="bmi-g0"] {
  background-color: #4691e2;
}
[for="bmi-g1"] {
  background-color: #0cb764;
}
[for="bmi-g2"] {
  background-color: #febf18;
}
[for="bmi-g3"] {
  background-color: #fc8711;
}
[for="bmi-g4"] {
  background-color: #ff6455;
}
[for="bmi-g5"] {
  background-color: #cc1100;
  color: #fff;
}

.bmi-underweight {
  color: #4691e2;
}

.bmi-normal {
  color: #0cb764;
}

.bmi-preobesity {
  color: #febf18;
}

.bmi-obese1 {
  color: #fc8711;
}

.bmi-obese2 {
  color: #ff6455;
}

.bmi-obese3 {
  color: #cc1100;
}

.c-bmi__groups input:checked + label::before {
  background-color: #fff;
  clip-path: polygon(0% 0%, 0% 100%, 75% 50%);
  content: "";
  display: inline-block;
  height: 1rem;
  left: 0.5rem;
  position: absolute;
  top: 0.35rem;
  width: 1rem;
}
.c-bmi__groups input:checked + label + div {
  display: block;
  flex: 0 0 100%;
}
.c-bmi__label {
  display: block;
  font-size: medium;
  margin: 0 0 1rem 0;
  position: relative;
}
.c-bmi__label output {
  position: absolute;
  right: 0;
  top: 0;
}
.c-bmi__range {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  outline: none;
  width: 100%;
}

.c-bmi__result {
  display: block;
  font-size: 14px;
  margin-bottom: 0rem;
  text-align: left;
  color: #fff;
}
.c-bmi__result span {
  margin-left: 10px;
}
.bmiGroup .bmiInputRadio[type="radio"] {
  display: none;
}

/* RWD */
@media (min-width: 600px) {
  .c-bmi__groups {
    display: flex;
  }
  .c-bmi__groups input:checked + label::before {
    background-color: inherit;
    clip-path: polygon(0% 0%, 100% 0%, 50% 100%);
    left: 50%;
    top: -1rem;
    transform: translateX(-50%);
    width: 1rem;
  }
  .c-bmi__groups label {
    flex: 1;
  }
  [for="bmi-g0"] {
    border-radius: 1rem 0 0 1rem;
  }
  [for="bmi-g5"] {
    border-radius: 0 1rem 1rem 0;
  }
}

/***********************************  bmi calculate end ********************************** */
.bb {
  width: 100%;
  border-bottom: 1px dashed #545454;
  margin-bottom: 10px;
  margin-top: 4px;
}
.addNewformGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 4px;
}

.mxW-150 {
  max-width: 150px !important;
}
.MuiPaper-root.MuiAccordion-root.docInputElmntAcod {
  width: 100%;
  background-color: #1c1c1c;
  color: inherit;
}
.MuiPaper-root.MuiAccordion-root.Mui-expanded.docInputElmntAcod {
  margin: 8px 0;
}
.docInputElmntAcod .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px;
  background: #2f2f2f;
}
.docInputElmntAcod .MuiAccordionSummary-expandIconWrapper {
  color: #545454;
}
.docInputElmntAcod .MuiAccordionSummary-content.Mui-expanded {
  margin: 10px 0;
}
.complstDtlGrp {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.complstDtlGrp .removeItemBtn {
  width: 32px;
  height: 32px;
  background-color: #2e2e2e;
  color: #f7a7a7;
}
.docInputElmntAcod .acodHeader .tskElemHeding {
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.6;
}
.docInputElmntAcod .acodDetails .tskElemHeding {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.6;
  margin-bottom: 10px;
  margin-top: 16px;
}
.MuiAccordionSummary-content.Mui-expanded .tskElemHeding {
  opacity: 1;
}
.hilightHeading {
  background-color: #000000;
  width: max-content;
  border-radius: 4px;
  padding: 4px 12px;
}
.cTypeTag {
  gap: 4px;
  display: flex;
}
.MuiButtonBase-root.MuiButton-root.differnDiagnosBtn {
  background-color: #333333;
  color: #ff9800;
  padding: 8px 16px;
  border-radius: 6px;
  max-height: 36px;
  width: fit-content;
  box-shadow: 3px 4px 5px #111111, -3px -3px 10px -1px rgb(48 48 48 / 93%);
}
.flexDRowWrap {
  flex-direction: row;
  flex-wrap: wrap;
}
ul.withOutList {
  padding-inline-start: 24px;
  list-style-type: none;
  margin: 0px;
  margin-bottom: 10px;
}
ul.withOutList li {
  border-bottom: 1px dashed #545454;
  padding-bottom: 6px;
  margin-bottom: 10px;
}
.valuDateRow {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.16px;
}
.BrandMed {
  color: #ff9800;
  margin-bottom: 6px;
  font-size: 12px;
}
.flxSPBTW {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
}
.buttonwithWarning {
  display: flex;
  flex-direction: column;
}
.warnigMsg {
  font-size: 12px;
  color: #b2b2b2;
  margin-top: 6px;
}
.ddBtnGroup {
  margin: 0px 0px 8px 0px;
  width: 100%;
}
.reviewBtnGrp {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
}
.wordCloud {
  height: 200px;
}
.bmiCatName {
  margin-left: 0px !important;
  text-transform: uppercase;
  color: #fff;
}

/* //////////////////////////////////prescription View start//////////////////////////////////////// */

.pres-container {
  display: flex;
  padding: 10px 0px;
  gap: 14px;
  width: 100%;
  margin-top: 10px;
  flex-direction: column;
}

.pid,
.date {
  color: #ffffff;

  font-size: 12px;
  opacity: 0.7;
}
.reviewdate {
  color: #ffffff;

  font-size: 12px;
}

.Pressurecontent {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.vitals {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2px 0px 2px 0px;
  gap: 20px;
  border-bottom: 1px solid #545454;
  border-bottom-style: dashed;
  margin-bottom: 4px;
}
.Bloodpresuremesurment {
  display: flex;
  gap: 10px;
}
.leftside {
  width: 100%;
}
.rightside {
  width: 100%;
}
.leftside .vitalscontainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  row-gap: 6px;
  column-gap: 6px;
}
.leftside .vitalscontainer .Bunitarea {
  background-color: #333;
  line-height: 1;
  padding: 2px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-width: 50px;
  max-width: fit-content;
  height: 28px;
  border-radius: 4px;
}
.leftside .vitalscontainer .vitals {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 10px;
  background-color: #1c1c1c;
  gap: 10px;
  border-bottom: 1px solid #333;
  border-bottom-style: dashed;
  margin-bottom: 4px;
  border-radius: 6px;
}
.rightside .vitalsactionarea {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.rightside .complaintList {
  margin-top: 10px;
}
.Biconarea {
  width: 18px;
  height: 18px;
}
.vitalimg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.vitalnam {
  font-size: 14px;
  font-weight: 600;
}
.Btxt {
  color: #fff;
}
.Bunitarea {
  background-color: #1c1c1c;
  line-height: 1;
  padding: 3px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-width: 50px;
  max-width: 50px;
  height: 32px;
}

.rate,
.oxygn {
  font-size: 14px;
  color: #ffffff;
  font-weight: 500;
  letter-spacing: 2px;
}
.Bunitnam {
  color: #ffffff;
  font-size: 12px;
  opacity: 0.7;
}
.vitalshortfrom {
  font-weight: 400;
  font-size: 12px;
  opacity: 0.7;
}
/* .Pulsert {
  gap: 85px;
} */
.presBmi .c-bmi__groups label {
  display: block;
  padding: 0px;
  position: relative;
  width: 45px;
  height: 10px;
}
.warnigUnit {
  background-color: #ff9f10;
}
.dengerUnit {
  background-color: #fe5151;
}
.redtxt {
  color: #ffffff;
}

.vitalunitrate {
  font-size: 12px;

  color: #ffffff;
  font-weight: 800;
  letter-spacing: 2px;
}
.oxygn {
  font-size: 16px;

  color: #414141;
  font-weight: 800;
}
.vitalheadngtxt {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.7;
}
.vitalinfosubtxt {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.vitalsactionarea {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.vitlulinfo {
  margin: 4px 0px !important;
  color: #fff;
  padding-inline-start: 30px;
}
.vitlulinfo li {
  margin-bottom: 4px;
}
.vitalscontainer {
  margin-bottom: 11px;
}
/* .radioimg {
  display: none;
} */
.arrowmarked.active {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 13px solid #f90;
  margin-top: -13px;
}
.bmiarea {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.bmiresult {
  display: flex;
  gap: 10px;
  align-items: center;
}
.bmitxt {
  color: #dbdbdb;
  font-size: 12px;
  opacity: 0.6;
  font-weight: 600;
}
.bmiunit {
  color: #e28800;
  font-size: 16px;
  font-weight: 600;
}
.bmimarkedarea {
  width: 9.9px;
  height: 9.9px;
}
.arrowicon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.bmicolor {
  width: 29px;
  height: 5px;
}
.bmifirst {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #e92b2b;
}
.bmisecond {
  width: 24px !important;
  background-color: #ff9900;
}
.bmithird {
  background-color: #15de29;
  width: 30px !important;
}
.bmifourth {
  background-color: #ff9900;
  width: 30px !important;
}
.bmifive {
  width: 47px !important;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #e92b2b;
}
.bmicolorunitset {
  display: flex;
}

.mediconarea svg path {
  stroke: #fff;
}
.bmimesurment {
  gap: 6px;
  display: flex;
  flex-direction: column;
}
.arrowicon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.uniticonarea {
  width: 5px;
  height: 12px;
  position: relative;
  text-align: center;
  left: 3%;
}
.bmiuniterange {
  display: flex;
  gap: 1px;
}
.uniterange {
  width: 29px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.utwo {
  width: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.uthree,
.ufour {
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.unitrangtxt {
  font-size: 10px;
}
.Dradvice {
  font-size: 12px;

  font-weight: bold;
  color: #ffffff;
}
.doctoradvicearea {
  width: 100%;
}

.mednam {
  font-size: 14px;
  color: #ffffff;
  word-break: break-all;
}
.actnoriconarea {
  width: 14px;
  height: 14px;
}
.acticn {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.takeaction {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 19px;
  gap: 5px;
  border-radius: 4px;
}
.actiotxt {
  font-size: 12px;
  color: #ffffff;
}
.actionarea {
  display: flex;
  gap: 10px;
}
.anrml {
  width: 68px;
  padding: 2px 5px;
  background-color: #15de29;
}
.attentn {
  width: 120px;
  padding: 2px 5px;
  background-color: #ff9900;
}
.critcl {
  width: 116px;
  padding: 2px 5px;
  background-color: #e92b2b;
}
.scaniconarea {
  width: 144px;
  height: 72.81px;
}
.acticn {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.actionareacontainer {
  display: flex;
  padding: 0px 15px;
  gap: 269px;
  flex-direction: row;
  align-items: center;
}
.vitlfootrarea {
  position: relative;
  background-color: #1c1c1c;
  display: flex;
  gap: 154px;
  padding: 5px 15px;
  height: 31px;
  align-items: center;
}
/* ::-webkit-scrollbar { 
  display: none;
} */
.prodnamtxt {
  font-size: 12px;
  color: #ffffff;
  font-weight: bold;
}
.prodaddresstxt {
  font-size: 12px;
  color: #ffffff;
}
.contactnumbericon {
  width: 11.67px;
  height: 14.91px;
}
.phoneicn {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.contctnumber {
  font-size: 12px;
  color: #afafaf;
  font-weight: bold;
}
.productfrom,
.contactarea {
  display: flex;
  gap: 5px;
  align-items: center;
}
.mainscrolldiv {
  height: 85%;
  overflow: hidden;
  overflow-y: overlay;
  position: relative;
  scrollbar-width: none;
}
.Biconarea svg {
  fill: #949494;
  height: 21px;
  width: 21px;
}

/* //////////////////////////////////prescription View end//////////////////////////////////////// */
.mt6 {
  margin-top: 6px;
}
.centerDetail {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
}
.centerDetail .centerName {
  font-size: 18px;
}
.centrDtlInd {
  display: flex;
  gap: 2px;
  color: #000000;
  width: 100%;
  flex-direction: row;
}
.centrDtlInd .hedingCCD {
  color: #414141;
  font-weight: 400;
  width: 80px;
}
.centrDtlInd .ccInfoData {
  flex: 1;
}

.w50p {
  width: 50%;
}
.w15p {
  width: 10%;
}
.w15p {
  width: 10%;
}
.w20p {
  width: 30%;
}
.testResulttable,
.testResulttable th,
.testResulttable td {
  padding: 4px 10px;
  vertical-align: middle;
  border: none;
  text-align: left;
}
.testResulttable td.singlRow {
  padding: 2px 10px;
}
.testNameH {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
}
.testElmGrp {
  display: flex;
  flex-direction: column;
  color: #fff;
  font-size: 14px;
  margin-left: 10px;
  font-weight: 400;
}
.testNrmlRange {
  display: flex;
  flex-direction: column;
}
.testElmDtl {
  opacity: 0.6;
  font-size: 12px;
  font-weight: 400;
}
.testElmNM {
  font-weight: 500;
  /* margin-top: 10px; */
}
.outOfRange {
  color: #ed3237 !important;
  font-weight: 700 !important;
  opacity: 1 !important;
}
.testResult {
  width: 100%;
}
.waitforTestResult {
  color: #777777;
  text-align: center;
  font-style: italic;
  letter-spacing: 0.5px;
}
.testResulttable th {
  background-color: #333333;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  opacity: 0.6;
  padding: 10px;
}
.testValue {
  color: #fff;
  font-weight: 400;
  opacity: 0.6;
}
.textCenter {
  text-align: center;
}
.testUnit,
.testNrmlRange .tstnRangeElm {
  color: #fff;
  opacity: 0.6;
  font-size: 12px;
  font-weight: 400;
}
.riskValue {
  color: #ffffff !important;
  font-weight: 700;
  opacity: 1;
}
.emptyRow {
  min-height: 8px;
  border-bottom: 1px solid #373737 !important;
}
.pastIllness .formElement .formDatePicker {
  padding: 8px 12px 8px 28px;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: 1px solid #707070;
  outline: none;
  border-radius: 4px;
  width: calc(100% - 40px);
  background-color: #333333;
}
.mb0 {
  margin-bottom: 0 !important;
}
.d-flex {
  display: flex;
  align-items: center;
  gap: 4px;
}
.wAuto {
  width: auto !important;
}
.mxW-160 {
  max-width: 160px !important;
}
.testReportText {
  color: #fff;
  font-weight: 400;
  margin-bottom: 6px;
}
.rheadtext {
  font-weight: 600;
}
.rDescptext {
  opacity: 0.6;
}
.naLstTyp {
  list-style-type: none !important;
}
.presBmi {
  flex-direction: column !important;
  align-items: flex-start !important;
  gap: 16px;
}
.width180 {
  width: 180px !important;
  min-width: 180px !important;
}
.editInputVitals {
  padding: 4px;
  width: 50px;
  border: 1px solid #707070;
  background-color: #232323;
  color: #fff;
  border-radius: 4px;
}
.vitalsInd .vitInfo .vitValue input[type="date"].editInputVitals {
  min-width: 100px;
}

.vitalsInd
  .vitInfo
  .vitValue
  input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 0.6;
  filter: invert(0.8);
}

.vitalsInd
  .vitInfo
  .vitValue
  input[type="date"]::-webkit-calendar-picker-indicator:hover {
  opacity: 1;
}
.ddFlotContainer .elementFormContainer p.MuiTypography-root {
  color: #b2b2b2;
}

.editInputUnits {
  background-color: #232323;
  color: #fff;
  padding: 3px;
  border-radius: 4px;
  /* margin-left: 3px; */
}
.slash {
  height: 18px;
  margin: 0 2px;
  width: 2px;
  opacity: 0.4;
  background-color: #fff;
  transform: rotate(14deg);
}
.editInputDate {
  padding: 4px;
  border: 1px solid #707070;
  background-color: #232323;
  color: #fff;
  border-radius: 4px;
}

input[type="date"].editInputDate::-webkit-calendar-picker-indicator {
  opacity: 0.6;
  filter: invert(0.8);
}

input[type="date"].editInputDate::-webkit-calendar-picker-indicator:hover {
  opacity: 1;
}
/* .editInputVitals:focus-visible {
  outline-color: #00adef;
} */
.fullHeight {
  height: 100%;
}
.MuiButtonBase-root.MuiButton-root.faceRegisterBtn {
  width: 200px;
  padding: 8px 12px 8px 12px;
  min-height: 24px;
  background-color: #00a4e2;
  color: #fff;
  font-size: 14px;
  font-family: "Inter";
  text-transform: capitalize;
}
.fullWidth100p {
  width: 100% !important;
}
.with30vw {
  width: 30vw !important;
}
.faceIndview {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2px;
}
.viewFaceRegisterGroup {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
}

.faceIndview .faceText {
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  opacity: 0.5;
}
.faceIndviewImg {
  height: 100px;
  width: 100px;
}
.faceIndviewImg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: top center;
  border-radius: 6px;
}
.faceWithFormGrp {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  gap: 10px;
}
.mxW-85 {
  max-width: 85px !important;
}
.lftFromGrp {
  width: 100%;
}
.MuiButtonBase-root.MuiButton-root.indImgButton {
  position: relative;
  height: 100px;
  width: 100px;
  background-color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #ffffff;
  gap: 2px;
  border-radius: 6px;
  font-size: 12px;
  text-transform: capitalize;
  cursor: pointer;
}

.indImgButton svg {
  height: 60px;
  width: 60px;
}

.indImgButton::before,
.indImgButton::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  border: 2px solid #b2b2b2;
}

.indImgButton::before {
  bottom: 0;
  right: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 6px;
  border-top: none;
  border-left: none;
}

.indImgButton::after {
  top: 0;
  left: 0;
  border-top-left-radius: 6px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: none;
  border-right: none;
}

.indImgButton::before,
.indImgButton::after {
  box-sizing: border-box;
}
.custContactNo {
  display: flex;
  align-items: center;
  width: 100%;
}
select.countryCode {
  padding: 8px 6px 8px 6px;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: 1px solid #707070;
  outline: none;
  border-radius: 4px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: none;
  width: 72px;
  background-color: #4d4d4d;
  height: 40px;
}

.custContactNo
  .formElement
  .formTextFieldArea
  .MuiInputBase-root.MuiOutlinedInput-root {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: none;
}
.mxW174 {
  max-width: 174px;
}
.mxW120 {
  max-width: 120px;
}
.patientAgeBreack {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 6px;
}

.MuiButtonBase-root.MuiButton-root.raiseSupportBtn {
  border-radius: 4px;
  padding: 8px 18px;
  height: 36px;
  border: 1px solid #545454;
  color: #a4a4a4;
  text-transform: capitalize;
}
.MuiButtonBase-root.MuiButton-root.recaptureBtn {
  width: 100px;
  font-size: 12px;
  padding: 6px;
  background-color: #333;
  text-transform: capitalize;
  color: #b2b2b2;
}
.selectBookingType {
  margin-top: 24px;
}
.selectBookingType .MuiFormGroup-root.formRadioGroup {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0px;
  padding-left: 4px;
}
.scanFaceBtn .MuiButtonBase-root.MuiIconButton-root {
  border: 1px solid #707070;
  border-radius: 4px;
  background-color: #333333;
  padding: 4px;
  color: #fff;
  height: 40px;
  width: 40px;
}
.pDemographyImgGrp {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}
.pDemographyImgGrp .pDemography {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  row-gap: 0px;
  flex-wrap: wrap;
  width: calc(100% - 80px);
  flex-direction: column;
}
.pDemography .pdSmallTxt {
  opacity: 0.6;
  gap: 10px;
}
.pDemographyImgGrp .patImage {
  height: 80px;
  width: 80px;
  border-radius: 40px;
}

.pDemographyImgGrp .patImage img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.pDemogInd.pdlargTxt span.pDVlue {
  font-size: 20px;
}
.width250px {
  width: 250px !important;
}
.doctorSelectWthSubBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}
.doctorSelectWthSubBtn .formElementGrp {
  display: flex;
  width: 100%;
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
}
.AdminRightFlotingContainer.ddFlotContainer {
  width: 45vw;
  animation: schFloting-appear 0.8s ease forwards;
  box-shadow: -9px 0px 24px #000000e6;
  border-left: 1px solid #545454;
}
.ddFlotContainer .flotingAreaClose {
  padding: 13px 10px 13px 0px !important;
}
.optionLikeDislike {
  color: #b2b2b2;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.ddAISugtList {
  padding-inline-start: 0px;
  list-style-type: none;
  width: 100%;
}
.ddOptIndv .formElement {
  width: calc(100% - 100px);
}
.ddOptIndv {
  display: flex;
  width: calc(100% - 20px);
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  border-bottom: 1px dashed #545454;
  margin-bottom: 4px;
  padding: 6px 10px;
  background-color: #1c1c1c;
  border-radius: 4px;
}
.ddOptIndv.active {
  background-color: #333;
}
.optionLikeDislike input[type="radio"] {
  display: none;
}
.optionLikeDislike svg {
  height: 24px;
  width: 24px;
}
.selectedOpt {
  color: #fff;
}

.outlinedInputSP {
  background-color: #1c1c1c;
}
.filterPat {
  color: #fff !important;
}
.sPatOptList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
  font-size: 14px;
  line-height: 16px;
  padding: "8px";
  cursor: pointer;
  border-bottom: 1px dashed #eee;
}
.sPatOptList:hover {
  background-color: #eeeeee;
}
.spname {
  display: block;
  color: #000000;
}
.spage {
  display: block;
  color: #000000;
  opacity: 0.5;
}
.searchWthfilterBtn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0px;
}
.filterPatBtn.MuiButtonBase-root.MuiIconButton-root {
  border: 1px solid #707070;
  border-radius: 4px;
  background-color: #333333;
  padding: 4px;
  color: #fff;
  height: 40px;
  width: 40px;
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.searchWthfilterBtn
  .formElement
  .formControl
  .MuiAutocomplete-root.formAutocompleteField,
.searchWthfilterBtn
  .formElement
  .formControl
  .MuiAutocomplete-root.formAutocompleteField
  .MuiInputBase-root.MuiOutlinedInput-root {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.filterPopper {
  width: calc(41.7vw - 96px);
  z-index: 1200;
  box-shadow: 0 10px 24px #1c1c1c;
}
.filterPopperBox {
  background-color: #1c1c1c;
  border-radius: 6px;
  padding: 20px 10px 10px;
  height: 100%;
  max-height: 60vh;
  overflow: hidden;
  width: calc(100% - 20px);
  border: 1px solid #545454;
  color: #fff;
}
.width50p {
  width: calc((100% - 6px) / 2) !important;
}
.wrapWthspcBtw {
  flex-wrap: wrap !important;
  justify-content: space-between !important;
}

.filPatImg {
  height: 50px;
  width: 50px;
  border-radius: 25px;
}
.filPatImg img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
  background-color: #232323;
}
.filtPatDtl {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.filtPatNm {
  font-size: 18px;
}
.filtPatOthDtl {
  font-size: 14px;
  font-weight: 400;
  opacity: 0.5;
  display: flex;
  gap: 10px;
}
.filterPatlistInd {
  display: flex;
  align-items: center;
  gap: 10px;
  width: calc(100% - 16px);
  padding: 4px 8px;
  background-color: #333;
  border-radius: 4px;
  border-bottom: 1px dashed #545454;
  cursor: pointer;
  margin-bottom: 4px;
}
.filterPatlistInd:hover {
  background-color: #3d3d3d;
}
.filterPatlist {
  width: 100%;
}
.filterPatlist ul {
  list-style-type: none;
  padding-inline-start: 6px;
  margin: 6px 0px;
}
.filtPatNm span {
  opacity: 0.5;
  font-size: 14px;
  font-weight: 500;
  margin-left: 6px;
}

.phyExamTable,
.phyExamTable th,
.phyExamTable td {
  padding: 2px 10px;
  vertical-align: middle;
  border: none;
  text-align: left;
  width: auto;
  color: #ffffff;
  font-size: 14px;
  border: 1px solid #333333;
  border-collapse: collapse;
}

.phyExamTable {
  padding: 0px !important;
  width: 100%;
}

.phyExamTable th {
  background-color: #0c0c0c;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  opacity: 0.6;
  padding: 10px;
}
.sDocOptList {
  border-bottom: 1px dashed #b2b2b2;
  cursor: pointer;
  width: 100%;
}
.sDocOptList:hover {
  background-color: #ebebeb;
}
.sDocOptList .sDname {
  font-size: 16px;
  line-height: 18px;
  text-transform: capitalize;
  font-weight: 700;
}
.sDocOptList .sDElm {
  font-size: 14px;
  line-height: 16px;
}
.sDElmGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  row-gap: 1px;
  flex-wrap: wrap;
}
.sDElmGrp .sDElm {
  font-size: 12px;
  opacity: 0.8;
}
.sDElm span {
  opacity: 1;
  font-weight: 600;
  color: #000000;
}

.dfultDarkSecondaryBtnSOS {
  padding: 6px 16px !important;
  font-size: 14px !important;
  border-radius: 4px !important;
  background-color: transparent !important;
  border: 1.6px solid #c00000 !important;
  color: #ffffff !important;
  font-family: Inter !important;
  transition: all 0.8s !important;
  text-transform: capitalize !important;
  font-weight: 500;
  transition: all 0.6s;
  min-width: 106px !important;
}

.dfultDarkSecondaryBtnSOS:hover {
  background-color: #c00000 !important;
  color: #fff !important;
}

.dfultPrimaryBtnSOS {
  padding: 6px 16px !important;
  font-size: 14px !important;
  border-radius: 4px !important;
  background-color: #c00000 !important;
  color: #fff !important;
  font-family: Inter !important;
  transition: all 0.8s !important;
  border: 1.6px solid #c00000 !important;
  text-transform: capitalize !important;
  font-weight: 500;
  min-width: 106px !important;
}
.tdFdC {
  /* flex-direction: column;
  align-items: flex-start; */
  gap: 16px !important;
}
.tdStext {
  opacity: 0.6;
}
.constStatus {
  background-color: #3d3d3d;
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  padding: 0 10px;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  max-width: 90px;
  width: 100%;
}
.statSuccessfull {
  border-left: 5px solid #03ab00;
}
.statCancel {
  border-left: 5px solid #f90000;
}
.jcc {
  justify-content: center !important;
}

.tblActionBtnGrp .dnPresc.MuiButtonBase-root.MuiIconButton-root {
  color: #d5d5d5;
  background-color: #2c2c2c;
}
.formSearchGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}
.vRisk .editInputUnits {
  color: #e92b2b;
}
.vNormal .editInputUnits {
  color: #0cb764;
}
.vModarate .editInputUnits {
  color: #ff9900;
}

#hourglass {
  font-size: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff9800;
}

.rotated {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);

  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.WaitTime {
  justify-content: space-between !important;
  text-transform: lowercase !important;
  display: flex;
  max-width: 80px !important;
}
.conctDoc.MuiButtonBase-root.MuiButton-root {
  padding: 2px 10px;
  border-radius: 4px;
  text-transform: capitalize;
  font-size: 12px;
  color: #fff;
  background-color: #00adef;
}
.sosP {
  color: #fff;
  background-color: #e45252;
  border-radius: 12px;
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sosP svg {
  height: 20px;
  width: 20px;
}
.prebook {
  color: #fff;
  background-color: #00adef;
  border-radius: 12px;
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.prebook svg {
  height: 20px;
  width: 20px;
}
.checkinTime {
  color: #fff;
  opacity: 0.6;
  font-size: 14px;
  font-weight: 400;
}
.textWsearch {
  display: flex;
  align-items: center;
  gap: 10px;
}
.textWsearch .searchTasklist {
  max-width: 220px;
}
.feedBackContainerArea {
  height: 100%;
  width: 100%;
}
.feedBackContainArea {
  height: 100%;
  width: 100%;
}
.feedBackContainer {
  padding: 0px 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  height: calc(100vh - 80px);
}
.reatingStar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.reatingStar svg.MuiSvgIcon-root {
  height: 42px;
  width: 42px;
  color: #ffc107;
  cursor: pointer;
  transition: all 2s;
}
.reatingStar label {
  transition: all 0.8s;
}
.feedBackCarea {
  height: 100%;
  width: 100%;
  max-width: 720px;
  max-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-bottom: 20px;
  overflow-y: overlay !important;
}
.feedbackHeader {
  background-color: #333333;
  width: calc(100% - 20px);
  text-align: center;
  color: #fff;
  padding: 16px 10px;
}
.fbhTxt {
  font-size: 22px;
}
.fbshTxt {
  font-size: 16px;
  opacity: 0.6;
  font-weight: 400;
}
.feedbackOption {
  width: 85%;
}

.userRating {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.userComment {
  width: calc(100% - 20px) !important;
}
.submitFeedbackBtn.MuiButtonBase-root.MuiButton-root {
  background-color: #857dcf;
  color: #fff;
  padding: 10px 50px;
  text-transform: capitalize;
  font-size: 16px;
  letter-spacing: 0.6px;
  font-weight: 400;
  border-radius: 30px;
}

.feedbackOption .MuiFormGroup-root .MuiButtonBase-root.MuiCheckbox-root {
  color: #b2b2b2;
  padding: 6px;
}
.textleft85 {
  text-align: left;
  width: 86%;
  opacity: 1;
  font-weight: 600;
  font-size: 18px;
}
.patinfom {
  display: flex;
  gap: 4px;
}
.patinfom span {
  opacity: 0.7;
}
.switchPatlist {
  margin-left: 30px;
}
.switchPatlist
  .MuiFormGroup-root
  .MuiFormControlLabel-root
  .MuiButtonBase-root.MuiCheckbox-root {
  padding: 4px;
}
.selectMonth .react-datepicker__month-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.selectMonth .react-datepicker__month-wrapper .react-datepicker__month-text {
  height: 30px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selectMonth
  .react-datepicker__month-text--selected.react-datepicker__month-text--keyboard-selected {
  background-color: #00adef;
  color: rgb(255, 255, 255);
}
.weekDaysGrp {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-top: 20px;
}
.weekDaysIndv {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  width: 100%;
  margin-bottom: 4px;
  padding-bottom: 12px;
  border-bottom: 1px dashed #545454;
}
.checkWeekControngrp {
  display: flex;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
}
.checkDays {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  margin-top: 4px;
  max-width: 90px;
  width: 100%;
}
.settingsOption {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}
.checkDaysSettings {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 4px;
}
.checkAdd .MuiButtonBase-root.MuiIconButton-root {
  color: #fff;
  background-color: #1c1c1c;
}
.checkTrash .MuiButtonBase-root.MuiIconButton-root {
  color: #ff0000;
  background-color: #1c1c1c;
}
.checkBoxDays .MuiButtonBase-root.MuiCheckbox-root {
  color: #b2b2b2;
  padding: 4px;
}
.unavailableText {
  color: #fff;
  opacity: 0.6;
  font-size: 16px;
  width: 100%;
  margin-left: 0px;
  margin-top: 4px;
}
.multislots {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 4px;
  flex-direction: column;
}
.setopGrp {
  width: 100%;
}
.multislots .checkDaysSettings:nth-child(2) {
  border-top: 1px dashed #333333;
  padding-top: 6px;
  margin-top: 2px;
}

.multislots .checkDaysSettings:nth-child(3) {
  border-top: 1px dashed #333333;
  padding-top: 6px;
  margin-top: 2px;
}
.multislots .checkDaysSettings:nth-child(4) {
  border-top: 1px dashed #333333;
  padding-top: 6px;
  margin-top: 2px;
}
.multislots .checkDaysSettings:nth-child(5) {
  border-top: 1px dashed #333333;
  padding-top: 6px;
  margin-top: 2px;
}
.multislots .checkDaysSettings:nth-child(6) {
  border-top: 1px dashed #333333;
  padding-top: 6px;
  margin-top: 2px;
}
.multislots .checkDaysSettings:first-child {
  border-top: 1px dashed transparent !important;
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.days-header-actionBtn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 2px;
}
.days-header-actionBtn .MuiButtonBase-root.MuiIconButton-root {
  background-color: #545454;
  border: 1px solid #b2b2b2;
  color: #fff;
}
.days-header {
  display: flex;
  justify-content: space-between;
  margin: 8px 10px;
  padding-bottom: 16px;
  width: 100%;
  border-bottom: 1px dashed #545454;
}
.day-box {
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  background-color: #1c1c1c;
  border: 1px solid #545454;
  width: 100%;
  margin: 0 5px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;
}
.day-box.active {
  background-color: #000000;
  border: 1px solid #b2b2b2;
  box-shadow: 5px 5px 15px #000, -5px -5px 16px #3f3f3f;
  position: relative;
}
.day-box.active::after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8px 0 8px;
  border-color: #b2b2b2 transparent transparent transparent;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
}
.day-box span {
  display: block;
  margin-top: 1px;
  font-size: 14px;
}
.slots-container {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
  gap: 8px;
  margin: 0px 58px 10px 58px;
}
.slot-box {
  background-color: #1c1c1c;
  padding: 2px 4px;
  border-radius: 4px;
  text-align: center;
  width: 166px;
  min-height: 50px;
  /* max-height: 60px; */
  max-height: -webkit-fill-available;
  position: relative;
  border: 1px solid #545454;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  cursor: pointer;
  transition: all 0.8s;
  flex-direction: column;
}
.rfContContain
  .rfContContainDetails
  .elementFormContainer
  .appoinmentBookingSlot
  .slots-container
  .slot-box {
  width: calc((100% - 48px) / 3);
}
.slot-box:hover {
  background-color: #000000;
}
.slotInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
}
.slot-box .slotInfo .slotTime {
  color: #fff;
}
.slot-box.booked .slotInfo .slotTime {
  opacity: 0.7;
  font-size: 12px;
  letter-spacing: 0.5px;
}
.slot-box .slotInfo .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.slot-box .slotInfo .icon svg {
  color: #03ab00;
  height: 20px;
  width: 20px;
}
.slot-box.booked .slotInfo .icon svg {
  color: #edb900;
}

.slot-box.booked {
  background-color: #333;
}
.slot-box.booked:hover {
  background-color: #333 !important;
}
.slotsStatus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.slotsAvailable,
.slotsBooked {
  display: flex;
  align-items: flex-start;
}
.slotsDtl {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.ddHighTxt {
  font-size: 14px;
  letter-spacing: 1px;
  color: #fff;
  font-weight: 600;
}
.ddSubTxt {
  font-size: 12px;
  color: #b2b2b2;
  font-weight: 400;
}
.sdHtxt {
  font-size: 12px;
  color: #fff;
}
.sdSubTxt {
  font-size: 12px;
  color: #707070;
}
.slotsAvailable .daysIcon svg {
  color: #03ab00;
  height: 18px;
  width: 18px;
}
.slotsBooked .daysIcon svg {
  color: #edb900;
  height: 18px;
  width: 18px;
}
.day-box.slotUnavailable {
  background-color: #333 !important;
}
.day-box.slotUnavailable .slotsStatus {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.day-box.slotUnavailable .slotsAvailable .daysIcon svg {
  color: #eb3232;
  height: 18px;
  width: 18px;
}
.day-box.slotUnavailable .ddHighTxt,
.day-box.slotUnavailable .sdHtxt {
  opacity: 0.7;
}
.slot-box.booked .slotInfo {
  flex-direction: row;
}
.bookedPatInfo .patDtl {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.patDtl {
  color: #fff;
}
.patDtl .patgender {
  display: flex;
  align-items: center;
  justify-content: center;
}
.patDtl .patgender svg {
  height: 18px;
  width: 18px;
}
.bookedPatInfo .patAge {
  font-size: 12px;
  color: #fff;
  opacity: 0.7;
  line-height: 12px;
  font-weight: 400;
  letter-spacing: 0.8px;
  margin-left: 22px;
  text-align: left;
}
.drAvableTimeline .days-header {
  display: flex;
  justify-content: space-between;
  margin: 6px 10px;
  padding-bottom: 16px;
  width: 100%;
  border-bottom: 1px dashed #545454;
}
.doctorListSlot {
  width: 100%;
  border-radius: 6px;
  background-color: #212121;
  border: 1px solid #333;
  margin-bottom: 10px;
}
.doctorDetails {
  width: calc(100% - 16px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #333;
  padding: 8px;
}
.docImage {
  height: 60px;
  width: 60px;
  border-radius: 30px;
}
.docImage img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.doctorInfoWthImg {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.doctorFullInfo {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 6px;
  justify-content: flex-start;
}
.doctorNmSpc {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.doctorNmSpc .docName {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}
.doctorNmSpc .docSpclty {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  opacity: 0.6;
}
.docExpFeeLan {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  row-gap: 4px;
  column-gap: 20px;
  flex-wrap: wrap;
}
.docEFL {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.eflicon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.docEFL .eflicon svg {
  fill: #b2b2b2;
  height: 16px;
  width: 16px;
}
.docEFL .eflText {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
}
.eflicon.fillnone svg {
  fill: none !important;
}
.docRatvalue svg {
  fill: #ffc700;
  height: 28px;
  width: 28px;
}
.docRatvalue {
  display: flex;
  align-items: center;
  gap: 4px;
}
.docRatvalue .ratingvalue {
  color: #fff;
  font-size: 16px;
}
.doctorRating {
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.docRatCount {
  color: #fff;
  font-size: 14px;
  opacity: 0.6;
}
.doctorDetailSlots {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  gap: 6px;
  width: calc(100% - 94px);
  padding: 10px;
  margin-left: 76px;
}
.doctorDetailSlots .dfultPrimaryBtn {
  width: calc((100% - 96px) / 8) !important;
}
.BADoctorListContain .fullContainArea {
  height: calc(100vh - 140px);
}
.doctorDetailSlots .slot-box {
  background-color: #1c1c1c;
  padding: 4px;
  border-radius: 4px;
  text-align: center;
  width: calc((100% - 96px) / 8);
  min-height: 40px;
  max-height: 50px;
  position: relative;
  border: 1px solid #545454;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  transition: all 0.8s;
  flex-direction: column;
}
.doctorDetailSlots .slot-box .slotInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 4px;
}
.doctorDetailSlots .slot-box:hover {
  background-color: #000000;
}
.slots-container.docList {
  margin: 10px 0px;
}
.doctorDetailSlots .slotTime {
  display: flex;
  flex-direction: column;
  gap: 0px;
}
.doctorDetailSlots .slotTime .date {
  opacity: 1;
  font-size: 14px;
}

.doctorDetailSlots .slotTime .time {
  opacity: 0.7;
  font-size: 12px;
}
.appoinmentBookingSlot .slot-box.active,
.doctorDetailSlots .slot-box.active {
  background-color: #000000;
  border: 1px solid #333;
}
.doctorDetails.bookedSlot {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  padding: 0px 0px 8px 0px;
  border-bottom: 1px dashed #545454;
}
.bookedSlot .slotInfo {
  flex-direction: row;
}
.bdtSlotInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 0px;
  max-width: 200px;
  width: 100%;
}
.bdtSlotInfo .bookingDateTime {
  font-size: 14px;
  color: #fff;
  opacity: 0.6;
}
.bookedSlot .slotInfo .slotTime .date {
  color: #fff;
  font-size: 14px;
  opacity: 1;
}
.bookedSlot .slotInfo .slotTime .time {
  color: #fff;
  font-size: 14px;
  opacity: 1;
}
.bookedSlot .slotInfo .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bookedSlot .slotInfo .icon svg {
  height: 40px;
  width: 40px;
  color: #03ab00;
}
.bookedSlot .slotInfo .slotTime {
  flex-direction: column;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 1px;
}
.appoinBookPatList .filtPatDtl {
  color: #fff;
}

/* doctor booked start */
.doclistCusSlider {
  position: relative;
}
.doclistCusSlider .slick-prev,
.doclistCusSlider .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50px;
  display: block;
  width: 22px;
  height: 22px;
  border-radius: 6px;
  transform: translate(0, -50%);
  cursor: pointer;
  color: #ffffff;
  border: none;
  outline: none;
  background: #333;
  padding: 4px;
}
.doclistCusSlider .slick-prev {
  left: -34px;
}
.doclistCusSlider .slick-next {
  right: -34px;
}
.doctor-carousel {
  width: calc(100% - 68px);
  margin: auto;
  padding: 0px;
}

.BaDocCarousel .slick-slide > div,
.BaDocCarousel .slick-slide,
.BaDocCarousel .slick-track,
.BaDocCarousel .slick-list,
.BaDocCarousel .slick-slider.slick-initialized,
.doctor-carousel.BaDocCarousel {
  height: 100%;
}
.BaDocCarousel .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: 0;
  margin-right: 0;
  /* transform: translate3d(0px, 0px, 0px) !important; */
}

.doctor-cardBA {
  margin: 0;
  padding: 0px 8px;
  width: calc(100% - 17px) !important;
  border-right: 0.5px dashed #545454;
  border-left: 0.5px dashed #545454;
  height: 100%;
}
.doctor-infoBA {
  display: flex;
  align-items: flex-start;
  gap: 6px;
  padding: 6px;
  border-radius: 6px;
  background-color: #333333;
  margin-bottom: 10px;
  min-height: 105px;
}
.doctor-infoBA .docImage {
  height: 86px;
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  border-radius: 6px;
}
.doctor-infoBA .docImage img {
  height: 100%;
  width: 100%;
  border-radius: 6px;
  object-fit: cover;
}
.docNameOther {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
  flex-direction: column;
  width: 100%;
}
.docNameOther .doctorNmSpc {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2px;
  padding-bottom: 4px;
  border-bottom: 1px dashed #545454;
  width: 100%;
}
.docNameOther .doctorNmSpc .docName {
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: #fff;
}
.docNameOther .doctorNmSpc .docSpclty {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: #fff;
  opacity: 0.6;
}
.langW100p {
  width: 100%;
}
.docNameOther .docExpFeeLan {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 12px;
  row-gap: 2px;
  flex-wrap: wrap;
}
.docNameOther .docEFL .eflicon svg {
  fill: #b2b2b2;
  height: 16px;
  width: 16px;
}
.docNameOther .docEFL .eflText {
  color: #fff;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
}
.docNameOther .eflText span {
  margin-left: 4px;
}
.doctor-cardBA .slots-container {
  margin: 4px;
}
.doctor-cardBA .slots-container .slot-box {
  width: calc((100% - 28px) / 2) !important;
  max-height: fit-content;
}
.slick-slide .doctor-cardBA .slots-container {
  overflow-y: overlay;
  max-height: calc(100dvh - 348px);
  position: relative;
}
.appoinBookPatList .filPatFullinfo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.appoinBookPatList .filterPatlistInd {
  background-color: transparent;
  border-bottom: transparent;
  justify-content: space-between;
}
.faceRegTxt {
  font-size: 14px;
  color: #fff;
  opacity: 0.6;
}
.appoinBookPatList .scanFaceBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
}
/* doctor booked end */
.appoinmentBookingGrp .MuiFormGroup-root.formRadioGroup {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  gap: 4px;
  padding-left: 4px;
}
.appoinmentBookingGrp
  .formControl
  .formRadioGroup
  .MuiFormControlLabel-root
  .MuiButtonBase-root.MuiRadio-root {
  padding: 4px;
}
.slots-container.appoinmentBookingGrp {
  margin: 0px;
}
.appoinBookPatList .filterPatlistInd.active {
  background-color: #333;
}
.drgSafeSkeleton {
  width: 100%;
}
.drgSafeSkeletonCard.MuiPaper-root.MuiPaper-outlined {
  width: 100%;
  background-color: #1c1c1c;
  border: 1px solid #333;
}
.drgSafeSkeletonCardContent.MuiCardContent-root {
  background-color: #1c1c1c;
  color: #fff;
}
.drgSafeSkeletonCard .MuiSkeleton-root.MuiSkeleton-text {
  background-color: #545454;
}
.op7 {
  opacity: 0.7;
}
.drgSafeSkeletonCard .MuiDivider-root.MuiDivider-fullWidth {
  border-style: dashed;
  border-color: #545454;
  margin-bottom: 10px;
}
.MuiCardContent-root.drgSafeSkeletonCardContent
  .MuiTypography-root.MuiTypography-body1,
.MuiCardContent-root.drgSafeSkeletonCardContent
  .MuiTypography-root.MuiTypography-h6 {
  font-family: inter;
}
.footBtnGroup {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.editPrescBtnDark {
  padding: 6px 16px !important;
  font-size: 14px !important;
  border-radius: 4px !important;
  background-color: #333 !important;
  color: #b2b2b2 !important;
  font-family: Inter !important;
  transition: all 0.8s !important;
  border: 1.6px solid #545454 !important;
  text-transform: capitalize !important;
  font-weight: 500;
  min-width: 106px !important;
}
.mt5 {
  margin-top: 5px;
}
.testResultInput td.singlRow {
  padding-bottom: 6px;
  border-bottom: 1px dashed #333;
}
.minWidth100px {
  min-width: 100px;
}
.MuiButtonBase-root.MuiIconButton-root.removeRowBtn {
  color: #e09595;
  background-color: #333;
}
.MuiButtonBase-root.MuiIconButton-root.updateRowBtn {
  color: #ffffff;
  background-color: #333;
}
.opc1 {
  opacity: 1 !important;
}
.consultationDetails {
  padding: 8px;
  display: flex;
  align-items: stretch;
  gap: 10px;
}
.constDtlInd {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  padding: 6px;
  border: 1px solid #333333;
  border-radius: 6px;
  width: 50%;
}
.consultationDetails .costDocImg {
  height: 60px;
  width: 60px;
  border-radius: 6px;
}
.consultationDetails .costDocImg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 6px;
}
.costDocInfo {
  color: #fff;
  width: calc(100% - 60px);
}
.costDNM {
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.costDNM span {
  font-size: 14px;
  font-weight: 400;
  opacity: 0.6;
  margin-left: 10px;
}
.costDt {
  font-size: 14px;
  font-weight: 400;
  color: #b2b2b2;
}
.costDt span {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  margin-left: 10px;
}
.costDNote {
  font-size: 14px;
  font-weight: 400;
  color: #b2b2b2;
}
.costDNote span {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  margin-left: 10px;
}
.slots-container.reviewPatList {
  margin: 0px 0px 10px 0px;
}
.patAddQueuGrp {
  display: flex;
  align-items: center;
  gap: 12px;
}
.patAddQueuGrp .doctorRating {
  align-items: flex-end;
}
.patAddQueuGrp .docRatvalue svg {
  fill: #ffc700;
  height: 18px;
  width: 18px;
}

.slots-container.reviewPatList .doctorListSlot {
  width: 50%;
}
.tskLstTaskNM.fdcolTxt {
  display: flex;
  flex-direction: column;
  gap: 1px;
  align-items: flex-start;
}
.fdcolTxt .subheadTxt {
  opacity: 0.6;
  font-size: 12px;
}
.testNMTstloc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.endOfTestGrp {
  border-bottom: 1px solid #545454 !important;
  padding-bottom: 10px !important;
}
.testNMTstloc .formElement {
  width: auto;
}
.testNMTstloc .formRadioGroup .MuiButtonBase-root.MuiRadio-root {
  padding: 4px;
}
.updateNewTest.formBtnElement {
  justify-content: flex-end;
}
.MuiButtonBase-root.MuiIconButton-root.deleteRowBtn {
  color: #ff9f9f;
  background-color: #333;
}
.comingSoon {
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  color: #fff;
  font-size: 26px;
  font-weight: 400;
  letter-spacing: 1px;
  background-color: #1c1c1c;
  width: 100%;
  border-radius: 12px;
}
.timerCountdown {
  font-size: 16px;
  font-weight: 600;
}
.countdown-days {
  color: #66afc5;
  margin-right: 10px;
}

.countdown-hours {
  color: #e2c541;
  margin-right: 10px;
}

.countdown-minutes {
  color: orange;
  margin-right: 10px;
}

.countdown-seconds {
  color: #cb8bd0;
  margin-right: 10px;
}
.csText {
  opacity: 0.4;
}
.pDemographyImgGrp.invSlctpatient {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
}
.pDemographyImgGrp.invSlctpatient .patImage {
  height: 50px;
  width: 50px;
  border-radius: 40px;
}
.listOfInv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 6px;
  width: 100%;
}
.indvInv.formElement {
  background-color: #1c1c1c;
  width: auto !important  ;
  border-radius: 4px;
  /* border: 1px solid #545454; */
}

.indvInv.formElement .formControl .MuiButtonBase-root.MuiCheckbox-root {
  margin-left: 4px;
  padding: 2px;
}
.indvInv.formElement
  .formControl
  .MuiButtonBase-root.MuiCheckbox-root
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  height: 18px;
  width: 18px;
}
.constWithInv {
  width: 100%;
  margin-bottom: 4px;
  padding-bottom: 4px;
  border-bottom: 1px solid #333;
  margin-left: 10px;
}
.consultDrDT {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  color: #fff;
  margin-bottom: 4px;
  gap: 30px;
}
.cnstDrNam {
  font-size: 14px;
  line-height: 16px;
}
.cnstDrNam span.constDrSpc {
  margin-left: 6px;
  opacity: 0.6;
  font-weight: 400;
}
.constDateTime {
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
}
.constDateTime span {
  margin-right: 6px;
  opacity: 0.6;
}
.constWithInvGrp {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.constWithInvGrp .constWithInv:last-child {
  border: none;
}
.testControlBtnGrp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.removeTest {
  padding: 6px 16px !important;
  font-size: 14px !important;
  border-radius: 4px !important;
  background-color: transparent !important;
  border: 1.6px solid #ff8787 !important;
  color: #ff8787 !important;
  font-family: Inter !important;
  transition: all 0.8s !important;
  text-transform: capitalize !important;
  font-weight: 500;
  transition: all 0.6s;
  min-width: 106px !important;
}

.slot-box.Unavailable {
  background-color: #333333;
  cursor: help;
  border: 1px solid #924749;
}
.slot-box.Unavailable .slotInfo .icon svg {
  color: #f44336;
  height: 20px;
  width: 20px;
}
.slot-box.Unavailable .slotInfo .slotTime {
  color: #f44336;
  font-weight: 400;
}
.docOnOffLineSwitch .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: #3f8350;
}
.docOnOffLineSwitch .MuiSwitch-switchBase.Mui-checked {
  color: #1eff00;
}
.docOnOffLineSwitch .MuiSwitch-switchBase {
  color: #707070;
}

.docOnOffLineSwitch .MuiSwitch-root .MuiSwitch-track {
  background-color: #333333;
  opacity: 1;
}
.docOnOffOption {
  margin-right: 24px;
  padding-right: 0px;
  border-right: 1px solid #545454;
}
.formElement.docOnOffOption {
  max-width: 150px !important;
}
.docOnOffLineSwitch.MuiFormControlLabel-root {
  margin-right: 4px;
}

.days-header-actionBtn .MuiButtonBase-root.MuiIconButton-root.settingsChange {
  background-color: #333333;
  color: #b2b2b2;
  border: 1px solid #545454;
  padding: 4px;
  margin-left: 6px;
}
.rescheduleIcon {
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rescheduleIcon .MuiSvgIcon-root {
  color: #f44336;
}

.doctorDetails.bookedSlot.mUnavailable .bdtSlotInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.bookedSlot.mUnavailable .slotInfo .slotTime {
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 1px;
}

.mUnavailable.bookedSlot .slotInfo .icon svg {
  height: 40px;
  width: 40px;
  color: #f44336;
}

.doctorDetails.bookedSlot.mUnavailable {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background-color: transparent;
  padding: 0px 0px 8px 0px;
  border-bottom: 1px dashed #545454;
}

.patDocList {
  width: 100%;
}
.patDocIndCard {
  width: calc(100% - 2px);
  border-radius: 6px;
  background-color: #212121;
  border: 1px solid #333333;
  margin-bottom: 10px;
}

.patDetails {
  width: calc(100% - 16px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #333;
  padding: 8px;
  gap: 12px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
}
.patDetails .patname {
  font-size: 16px;
  font-weight: 500;
}
.patAge span {
  opacity: 0.6;
}
.patDocDetails .docHead {
  color: #fff;
  opacity: 0.7;
  min-width: 85px;
}
.patDocDetails {
  width: 100%;
}
.patDocInv,
.patDocPrs {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  padding: 6px 8px;
  width: calc(100% - 18px);
}
.patDocPrs {
  border-bottom: 1px dashed #333;
}
.docInvGrp,
.docPrsGrp {
  display: flex;
  align-items: center;
  width: calc(100% - 85px);
  flex-wrap: wrap;
  gap: 8px;
}
.patDocInv .invDtlInd,
.docPrsGrp .prsDtl {
  display: flex;
  align-items: center;
  background-color: #1c1c1c;
  border: 1px solid #545454;
  border-radius: 6px;
  color: #fff;
  padding: 2px 6px;
  gap: 6px;
}

.docPrsGrp .docPrdDt {
  display: flex;
  align-items: center;
  gap: 4px;
  opacity: 0.6;
  font-size: 12px;
  font-weight: 400;
}

.docPrdDt .MuiSvgIcon-root {
  height: 18px;
  width: 18px;
}
.invDtlInd .MuiButtonBase-root.MuiIconButton-root,
.prsDtl .MuiButtonBase-root.MuiIconButton-root {
  color: #fff;
  background-color: #333333;
  border: 1px solid #545454;
  padding: 6px;
}
.invCheck .MuiFormControlLabel-root {
  margin-left: 0px;
  margin-right: 8px;
}
.invCheck .MuiButtonBase-root.MuiCheckbox-root {
  padding: 4px;
}
.invCheck .MuiButtonBase-root.MuiCheckbox-root .MuiSvgIcon-root {
  color: #b2b2b2;
}
.invDtl .invDate {
  margin-left: 4px;
  opacity: 0.6;
  font-size: 12px;
  font-weight: 400;
}
.invDtlInd .MuiButtonBase-root.MuiIconButton-root svg,
.prsDtl .MuiButtonBase-root.MuiIconButton-root svg {
  height: 18px;
  width: 18px;
}
.prsDtl .docDtl .docSpecl {
  margin-left: 2px;
  opacity: 0.6;
}
.docInvGrp .invDtlInd {
  min-height: 32px;
  max-height: 32px;
}

.rfContContainDetails .bookedSlot .doctorNmSpc {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0px;
  flex-direction: column;
}
.rfContContainDetails .doctorFullInfo {
  width: calc(100% - 60px);
}
.rfContContainDetails .doctorInfoWthImg {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}
.rfContContainDetails .doctorDetails .doctorInfoWthImg {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
}
.warnigMsg {
  font-size: 12px;
  color: #b2b2b2;
  margin-top: 6px;
}
/* view apponment update css start */
.viewAppointmentsContainer.fullContainArea {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: calc(100vh - 138px);
  justify-content: flex-start;
  width: 100%;
}
/* view apponment update css end */

svg.centAvlTest {
  height: 18px;
  width: 18px;
  color: #03ab00;
}

.MuiAccordionDetails-root table.testResulttable {
  width: 100%;
}

.atchFilWthfilNm {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-end;
  justify-content: flex-end;
}
.updateNewTest .atchFilWthfilNm {
  flex-direction: row;
  align-items: center;
}

.atchFilWthfilNm .elemntFilNm {
  display: -webkit-box;
  width: 105px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #fff;
  opacity: 0.6;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
}
.updateNewTest .atchFilWthfilNm .attachedFileDtl {
  border: 1px solid #545454;
  padding: 0 0 0 6px;
  cursor: pointer;
}
.attachedFileDtl {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  color: #fff;
  opacity: 0.7;
}

.attachedFileDtl .MuiButtonBase-root.MuiIconButton-root {
  height: 32px;
  width: 32px;
  border-radius: 12px;
}
.attachedFileDtl .MuiButtonBase-root.MuiIconButton-root svg {
  height: 24px;
  width: 24px;
  color: #ff9f9f;
}
.feedbackRatingChart .text-group {
  transform: translate(37%, 90%);
}
.listOfDataConst ul {
  padding-inline-start: 0px;
  list-style-type: none;
  margin: 0;
}
.listOfDataConst ul li .lstDataInd .lstName {
  font-size: 10px;
  color: #fff;
  font-weight: 500;
  flex: 30% 1;
}
.listOfDataConst ul li .lstDataInd .lstValue {
  font-size: 12px;
  color: #000000;
  font-weight: 500;
  flex: 1 1;
  padding: 1px 8px;
  cursor: pointer;
}
.listOfDataConst ul li .lstDataInd {
  display: flex;
  align-items: center;
  width: 100%;
}
.list1 {
  background-color: #015761;
}
.list2 {
  background-color: #026d77;
}
.list3 {
  background-color: #018996;
}
.list4 {
  background-color: #06abba;
}
.list5 {
  background-color: #0dc0d0;
}
.list6 {
  background-color: #15ccdd;
}
.list7 {
  background-color: #21d5e6;
}
.cardFrom .formControl .MuiSelect-select.MuiSelect-outlined {
  padding: 2px 32px 2px 12px;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.cardFrom .w110 {
  min-width: 114px;
}
.align-item-center {
  align-items: center !important;
}
.todayIndc {
  color: #fff;
  font-weight: 500;
  text-align: left;
  opacity: 0.6;
}
.width50per {
  width: 50% !important;
}
.pRelative {
  position: relative !important;
}
.spdMeeterLabel {
  position: relative;
  transform: rotate(270deg) translate(82px, -100px);
  left: 0;
  color: #fff;
}

.gaugeChartContainer {
  position: relative;
  width: 100%; /* Adjust size as needed */
  height: 100%; /* Adjust height for the half-circle gauge */
}

.gaugeChartLabels {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gaugeLabel {
  position: absolute;
  font-size: 10px;
  color: #ffffff;
  opacity: 0.9;
}

.gaugeLabel1 {
  left: 6%;
  top: 38%;
  transform: rotate(0deg);
}

.gaugeLabel2 {
  left: 21%;
  top: 0%;
  transform: rotate(0deg);
}

.gaugeLabel3 {
  left: 50%;
  top: -15%;
  transform: translateX(-50%) rotate(0deg);
}

.gaugeLabel4 {
  right: 21%;
  top: 1%;
  transform: rotate(0deg);
}

.gaugeLabel5 {
  right: 6%;
  top: 38%;
  transform: rotate(0deg);
}
.w90 {
  min-width: 90px;
}
.anDCenterGrf {
  width: 100%;
}
.hw100imp {
  height: 100% !important;
  width: 100% !important;
}
.maxw140 {
  max-width: 140px;
}
.maxHeight150 {
  height: 150px !important;
}
.cnameWithRat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
}
.cenRate {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 18px;
  font-weight: 600;
}
.cenRate svg {
  color: #e28800;
}
.centerHilights {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 4px;
  margin-bottom: 8px;
}
.cenCardInd {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background-color: #e7e7e7;
  border-radius: 2px;
  padding: 4px;
  width: calc(100% / 3);
}
.cenCardInd.half {
  width: calc(100% / 2);
}
.smlTxt {
  font-size: 12px;
  opacity: 0.7;
}
.keyTxt {
  font-size: 18px;
  font-weight: 600;
}
.bestOneList {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}
.feedbackRatingChart svg {
  min-height: 100px !important;
}
.mb-8 {
  margin-bottom: -8px;
}
.invAddedListGrp {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  flex-wrap: wrap;
}
.invAddedIndv {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background-color: #1c1c1c;
  border-radius: 4px;
  padding: 2px 6px;
}
.invAddedIndv .invAddName {
  font-size: 12px;
  color: #fff;
}
.invAddedIndv .MuiButtonBase-root.MuiIconButton-root {
  padding: 2px;
  background-color: #707070;
}
.invAddedIndv .MuiButtonBase-root.MuiIconButton-root svg {
  height: 14px;
  width: 14px;
  color: #000000;
}
.upperAgelimit_row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.medicationGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #545454;
}
.gap10 {
  gap: 10px !important;
}

.AdminRightFlotingContainer
  .stpMedicationList
  .formElement
  .MuiFormControlLabel-root.formRadioField
  .MuiButtonBase-root.MuiRadio-root {
  padding: 4px;
}
.medicationGroup .formControl .feformGroup {
  gap: 50px;
  row-gap: 2px;
}
.stpMedicationList {
  width: 100%;
}
.stpMedicationList ul {
  padding-inline-start: 22px;
  color: #fff;
  list-style-type: decimal;
  margin: 0;
}
.stpMedicationList ul li {
  border-bottom: 1px solid #545454;
}
.stpMedicationList ul li .medStpwthRmv:last-child {
  border-bottom: none;
}
.regimeDtl {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}
.regimeName {
  font-size: 16px;
  line-height: 18px;
  color: #fff;
}
.rmvRegim {
  color: #ed3237;
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}
.medStpwthRmv {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  margin-bottom: 4px;
  margin-top: 4px;
  padding-bottom: 4px;
  border-bottom: 1px dashed #1c1c1c;
}
.medRecStpGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
  flex-wrap: wrap;
}
.medRecStpInd {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 6px;
  background-color: #1c1c1c;
  border-radius: 4px;
  padding: 2px 4px;
  font-size: 12px;
  font-weight: 400;
}
.medStpwthRmv .MuiButtonBase-root.MuiIconButton-root {
  padding: 6px;
  background-color: #444444;
}
.medStpwthRmv .MuiButtonBase-root.MuiIconButton-root svg {
  color: #ff9ea1;
  height: 16px;
  width: 16px;
}
.medRecStpInd span {
  opacity: 0.6;
}
.fullheightwidth {
  height: 100% !important;
  width: 100% !important;
}

.testResultInputCalendarDiv {
  min-width: 250px !important;
}

.testResultInputCalendar {
  display: flex !important;
}
.testRmvExtarnalfil {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  flex-direction: row;
}
.removeTestfrmList {
  font-size: 12px;
  color: #ff8787;
  font-style: italic;
  cursor: pointer;
}
.mw212 {
  max-width: 212px;
}

/* new stp ui start */
.regimeWithComponent ul.regimeList {
  list-style-type: disclosure-closed;
  color: #fff;
  padding-inline-start: 20px;
  margin: 4px 0px;
}

.CompoundDtlgrp ul.compoundGrpUl {
  padding-inline-start: 10px;
  margin: 6px 0px;
  counter-reset: list-counter;
  list-style: none;
}
.compoundGrpUl li.compoundGrpList {
  padding: 4px;
  border: 1px solid #707070;
  z-index: 2;
  position: relative;
  padding-left: 10px;
  margin-bottom: 10px;
}

.compoundGrpUl li.compoundGrpList::before {
  counter-increment: list-counter;
  content: counter(list-counter);
  position: absolute;
  left: -20px;
  top: -1px;
  background-color: #707070;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.compHeader {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.orThenCompGrp {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  min-width: fit-content;
}
.compName {
  font-size: 14px;
  line-height: 16px;
}
.AdminRightFlotingContainer .orThenCompGrp .formControl,
.AdminRightFlotingContainer .orThenCompGrp .formElement {
  width: auto;
}
.AdminRightFlotingContainer
  .orThenCompGrp
  .formElement
  .MuiFormControlLabel-root.formRadioField {
  margin-right: 8px;
}
.compoundGrpUl
  .formControl
  .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd
  .MuiButtonBase-root.MuiRadio-root {
  padding: 2px;
}
.MuiButtonBase-root.MuiIconButton-root.rmvCompound {
  color: #000000;
  padding: 4px;
  background-color: #ff7c80;
  height: 24px;
  width: 24px;
}
.compoundIndv {
  margin-bottom: 10px;
}
.compfulDtl {
  border-bottom: 1px dashed #333;
  padding-bottom: 4px;
  margin-bottom: 6px;
}
.compoundIndv.compThen {
  margin-left: 10px;
  position: relative;
}
.compoundIndv.compAnd {
  margin-left: 10px;
  position: relative;
  padding: 2px 2px 2px 2px;
  border: 1px solid #333333;
}
.compChild {
  display: none;
}
.compAnd .compChild,
.compOr .compChild,
.compThen .compChild {
  display: block;
  margin-bottom: 2px;
  font-size: 14px;
  font-style: italic;
  text-decoration: underline;
  color: #fff;
  opacity: 0.6;
}

.compThen .compfulDtl {
  position: relative;
}
.compThen::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  width: 1px;
  height: 22px;
  background: #b2b2b2;
}
.compAnd::before {
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  width: 1px;
  height: 22px;
  background: #b2b2b2;
}
.compThen .compChild::before {
  content: "";
  position: absolute;
  top: 12px;
  left: -2px;
  transform: translate(-100%, -50%);
  width: 8px;
  height: 1px;
  background: #b2b2b2 !important;
}
.compAnd .compChild::before {
  content: "";
  position: absolute;
  top: 12px;
  left: -2px;
  transform: translate(-100%, -50%);
  width: 8px;
  height: 1px;
  background: #b2b2b2 !important;
}

.compOr .compChild::before {
  content: "";
  position: absolute;
  top: 12px;
  left: -2px;
  transform: translate(-100%, -50%);
  width: 8px;
  height: 1px;
  background: transparent;
}
.hidebtn {
  display: none !important;
}
.addAndCompound {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}
.addAndCompBtn {
  padding: 2px 10px !important;
  font-size: 14px !important;
  border-radius: 4px !important;
  background-color: #333 !important;
  color: #fff !important;
  font-family: Inter !important;
  transition: all 0.8s !important;
  border: 1.6px solid #707070 !important;
  text-transform: capitalize !important;
  font-weight: 500;
  min-width: 80px !important;
}
.MuiButtonBase-root.MuiIconButton-root.listAgegroup {
  color: #fff;
  background-color: #1f1f1f;
  border: 1px solid #545454;
}
/* new stp ui end */
.react-datepicker__tab-loop .react-datepicker-popper {
  width: max-content;
}
.AdminRightFlotingContainer .ListAgeGroupModel .elementFormContainer {
  padding: 20px 0px 20px 0px;
}
.listOfAgeGrp {
  width: 100%;
  list-style-type: none;
  color: #000;
  padding-inline-start: 0px;
  margin: 0px;
}
.ageListInd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding-bottom: 4px;
  margin-top: 4px;
  border-bottom: 1px dashed #c5c5c5;
}
.updateListBtn {
  display: flex;
  align-items: center;
  gap: 4px;
}
.updateListBtn .MuiButtonBase-root.MuiIconButton-root {
  height: 24px;
  width: 24px;
  padding: 4px;
  background-color: #e7e7e7;
}
.updateListBtn .MuiButtonBase-root.MuiIconButton-root svg,
.updateListBtn .MuiButtonBase-root.MuiIconButton-root .MuiSvgIcon-root {
  height: 18px;
  width: 18px;
}
.ListAgeGroupModel .modelTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.startTestGrp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  color: #fff;
}
.startTestBtn {
  padding: 4px 16px !important;
  font-size: 14px !important;
  border-radius: 4px !important;
  background-color: #333333 !important;
  color: #fff !important;
  font-family: Inter !important;
  transition: all 0.8s !important;
  border: 1.6px solid #545454 !important;
  text-transform: capitalize !important;
  font-weight: 500;
  min-width: 106px !important;
}
.startTestBtn.active {
  padding: 4px 16px !important;
  font-size: 14px !important;
  border-radius: 4px !important;
  background-color: #00adef !important;
  color: #fff !important;
  font-family: Inter !important;
  transition: all 0.8s !important;
  border: 1.6px solid #00adef !important;
  text-transform: capitalize !important;
  font-weight: 500;
  min-width: 106px !important;
}

.startTestBtn.loading {
  padding: 4px 16px !important;
  font-size: 14px !important;
  border-radius: 4px !important;
  background-color: #333333 !important;
  color: #fff !important;
  font-family: Inter !important;
  transition: all 0.8s !important;
  border: 1.6px solid #545454 !important;
  text-transform: capitalize !important;
  font-weight: 500;
  min-width: 106px !important;
}

@keyframes blinkDot {
  50% {
    color: transparent;
  }
}
.startTestBtn .loader__dot {
  display: none;
}
.startTestBtn.loading .loader__dot {
  animation: 1s blinkDot infinite;
  display: block;
}
.startTestBtn.loading .loader__dot:nth-child(2) {
  animation-delay: 250ms;
}
.startTestBtn.loading .loader__dot:nth-child(3) {
  animation-delay: 500ms;
}
.tNameSubH {
  font-size: 14px;
  opacity: 0.8;
}
.scrlList420 {
  height: 420px;
  overflow: overlay;
}
.tskLstTaskNM.eclipTextlin2 {
  font-size: 14px;
  font-family: "Inter";
  display: -webkit-box !important;
  width: 220px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: #b2b2b2;
  text-overflow: ellipsis;
  margin-right: 2px;
}
ul.regimeLstVewPg {
  padding-inline-start: 16px;
  color: #b2b2b2;
  margin: 6px 0px;
}
.regimeTooltip .medRecStpInd {
  background-color: #d8d8d8 !important;
  color: #000;
}
.regimeWithComponent {
  width: 100%;
}
.kitCaptureImg {
  width: 150px;
  height: 200px;
  border-radius: 8px;
  position: relative;
  background-color: #1c1c1c;
  border: 1px solid #545454;
  display: flex;
  align-items: center;
  justify-content: center;
}
.captureInstrc {
  color: #fff;
  opacity: 0.6;
  font-size: 12px;
  font-weight: 400;
  padding: 4px;
  text-align: center;
}
.kitCaptureImg img {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  object-fit: contain;
}
.RDKTestCapture {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}
.submitRdkTestResult {
  padding: 4px 16px !important;
  font-size: 14px !important;
  border-radius: 4px !important;
  background-color: #00adef !important;
  color: #fff !important;
  font-family: Inter !important;
  transition: all 0.8s !important;
  border: 1.6px solid #00adef !important;
  text-transform: capitalize !important;
  font-weight: 500;
  min-width: 106px !important;
}
.rdkTestBtnGrp {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.actionRdkBtnGrp {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
}
.MuiButtonBase-root.MuiIconButton-root.editValueBtn {
  color: #b2b2b2;
  background-color: #333;
}

.dsHeight {
  height: calc(100dvh - 196px) !important;
}
.dsHeader {
  font-size: 16px !important;
}
.dsFloatingAreaClose {
  padding: 0px !important;
}

.dsCloseBtn {
  font-size: 16px !important;
}

.srcPatViewDoc {
  width: 390px !important;
}

.bookedPatInfo .patId {
  font-size: 8px;
  color: #fff;
  opacity: 0.7;
  line-height: 12px;
  font-weight: 400;
  letter-spacing: 0.8px;
  margin-left: 22px;
  text-align: left;
}

.rdkTestKitCameraSel {
  padding: 5px !important;
  margin-bottom: 10px;
  border-radius: 5px;
}

.testNameSearch {
  max-width: 220px !important;
}
.patDocSrchMainDiv {
  width: 550px !important;
}

.patientIdNameReview {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start !important;
  align-items: flex-start !important;
  justify-content: space-between;
  gap: 5px !important;
}

.humanbodyParts svg {
  height: 60dvh;
}
.visualSlectionContainer {
  display: flex;
  width: 100%;
  gap: 20px;
  overflow: hidden;
  overflow-x: overlay;
}
.labelSymptoms {
  max-height: 58dvh;
  min-width: 350px;
  max-width: 350px;
  overflow: overlay;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 6px;
  justify-content: flex-start;
  padding: 10px 4px 4px 4px;
}
.finalSymptoms::-webkit-scrollbar,
.labelSymptoms::-webkit-scrollbar {
  width: 6px !important;
}
.finalSymptoms::-webkit-scrollbar-thumb,
.labelSymptoms::-webkit-scrollbar-thumb {
  background-color: #b2b2b2 !important;
}
.finalSymptoms::-webkit-scrollbar-track,
.labelSymptoms::-webkit-scrollbar-track {
  background: rgb(202 202 202 / 60%) !important;
}
.labelSympInd {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 102px;
  width: 102px;
  /* border: 2px solid #b2b2b2; */
  cursor: pointer;
  position: relative;
  background-color: #fff;
  padding: 4px;
  border-radius: 50%;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow: 5px 5px 8px #ebebeb, -5px -5px 8px #ffffff;
}
.labelSympInd img {
  height: calc(100% - 4px);
  width: calc(100% - 4px);
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #f9f9f9;
}
.lvlSyHeading {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}
.noDatagrp {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 150px;
}
.btext {
  font-size: 24px;
  font-weight: 600;
  opacity: 0.7;
}
.subtext {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  opacity: 0.5;
  text-align: center;
}
.labelSympInd.active {
  background: #ffab91;
}
.levelSymptGrp {
  border-right: 1px dashed #b2b2b2;
  padding-right: 10px;
}
.levelSymptGrp:last-child {
  padding-right: 0px;
  border-right: none;
  width: 100%;
}
.vsContainer {
  width: 100%;
  height: 100%;
}
.finalSymptoms {
  max-height: 58dvh;
  overflow: hidden;
  overflow-y: overlay;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 6px;
  justify-content: flex-start;
  padding: 10px 4px 4px 4px;
}
.finalSymptList {
  width: 100%;
}
.finalSymptList .MuiList-root .MuiListItem-root .MuiListItemIcon-root {
  min-width: 28px;
}
.finalSymptList .MuiList-root .MuiListItem-root.MuiListItem-gutters {
  margin-bottom: 0px;
  margin-top: 4px;
}
.finalSymptList
  .MuiButtonBase-root.MuiListItemButton-root
  .MuiButtonBase-root.MuiCheckbox-root {
  color: #545454;
}
.finalSymptList
  .MuiButtonBase-root.MuiListItemButton-root:has(
    .MuiButtonBase-root.MuiCheckbox-root.Mui-checked
  ) {
  background-color: #ffe2d9;
}
.finalSymptList
  .MuiButtonBase-root.MuiListItemButton-root
  .MuiListItemText-root
  .MuiTypography-root {
  font-size: 14px !important;
  font-family: "Inter";
}
.minW90px {
  width: auto;
  min-width: 90px;
}
.minW150px {
  width: auto;
  min-width: 150px;
}
.minW120px {
  width: auto;
  min-width: 120px;
}
.minW110px {
  width: auto;
  min-width: 110px;
}
.nsviewApointDetailstepTen,
.nsviewApointDetailstepNine {
  width: 100%;
}
.__floater__body .react-joyride__tooltip div button[data-action="primary"] {
  background-color: #ff9900 !important;
}
.__floater__body .react-joyride__tooltip div button[data-action="back"] {
  color: #ff9900 !important;
}
.ripple circle:first-child {
  animation: ripple 2s infinite;
  transform-origin: center;
}

@keyframes ripple {
  0% {
    r: 30;
    opacity: 1;
  }
  50% {
    r: 40;
    opacity: 0.5;
  }
  100% {
    r: 50;
    opacity: 0.1;
  }
}

.regControl {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}
.justContFlexEnd {
  justify-content: flex-end !important;
}
.alignItemFlexEnd {
  align-items: flex-end;
}
.AdminRightFlotingContainer .flotingAreaClose.dsFloatingAreaClose.stpHead {
  padding: 13px 70px 13px 0px !important;
  width: calc(100% - 70px) !important;
}
.AdminRightFlotingContainer .elementFormContainer.stpContainer {
  padding: 20px 70px 20px 30px !important;
  width: calc(100% - 100px) !important;
}
.AdminRightFlotingContainer .elementFooter.stpFooter {
  padding: 10px 70px 10px 30px;
  width: calc(100% - 100px);
}
.stpCheckBox {
  margin-right: 4px !important;
}
.stpCheckBox .MuiButtonBase-root.MuiCheckbox-root {
  padding: 6px;
  color: #8a8a8a;
}
.stpWithCheckBox .MuiAccordionSummary-content,
.stpWithCheckBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.regimeWithComponent.stpView ul.regimeList {
  list-style-type: none;
  color: #fff;
  padding-inline-start: 24px;
  margin: 4px 0px;
}
.regimeWithComponent.stpView ul.regimeList .regimeDtl {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 0px;
  margin-left: -22px;
}
.compIndCheck {
  /* height: 34px;
  width: 34px;
  position: absolute;
  left: -18px;
  top: -24px; */
  height: 28px;
  width: 28px;
  position: absolute;
  left: -28px;
  top: -1px;
  background-color: #454545;
  display: flex;
  align-items: center;
  justify-content: center;
}
.compIndCheck .stpCheckBox {
  margin-left: 0px;
  margin-right: 0px;
}
.stpView .compoundGrpUl li.compoundGrpList::before {
  display: none;
}
.stpView .ml20 {
  margin-left: 20px;
}
.stpView .compoundIndv.compThen,
.stpView .compoundIndv.compAnd {
  margin-left: 20px;
}
.stpView .regimeList .regimeli {
  border-bottom: 1px solid #b2b2b2;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
.stpContainer .stpCheckBoxIndInv {
  background-color: #333333;
  padding: 2px 6px;
  margin-right: 4px;
  margin-bottom: 4px;
  border-radius: 4px;
}
.stpContainer .stpCheckBoxIndInv .MuiButtonBase-root.MuiCheckbox-root {
  margin-left: 0px !important;
}
.mt4 {
  margin-top: 4px;
}
.ddContainer {
  color: #fff;
}
.compoundName {
  font-size: 16px;
  line-height: 18px;
  color: #fff;
}
.MuiFormGroup-root.formRadioGroup.genericRadioGrp {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0px;
  padding-left: 6px;
}
.compGenUl {
  padding-inline-start: 20px;
  margin-top: 4px;
  width: 100%;
}
.compGenUl .compGenUlLi {
  margin-bottom: 4px;
  padding-bottom: 4px;
  border-bottom: 1px dashed #b2b2b2;
}
.complaintList .complaintListUl {
  list-style-type: circle;
}
.docPrsGrp.pastPresPE {
  width: 100% !important;
}
.pastPresPE .prsDtl .docDtl {
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  display: flex;
  font-weight: 400;
  line-height: 14px;
}
.zoyeControlerbtn {
  position: absolute;
  left: 0;
  bottom: 0;
}
.MuiButtonBase-root.MuiIconButton-root.navIcoBtn {
  padding: 4px;
  color: #ffffff;
  width: 30px;
  height: 30px;
  border-radius: 4px !important;
  background-color: #575757 !important;
}
.MuiButtonBase-root.MuiIconButton-root.navIcoBtn:hover {
  background-color: #1c1c1c !important;
}

.zcbgSection {
  display: grid;
  grid-template-columns: repeat(8, 1fr) 100px;
  grid-template-rows: repeat(3, 1fr);
  place-items: center;
  grid-gap: 1px;
  width: calc(30px * 8 + 108px);
  height: calc(30px * 3 + 2px);
}

.zcbgSection ~ .zcbgSection {
  margin-top: 50px;
}

/* Directional arrows */
.zcbgSection > .navIcoBtn:nth-child(1) {
  grid-column: 1;
  grid-row: 1;
}

.zcbgSection > .navIcoBtn:nth-child(2) {
  grid-column: 2;
  grid-row: 1;
}

.zcbgSection > .navIcoBtn:nth-child(3) {
  grid-column: 3;
  grid-row: 1;
}

.zcbgSection > .navIcoBtn:nth-child(4) {
  grid-column: 1;
  grid-row: 2;
}

.zcbgSection > .navIcoBtn:nth-child(5) {
  grid-column: 2;
  grid-row: 2;
}

.zcbgSection > .navIcoBtn:nth-child(6) {
  grid-column: 3;
  grid-row: 2;
}

.zcbgSection > .navIcoBtn:nth-child(7) {
  grid-column: 1;
  grid-row: 3;
}

.zcbgSection > .navIcoBtn:nth-child(8) {
  grid-column: 2;
  grid-row: 3;
}

.zcbgSection > .navIcoBtn:nth-child(9) {
  grid-column: 3;
  grid-row: 3;
}

/* Zoom, focus, and auto-focus buttons */
.zcbgSection > .navIcoBtn.zoomIn {
  grid-column: 5;
  grid-row: 2;
}

.zcbgSection > .navIcoBtn.zoomOut {
  grid-column: 6;
  grid-row: 2;
}

.zcbgSection > .navIcoBtn.focusIn {
  grid-column: 5;
  grid-row: 3;
}

.zcbgSection > .navIcoBtn.focusOut {
  grid-column: 6;
  grid-row: 3;
}

.zcbgSection > .navIcoBtn.autoFocus {
  grid-column: 7;
  grid-row: 3;
}
.zcbgSection > .backmainMenu {
  grid-column: 9;
  grid-row: 3;
}
.zcbgSection > .ptzContHead {
  grid-column: 5 / 9;
  grid-row: 1;
}
.zcbgSection > .emptyCell {
  grid-column: 4;
  grid-row: 3;
}
.zcbgSection > .emptyCell2 {
  grid-column: 8;
  grid-row: 3;
}
.zoyeControlerbtnGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 6px;
  background-color: #333333;
  gap: 12px;
  position: relative;
}
.MuiButtonBase-root.MuiIconButton-root.navIcoBtn.emptyCell2:hover,
.MuiButtonBase-root.MuiIconButton-root.navIcoBtn.emptyCell:hover,
.MuiButtonBase-root.MuiIconButton-root.navIcoBtn.emptyCell2,
.MuiButtonBase-root.MuiIconButton-root.navIcoBtn.emptyCell {
  background-color: transparent !important;
}

.zcbgSection .backmainMenu {
  width: 100px;
  height: 30px;
  color: #ff3a3a;
  font-style: italic;
  text-transform: capitalize;
  background-color: #575556;
  font-size: 14px;
  letter-spacing: 1px;
}
.zcbgSection .backmainMenu:hover {
  background-color: #1c1c1c;
}
.ptzContHead {
  color: #fff;
}

/* seviarity css */
.severity-selector {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  margin: 4px 0;
}

.option {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 2px;
  cursor: pointer;
  padding: 4px;
  border-radius: 6px;
  transition: border-color 0.3s ease;
  position: relative;
  color: #545454;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid #ffcebf;
}

.option.selected {
  border-color: #ff6a3b;
  border-width: 1.2px;
  color: #1c1c1c;
}

.option .line {
  position: relative;
  display: flex;
  align-items: center;
  width: 16px;
  height: 3px;
  pointer-events: auto;
}

.mild {
  background-color: #28a745;
}

.moderate {
  background-color: #ffc107;
}

.severe {
  background-color: #dc3545;
}
.vsOprionSelect {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 60px;
  padding-left: 46px;
  background-color: #ffefea;
  margin-bottom: 2px;
}
.sinceformControl.MuiFormControl-root {
  margin: 0px;
}
.sinceformControl .MuiSelect-select.MuiInputBase-input {
  font-size: 12px;
  font-weight: 500;
  min-height: 20px;
  padding: 0;
}
.sinceformControl .MuiInputBase-root.MuiInput-root.MuiInput-underline {
  margin-top: 6px;
}
.sinceformControl
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
  -webkit-transform: translate(0, 8px) scale(1);
  -moz-transform: translate(0, 8px) scale(1);
  -ms-transform: translate(0, 8px) scale(1);
  transform: translate(0, 8px) scale(1);
}
.sinceformControl .MuiInputLabel-root.Mui-focused {
  color: #a5a5a5 !important;
}
.sinceformControl .MuiSvgIcon-root.MuiSelect-icon {
  color: #ff5722;
}
.sinceformControl
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-shrink {
  -webkit-transform: translate(-2px, -2px) scale(0.7);
  -moz-transform: translate(-2px, -2px) scale(0.7);
  -ms-transform: translate(-2px, -2px) scale(0.7);
  transform: translate(-2px, -2px) scale(0.7);
}
/* seviarity css end */

.antbticlist svg {
  fill: #000000;
  height: 20px;
  width: 20px;
  margin-left: 3px;
}
.antbtic svg {
  fill: #fff;
  height: 20px;
  width: 20px;
}
.antbtic svg circle.st0 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 1;
  stroke-miterlimit: 10;
}
.antbtic svg line.st1 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
