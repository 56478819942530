@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap");
.onboardingSliderContainer {
  height: 100%;
  width: 100%;
  display: block;
}
.onboardingSliderContainArea {
  height: 100%;
  width: 100% !important;
  margin: 0;
  padding: 0;
}
.onboardingSliderWorkSpace {
  padding: 14px 70px 20px 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 12px;
  height: calc(100% - 34px);
}

.onBoardslider {
  width: 95vw;
}

.sliderElement {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  gap: 60px !important;
  flex-direction: row !important;
  background-color: transparent !important;
  /* padding: 10px !important; */
}
.sliderImg {
  height: 50vh;
  width: 100%;
  min-width: 600px;
  max-width: 620px;
}

.sliderImg img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.slideTextArea {
  padding: 20px 50px 20px 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
}
.slideTxtHead {
  font-family: "Abril Fatface", serif;
  font-weight: 400;
  font-style: normal;
  color: #fff;
  font-size: 2.4rem;
  line-height: normal;
}
.slideTxte {
  font-family: Inter;
  font-size: 16px;
  color: #fff;
  line-height: 24px;
  font-weight: 300;
}
.slick-dots {
  position: absolute !important;
  bottom: -70px !important;
  display: block;
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  list-style: none !important;
  text-align: center !important;
}
ul.slick-dots {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 1px;
}
.slick-dots li button {
  font-size: 0 !important;
  line-height: 0 !important;
  display: block !important;
  width: 12px !important;
  height: 12px !important;
  padding: 5px !important;
  cursor: pointer !important;
  color: transparent !important;
  border: 0 !important;
  outline: none !important;
  background: white !important;
  border-radius: 6px !important;
}

.slick-dots li.slick-active {
  position: relative !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 40px !important;
  height: 20px !important;
  margin: 0 5px !important;
  padding: 0 !important;
  cursor: pointer;
}
.slick-dots li {
  position: relative !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 20px !important;
  height: 20px !important;
  margin: 0 2px !important;
  padding: 0 !important;
  cursor: pointer;
}

.slick-dots li.slick-active button {
  width: 40px !important;
}

.slick-dots li.slick-active button:before {
  opacity: 0 !important;
  color: black;
}

.slick-dots li button:before {
  font-family: "slick" !important;
  font-size: 6px !important;
  line-height: 20px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 20px !important;
  height: 20px !important;
  content: "•" !important;
  text-align: center !important;
  opacity: 0 !important ;
  color: black !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.slideBtnNxt .MuiButtonBase-root.MuiButton-root.MuiButton-outlined {
  border-radius: 0px !important;
  padding: 10px 18px;
  color: #fff;
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Inter";
  border: 1px solid #fff;
}

.slideTxte a {
  color: #fff200 !important;
  text-decoration: none;
}

.slideTxte p {
  margin: 2px 0px 2px 0px;
}

.fwModelContainAreaMainDiv::-webkit-scrollbar {
  width: 4px !important;
  height: 0px !important;
}

.fwModelContainAreaMainDiv::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 1);
}

.fwModelContainAreaMainDiv::-webkit-scrollbar-track {
  /* background: rgb(18 18 18 / 70%); */
}
