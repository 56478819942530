/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* 404 page start */
section.pageNotFoundContainer {
  height: calc(100vh - 150px);
  background-color: #1c1c1c;
  padding: 100px 240px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.pageNotFoundContainer span.indPara {
  display: block;
  margin-bottom: 10px;
}
.gbHome {
  font-style: italic;
  font-size: 16px;
  color: rgb(255 255 255 / 50%);
}
/* 404 page end */
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* 404 page start */
/* 404 page end */
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */

/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
/* 404 page start */
/* 404 page end */
/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////// */
