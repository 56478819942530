.e-content-wrap::-webkit-scrollbar,
.e-time-cells-wrap::-webkit-scrollbar {
  width: 0px !important;
  height: 6px !important;
}
.e-content-wrap::-webkit-scrollbar-thumb,
.e-time-cells-wrap::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 1);
}

.e-content-wrap::-webkit-scrollbar-track,
.e-time-cells-wrap::-webkit-scrollbar-track {
  background: rgb(18 18 18 / 70%);
}
.weekViewContainer,
.schedulerCalendarView {
  height: 100%;
  width: 100%;
}
.scheduler-wrapper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  min-width: 85px;
}
.scheduler-wrapper .MuiButton-textPrimary {
  color: #3c3c3c !important;
}
.scheduler-header {
  border: 1px solid transparent;
  height: 100%;
  width: 100%;
}
.schedulerTitelbar {
  background-color: #edebff;
  color: #000000;
  height: 40px;
  line-height: 40px;
  width: 100%;
  font-size: 12px;
  padding: 0 10px;
  font-family: inherit;
  display: flex;
  justify-content: space-between;
}
.overview-toolbar {
  display: flex;
  height: 53px;
  justify-content: space-between;
}
.time-panel {
  display: flex;
  align-items: center;
  justify-content: center;
}
.timeZone,
.currentTime {
  font-size: 12px;
  color: #5c5c5c;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
}
.timeZone svg,
.currentTime svg {
  height: 18px;
  width: 18px;
}
.e-toolbar .e-toolbar-items {
  background: #fafafa;
}
.e-toolbar .e-toolbar-items {
  border-radius: 0 0 0 0;
  display: inline-block;
  height: 100%;
  min-height: 42px;
  vertical-align: middle;
}

.schedule-overview
  .overview-toolbar
  .e-toolbar
  .e-toolbar-item:not(.e-separator) {
  padding: 3.5px;
}
.e-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child {
  margin-left: 3px;
}
.e-toolbar .e-toolbar-items .e-toolbar-item:not(.e-separator) {
  height: inherit;
  min-width: 34px;
  padding: 3.5px;
}

.e-toolbar .e-toolbar-items .e-toolbar-item {
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  display: -ms-inline-flexbox;
  display: inline-flex;
  min-height: 42px;
  vertical-align: middle;
  width: auto;
}

.schedule-overview
  .overview-toolbar
  .e-toolbar
  .e-toolbar-item
  .e-tbar-btn.e-btn {
  height: 50px !important;
}
.schedule-overview
  .overview-toolbar
  .e-toolbar
  .e-toolbar-item
  .e-tbar-btn.e-tbtn-txt {
  display: inline-grid;
}
.schedule-overview .overview-toolbar .e-toolbar .e-toolbar-item .e-tbar-btn {
  padding: 1.5px;
}

.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn {
  height: calc(100% - 10px);
  margin: 4px 0;
  min-height: 0;
  min-width: 0;
  padding: 0 1.5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
}
.e-toolbar .e-toolbar-item .e-tbar-btn.e-btn {
  line-height: 15px;
}
.e-toolbar .e-toolbar-items .e-toolbar-item > * {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  text-overflow: ellipsis;
}
.e-toolbar .e-tbar-btn {
  background: #fafafa;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.87);
}
.e-toolbar .e-tbar-btn {
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  padding: 0 1.5px;
  text-align: center;
  text-decoration: none;
  text-transform: none;
}
.text-child,
.e-tbar-btn-text {
  font-size: 12px !important;
}
.e-toolbar .e-toolbar-items .e-toolbar-item.e-separator {
  border: solid #545454;
  border-width: 0 0.5px 0 0;
}

.e-toolbar .e-toolbar-items .e-toolbar-item.e-separator {
  height: calc(100% - 15px);
  vertical-align: middle;
}
.e-toolbar .e-toolbar-items .e-toolbar-item.e-separator {
  margin: 7.5px 3px;
  min-height: 27px;
  min-width: 1px;
}

.e-toolbar .e-toolbar-item.e-template .icon-child {
  height: 23px !important;
  text-align: center;
}

.e-control,
.e-control [class^="e-"],
.e-control [class*=" e-"] {
  box-sizing: border-box;
}

.e-switch-wrapper,
.e-css.e-switch-wrapper {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.e-switch-wrapper,
.e-css.e-switch-wrapper {
  cursor: pointer;
  display: inline-block;
  height: 12px;
  position: relative;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 34px;
}

.e-toolbar .e-toolbar-items .e-toolbar-item input[type="checkbox"] {
  height: auto;
}

.e-switch-wrapper .e-switch,
.e-css.e-switch-wrapper .e-switch {
  -moz-appearance: none;
  height: 1px;
  opacity: 0;
  position: absolute;
  width: 1px;
}

.e-switch-wrapper .e-switch-inner,
.e-css.e-switch-wrapper .e-switch-inner {
  background-color: initial;
}
.e-switch-wrapper .e-switch-inner,
.e-css.e-switch-wrapper .e-switch-inner {
  -ms-transition: all 0.08s linear;
  -webkit-transition: all 0.08s linear;
  border: none;
  border-radius: 20px;
  box-sizing: border-box;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: all 0.08s linear;
  width: 100%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 14px;
  top: 4px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: -2px;
  bottom: -4px;
  background-color: white;
  box-shadow: 0 1px 7px -4px #7a7a7a;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #9592ff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #9592ff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.troggleTemp {
  margin-right: 6px;
}

.overview-toolbar .overview-toolbar-settings {
  border-radius: 0;
  background-color: #f8f9fa;
  border-color: rgba(0, 0, 0, 0.15);
  border-left-width: 0;
  border-top-width: 0;
  border-right-width: 0;
  box-shadow: none;
  color: #495057;
  height: 53px;
  line-height: 1.6;
  outline: none;
  text-transform: capitalize;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.overview-content {
  border: 1px solid transparent;
  border-top: 0;
  display: flex;
  width: 100%;
  height: 100%;
}
.overview-content .left-panel {
  height: 100%;
  width: 100%;
}

.overview-scheduler {
  height: 100%;
  padding: 0px;
  width: 100%;
}
.overview-content .right-panel.hide {
  display: none;
  transform: translateX(100%);
  transition: transform 0.5s ease, width 500ms;
  width: 0;
}
.overview-content .right-panel {
  display: block;
  height: 600px;
  transform: translateX(0%);
  transition: transform 0.5s ease, width 500ms;
  width: 500px;
}
.hide {
  display: none !important;
}

.e-schedule {
  background-color: transparent;
  /* border: 1px solid #545454; */
  border: none;
  height: 100%;
  width: 100%;
}

.e-schedule {
  display: block;
  outline: medium none;
  overflow: hidden;
  position: relative;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.e-toolbar-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.nxtPrvBtn {
  min-width: 30px !important;
}

.e-schedule-toolbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fafafa;
}
.selectDate {
  /* border: none;
    height: 30px;
    box-shadow: none !important;
    outline: none !important;
    background: #fafafa;
    color: #686868; */
  margin-left: 10px !important;
}
.selectDate fieldset {
  /* border: 1px solid rgb(229 229 229) !important;
    border-radius: 9px; */
  border: none;
  outline: none;
}

.MuiButtonBase-root .e-btn-icon {
  line-height: 14px !important;
}
.currentEvent .e-btn-icon {
  color: #000;
}

.e-schedule .e-table-container {
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  vertical-align: top;
  height: 100%;
}
.e-schedule .e-schedule-table {
  border: 0 none;
  border-collapse: separate;
  border-spacing: 0;
  margin: 0;
  table-layout: fixed;
  width: 100%;
}
.e-schedule .e-vertical-view .e-left-indent {
  width: 85px;
  border-width: 0 1px 1px 0px;
  border-color: #545454;
  border-style: solid;
}

.e-schedule .e-outer-table > tbody > tr > td {
  padding: 0;
  vertical-align: top;
}

.e-schedule .e-schedule-table > tbody > tr > th,
.e-schedule .e-schedule-table > tbody > tr > td {
  box-sizing: border-box;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.leftRightCalHeight {
  height: calc(100vh - 290px);
  overflow-y: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;
}
.e-schedule .e-vertical-view .e-date-header-wrap.e-all-day-auto {
  max-height: unset;
}
.e-schedule .e-vertical-view .e-date-header-wrap {
  max-height: 300px;
  overflow: hidden;
}
.e-schedule .e-date-header-wrap {
  border-color: #545454;
  border-style: solid;
  border-width: 0;
  position: relative;
}

.e-schedule .e-vertical-view .e-timeline-wrapper,
.e-schedule .e-vertical-view .e-day-wrapper,
.e-schedule .e-vertical-view .e-all-day-appointment-wrapper {
  height: 0;
  margin: 0;
  padding: 0;
  position: relative;
}

.e-schedule .e-vertical-view .e-date-header-wrap table tbody td:first-child,
.e-schedule .e-vertical-view .e-content-wrap table td:first-child {
  border-left-width: 0;
}
.e-schedule .e-vertical-view .e-date-header-wrap table tbody td {
  background-color: transparent;
  border-color: #545454;
  border-style: solid;
  border-width: 0 0 1px 1px;
  color: rgba(255, 255, 255, 0.87);
  text-align: left;
}
.e-schedule .e-vertical-view .e-resource-cells {
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  height: 40px;
  padding: 8px;
  padding-left: 15px;
}
.e-schedule .e-text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.e-schedule .e-vertical-view .e-date-header-wrap table tbody td.e-header-cells {
  border-bottom-width: 0;
}
.e-schedule .e-vertical-view .e-date-header-wrap table tbody td:first-child,
.e-schedule .e-vertical-view .e-content-wrap table td:first-child {
  border-left-width: 0;
}

.schedule-overview.e-schedule .e-vertical-view .e-header-cells,
.schedule-overview.e-schedule .e-timeline-month-view .e-header-cells {
  padding: 0;
  text-align: center !important;
}
.schedule-overview.e-schedule .date-text {
  font-size: 12px;
}
.schedule-overview.e-schedule .weather-image {
  width: 20px;
  height: 20px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.schedule-overview.e-schedule .weather-text {
  font-size: 11px;
}

.e-schedule .e-vertical-view .e-header-cells {
  font-size: 12px;
  height: 60px;
  padding: 5px;
}

.e-schedule
  .e-vertical-view
  .e-left-indent-wrap
  table
  tbody
  td.e-resource-cells {
  border-bottom-color: rgba(0, 0, 0, 0);
}
.e-schedule .e-vertical-view .e-left-indent-wrap table tbody td {
  background-color: #fff;
  border-color: #545454;
  border-style: solid;
  border-width: 0 1px 1px 0;
  color: rgba(0, 0, 0, 0.87);
}
.e-schedule .e-vertical-view .e-resource-cells {
  color: rgba(0, 0, 0, 0.87);
  font-size: 13px;
  height: 40px;
  padding: 8px;
  padding-left: 15px;
}

.e-schedule .e-vertical-view .e-left-indent-wrap table tbody td.e-header-cells {
  border-bottom-width: 0;
}

.schedule-overview.e-schedule .e-vertical-view .e-header-cells,
.schedule-overview.e-schedule .e-timeline-month-view .e-header-cells {
  padding: 0;
  text-align: center !important;
}
.schedule-overview .e-schedule .e-vertical-view .e-header-cells {
  text-align: center !important;
}
.e-schedule .e-vertical-view .e-left-indent-wrap table tbody td {
  background-color: #fff;
  border-color: #545454;
  border-style: solid;
  border-width: 0 1px 1px 0;
  color: rgba(0, 0, 0, 0.87);
}
.e-schedule
  .e-vertical-view
  .e-all-day-appointment-section.e-appointment-expand {
  transform: rotate(0deg);
}

.e-schedule .e-vertical-view .e-all-day-appointment-section.e-disable {
  display: none;
}
.e-schedule .e-vertical-view .e-all-day-appointment-section {
  bottom: 4px;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
  display: block;
  font-size: 11px;
  height: 25px;
  left: 0;
  line-height: 25px;
  margin: 0 auto;
  position: absolute;
  right: 0;
  transform: rotate(0deg);
  transition: transform 300ms ease-in-out;
  width: 25px;
}
.e-schedule .e-vertical-view .e-all-day-cells:first-child.e-animate {
  transition: 250ms ease-out;
}

.e-schedule
  .e-vertical-view
  .e-all-day-appointment-wrapper
  .e-appointment:not(.e-schedule-event-clone) {
  cursor: default;
}
.e-schedule .e-vertical-view .e-all-day-appointment-wrapper .e-appointment {
  background: #8482ff !important;
  border: 1px solid #e8eaf6;
  border-radius: 2px;
  color: #fff;
  display: flex;
  height: auto;
  min-height: 20px !important;
  overflow: hidden;
  position: absolute;
}
.e-schedule .e-appointment {
  opacity: 1;
  transition: opacity 100ms linear;
}
.e-schedule .e-event-resize.e-left-handler {
  cursor: ew-resize;
  float: left;
  left: 0;
}

.e-schedule .e-event-resize.e-left-handler,
.e-schedule .e-event-resize.e-right-handler {
  height: 100%;
  width: 10px;
}
.e-schedule .e-event-resize {
  position: absolute;
}

.e-schedule
  .e-vertical-view
  .e-all-day-appointment-wrapper
  .e-appointment
  .e-appointment-details {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: auto;
  flex: auto;
  line-height: 20px;
  overflow: hidden;
  padding: 1px 0 1px 4px;
}

.e-schedule
  .e-vertical-view
  .e-all-day-appointment-wrapper
  .e-appointment
  .e-appointment-details
  .e-subject {
  -ms-flex: auto;
  flex: auto;
  font-size: 13px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
}

.e-schedule .e-event-resize.e-right-handler {
  cursor: pointer;
  float: right;
  right: 0;
  top: 2px;
}
.e-schedule .e-event-resize.e-left-handler,
.e-schedule .e-event-resize.e-right-handler {
  height: 100%;
  width: 22px;
}
.e-schedule .e-event-resize {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.e-schedule .e-event-resize .e-top-bottom-resize {
  position: relative;
  cursor: pointer;
  /* bottom: 0;
      right: 4px; */
}
.e-subject .e-time,
.e-subject {
  cursor: pointer;
}
.e-schedule .e-event-resize.e-right-handler .e-left-right-resize {
  height: 18px;
  width: 18px;
  background: #5e5bff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.e-schedule .e-event-resize.e-right-handler .e-left-right-resize span {
  font-size: 12px;
}

.overview-content .right-panel {
  display: block;
  height: 600px;
  transform: translateX(0%);
  transition: transform 0.5s ease, width 500ms;
  width: 500px;
}

.overview-content .right-panel .control-panel {
  background: #f8f9fa;
  border-left: 1px solid #00000026;
  font-size: 14px;
  height: 100%;
  overflow: auto;
  padding: 15px;
  width: 100%;
}

.right-panel .control-panel .col-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.col-left {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
  margin-bottom: 1rem;
}
.col-left label {
  font-size: 12px;
}

.col-right {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
  margin-bottom: 1rem;
}

.e-schedule .e-vertical-view .e-content-wrap,
.e-schedule .e-vertical-view .e-time-cells-wrap {
  position: relative;
}

.e-schedule .e-vertical-view .e-time-cells-wrap {
  overflow: hidden;
}

.e-schedule .e-vertical-view .e-clone-time-indicator,
.e-schedule .e-vertical-view .e-current-time {
  color: #00d1ff;
}
.e-schedule .e-vertical-view .e-clone-time-indicator,
.e-schedule .e-vertical-view .e-current-time,
.e-schedule .e-vertical-view .e-previous-timeline,
.e-schedule .e-vertical-view .e-current-timeline {
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 100%;
}
.e-schedule .e-vertical-view .e-current-time {
  font-size: 11px;
}

.e-schedule .e-vertical-view .e-time-cells-wrap table td {
  border-bottom-color: rgba(0, 0, 0, 0);
}

.e-schedule .e-vertical-view .e-time-cells-wrap table td {
  background-color: #fff;
  border-color: #545454;
  border-style: solid;
  border-width: 0 1px 1px 0;
  color: rgba(0, 0, 0, 0.54);
}
.e-schedule .e-vertical-view .e-time-cells-wrap table td,
.e-schedule .e-vertical-view .e-work-cells {
  font-size: 12px;
  height: 21px;
  text-align: center;
}

.e-schedule .e-vertical-view .e-content-wrap,
.e-schedule .e-vertical-view .e-time-cells-wrap {
  position: relative;
}
.e-schedule .e-content-wrap,
.e-schedule .e-scroll-container {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;
}
.e-schedule .e-content-wrap {
  position: relative;
}

.e-schedule .e-vertical-view .e-date-header-wrap table tbody td:first-child,
.e-schedule .e-vertical-view .e-content-wrap table td:first-child {
  border-left-width: 0;
}
.e-schedule .e-vertical-view .e-timeline-wrapper,
.e-schedule .e-vertical-view .e-day-wrapper,
.e-schedule .e-vertical-view .e-all-day-appointment-wrapper {
  height: 0;
  margin: 0;
  padding: 0;
  position: relative;
}
/* .e-schedule .e-content-wrap .e-day-wrapper {
    /* width: 100%; */
/* }  */

.e-schedule .e-vertical-view .e-previous-timeline {
  border-top: 1px dashed #0089a7;
}

.e-schedule .e-vertical-view .e-current-timeline {
  border-top: 1px Solid #00d1ff;
}
.e-schedule .e-vertical-view .e-current-timeline::before {
  border-radius: 0;
  content: "";
  position: absolute;
  left: 0px;
  margin-top: -8px;
  z-index: 507;
  border: 8px solid transparent;
  border-left-color: #00d1ff;
}

.e-schedule .e-vertical-view .e-current-timeline::after {
  border-radius: 0;
  content: "";
  position: absolute;
  right: 0px;
  margin-top: -8px;
  z-index: 507;
  border: 8px solid transparent;
  border-right-color: #00d1ff;
}

.e-schedule .e-vertical-view .e-previous-timeline,
.e-schedule .e-vertical-view .e-current-timeline {
  z-index: 1;
}

.e-schedule .e-vertical-view .e-date-header-wrap table tbody td:first-child,
.e-schedule .e-vertical-view .e-content-wrap table td:first-child {
  border-left-width: 0;
}

.e-schedule .e-vertical-view .e-alternate-cells {
  border-bottom-style: dotted;
}
.e-schedule .e-vertical-view .e-work-cells {
  background-color: transparent;
  border-color: #545454;
  border-style: solid;
  border-width: 0 0 1px 1px;
  padding: 0;
}
.firstSlot {
  width: 36px;
  height: 21px !important;
}
.secondSlot {
  width: 36px;
  height: 42px !important;
}
.thirdSlot {
  width: 36px;
  height: 84px !important;
}

.e-schedule .e-vertical-view .e-time-cells-wrap table td {
  border-color: transparent;
  border-style: solid;
  border-width: 0 1px 1px 0;
  color: #ffffff;
  background-color: transparent;
}
.e-time-slots span {
  font-size: 12px !important;
  line-height: 12px !important;
}
.e-time-cells.e-time-slots {
  border-bottom-color: #545454 !important;
}
.rightTimeCell {
  border-right: 1px solid #545454;
}
.e-appointment-wrapper {
  position: relative;
}
.e-appointment {
  width: calc(100% - 20px);
  height: 108px;
  min-width: 20px;
  left: 0px;
  background: transparent;
  border: none;
  border-radius: 0px;
  color: rgb(255, 255, 255);
  overflow: hidden;
  position: absolute;
  min-height: 21px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  padding: 0px;
  display: flex;
}

.createEvent {
  width: 800px;
  border: 1px solid rgb(207, 207, 207) !important;
}

.ml110 {
  margin-left: 110px !important;
}

@media screen and (max-width: 762px) and (min-width: 320px) {
  .createEvent {
    width: 100%;
  }
}

.dNone {
  display: none !important;
}
.e-appointment-details {
  padding: 2px 1px 1px 4px;
  height: 100%;
  flex: 1;
}
.e-appointment-details .e-subject {
  min-height: 14px;
  font-size: 12px;
  line-height: 14px;
  white-space: normal;
  word-wrap: break-word;
  display: -webkit-box;
  min-width: 32px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: #fff;
  opacity: 1;
  text-overflow: ellipsis;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.14px;
}
.e-subject {
  /* max-height: 84px; */
  min-height: 16px;
  font-size: 11px;
  line-height: 12px;
  overflow: hidden;
  overflow-wrap: break-word;
  white-space: normal;
  word-wrap: break-word;
}
.e-appointment-details.moreEvent {
  background-color: #1c1c1c !important;
  border: 1px solid #dbdbdb;
  border-radius: 0px !important;
  padding: 0px !important;
  margin: 0px;
  min-width: 32px !important;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  color: #fff !important;
  max-width: 64px;
}
.e-time {
  font-size: 9px;
}
.bgHoliday {
  background-color: #544b81 !important;
  border-color: #726c8d !important;
}
.bgWekOff {
  background-color: #2e2e2e !important;
  border-color: #545454 !important;
}
.bgOnLeav {
  background-color: #822e1a !important;
  border-color: #545454 !important;
}
.idntBox {
  height: 14px;
  width: 16px !important;
  border: 1px solid;
  display: block;
  margin-right: 5px;
}
.etEllipsGrp {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.clrIdntityGrp {
  display: flex;
  align-items: center;
}
.clrIdntity {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  margin-left: 10px;
}

/*Agenda view css start*/
.e-schedule .e-agenda-view {
  background-color: transparent;
}

.e-schedule .e-agenda-view .e-resource-column {
  border-bottom: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  color: #343a40;
  vertical-align: top;
  width: 90px;
}
.e-schedule .e-agenda-view .e-content-wrap table td:first-child,
.e-schedule .e-agenda-view .e-date-column {
  vertical-align: top;
  width: 120px;
  border: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #545454;
}
.e-schedule .e-agenda-view .e-resource-name {
  color: #212529;
  font-size: 12px;
  line-height: 26px;
  overflow: hidden;
  padding: 8px;
  text-overflow: ellipsis;
}
.e-schedule .e-agenda-view .e-day-date-header {
  color: #ffffff;
  cursor: default;
  padding: 8px 12px 12px 12px;
}
.schedule-overview.e-schedule .date-text {
  font-size: 12px;
}
.schedule-overview.e-schedule .days-text {
  font-size: 12px;
}
.schedule-overview.e-schedule .e-agenda-view .days-text {
  font-size: 12px;
  color: rgb(255 255 255 / 60%);
}
.e-schedule .e-agenda-view .e-day-border {
  border: 1px solid #545454;
}
.e-schedule .e-agenda-view .e-agenda-parent {
  margin: 0;
  padding: 0;
}
.e-schedule .e-agenda-view .myCalendar {
  background-color: #16a085;
  border-right: 0px solid #0a7963;
  height: 12px;
  width: 12px;
  margin-bottom: 5px;
  border-radius: 12px;
}
.e-schedule .e-agenda-view .gCalendar {
  background-color: #f39c12;
  border-right: 0px solid #f39c12;
  height: 12px;
  width: 12px;
  margin-bottom: 5px;
  border-radius: 12px;
}
.e-schedule .e-agenda-view .e-agenda-item {
  padding: 2px 0;
}
.e-schedule .e-agenda-view .e-appointment {
  border: none !important;
  border-right: 0;
  cursor: default;
  padding: 0 14px;
  background: transparent;
  position: relative;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.e-schedule .e-agenda-view .e-appointment-meeting {
  border: none !important;
  border-left: 8px solid #0d6efd !important;
  border-right: 0;
  cursor: default;
  padding: 0 14px;
  background: transparent;
  position: relative;
  height: auto;
}

.e-schedule .e-agenda-view .e-appointment-tasks {
  border: none !important;
  border-left: 8px solid #8482ff !important;
  border-right: 0;
  cursor: default;
  padding: 0 14px;
  background: #fff;
  position: relative;
  height: auto;
}

.e-schedule .e-agenda-view .e-subject-wrap {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 4px;
}
.e-schedule .e-agenda-view .e-date-time {
  color: rgb(255 255 255 / 60%);
  font-size: 12px;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.e-schedule .e-agenda-view .e-subject {
  color: #ffffff;
  font-size: 14px;
  font-family: Inter;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.e-schedule .e-agenda-view .e-recurrence-icon,
.e-schedule .e-agenda-view .e-recurrence-edit-icon {
  color: #212529;
  line-height: 21px;
  padding: 0 10px;
}

.e-schedule .e-recurrence-icon,
.e-schedule .e-recurrence-edit-icon {
  font-size: 11px;
}
/* agenda view css end */

/*  */
.task-group-day {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.taskDtl-day {
  background-color: #6764f1;
  color: #fff;
  padding: 3px 6px;
  border-radius: 4px;
  margin: 0px 4px;
}
.taskName-day {
  font-size: 13px;
  line-height: 16px;
}
.dateCalender .dateDisplay {
  background-color: transparent;
}
.dateDisplay {
  background-color: #edebff;
  position: relative;
  top: 10px;
  left: 10px;
}
.htmlTooltip {
  padding: 4px;
}
.evTsTooltip {
  margin-inline-start: 10px;
  padding-inline-start: 10px;
}
.joinMtgEvent {
  position: relative;
  top: 5px;
  left: 10px;
  cursor: pointer;
}
/*month view css*/

.e-schedule .e-month-view .e-date-header-wrap {
  font-size: 14px;
  overflow: hidden;
}
.e-schedule .e-month-view .e-date-header-wrap table td:first-child,
.e-schedule .e-month-view .e-content-wrap table td:first-child {
  border-left-width: 1px;
}

.e-schedule .e-month-view .e-date-header-wrap table td,
.e-schedule .e-month-agenda-view .e-date-header-wrap table td {
  background-color: #000000;
  border-color: #545454;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  color: #ffffff;
  font-size: none;
  text-align: right;
  text-transform: none;
}
.e-schedule .e-month-view .e-resource-cells {
  color: #212529;
  font-size: 14px;
  height: 40px;
  padding: 8px;
  padding-left: 15px;
}

.monthCalHeight {
  height: calc(100vh - 250px);
}
.e-schedule .e-month-view .e-header-cells {
  cursor: default;
  font-weight: normal;
  height: 46px;
  padding: 8px;
  text-transform: uppercase;
}
.e-schedule .e-month-view .e-content-wrap {
  font-size: 14px;
}
.e-schedule .e-month-view .e-content-table {
  height: 100%;
}
.e-schedule .e-month-view .e-other-month,
.e-schedule .e-month-agenda-view .e-other-month {
  color: #7d7d7d !important;
}
.dayViewContainer .e-header-cells.e-current-day {
  border-bottom: 1px solid #545454 !important;
}
.dayViewContainer .e-schedule .e-vertical-view .e-work-cells {
  border-width: 0 1px 1px 1px;
}
.dayViewContainer .todayDD {
  background-color: #ff9900;
  padding: 2px 2px;
  border-radius: 34px;
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.agenDate {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.dayName {
  font-size: 12px;
  color: rgb(255 255 255 / 60%);
}
.e-day-date-Group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  gap: 4px;
}
.agenDT {
  font-size: 20px;
}
.agenDT.todayDD {
  background-color: #ff9900;
  padding: 0px 0px;
  border-radius: 34px;
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.agendaViewContainer .AgendaviewHeight {
  height: calc(100vh - 200px);
  overflow-y: auto;
}
.e-schedule .e-month-view .e-work-cells,
.e-schedule .e-month-agenda-view .e-work-cells {
  background-color: #1c1c1c;
  border-color: #545454;
  border-style: solid;
  border-width: 0 1px 1px 1px;
  color: #212529;
  padding: 0;
}
.e-schedule .e-month-view .currentDate {
  height: 40px !important;
  width: 40px !important;
  background-color: #fff !important;
  color: #000000 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 24px;
  font-weight: 600 !important;
}
.e-schedule .e-month-view .e-work-cells {
  height: 80px;
  vertical-align: top;
  width: 36px;
}
.e-schedule .e-month-view .e-work-days,
.e-schedule .e-month-agenda-view .e-work-days {
  background-color: #1c1c1c;
}
.e-schedule .e-month-view .e-date-header {
  cursor: pointer;
  display: table;
  height: 20px;
  line-height: 24px;
  margin: 3px 8px 2px;
  float: right;
  font-size: 18px;
  color: #fff;
}
.monthViewContainer .e-appointment-wrapper {
  height: 100%;
  width: 100%;
}
.e-appointment-event {
  width: 49%;
  height: 50px;
  left: 0%;
  background: rgb(100, 162, 255);
  border: 1px solid rgb(222, 226, 230);
  border-radius: 2px;
  color: rgb(255, 255, 255);
  overflow: hidden;
  position: absolute;
  min-height: 42px;
}
.e-schedule .e-month-view .e-other-month .e-date-header {
  color: rgb(255 255 255 / 40%);
  font-size: 14px;
}
.e-appointment-task {
  width: 49%;
  height: 50px;
  left: 51%;
  background: #aeadff !important;
  border: 1px solid rgb(222, 226, 230);
  border-radius: 2px;
  color: rgb(255, 255, 255);
  overflow: hidden;
  position: absolute;
  min-height: 40px;
}
.listPrivatesNotes {
  max-height: 230px;
  overflow-y: scroll !important;
  padding-right: 10px;
}

.listPrivatesNotes::-webkit-scrollbar {
  width: 8px;
}

.listPrivatesNotes::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(161, 161, 161, 0.3);
  border-radius: 0px;
  background-color: #f8f8f8;
}

.listPrivatesNotes::-webkit-scrollbar-thumb {
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 2px rgba(105, 105, 105, 0.87);
  background-color: #ececec;
}

.listPrivatesNotes ul li {
  border-bottom: 1px dashed #dfdfdf;
  padding-bottom: 5px;
  margin-bottom: 5px;
}
.listPrivatesNotes ul {
  padding-inline-start: 10px;
  margin-inline-start: 10px;
}

.liGrp {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.pNotesText {
  font-size: 12px;
}
.pNotesDeleteIco svg {
  cursor: pointer;
  color: #403dcf;
}

.meetingHead {
  font-size: 14px;
  font-weight: 600 !important;
  color: #000000;
}

.fildHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600 !important;
  font-size: 13px;
  border-bottom: 1px solid #c8d0ff;
  color: #8f8f8f;
}
.itmeListDtl {
  margin-bottom: 20px;
}
.itmeListDtl ol li {
  border-bottom: 1px solid #efe7f5;
  padding-bottom: 1px;
  margin-bottom: 6px;
}
.itListDtlInfo {
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: space-between;
}
.itmDtlListGrp {
  display: flex;
  width: 90%;
}
.mtb1020 {
  margin: 10px 0 20px;
}
.listItmText {
  width: 100%;
}
.rearrangeIco {
  position: absolute;
  left: -45px;
  top: -4px;
}
.listItmIco {
  margin: 0 5px;
  cursor: pointer;
}

.listItmIco svg {
  font-size: 12px !important;
}
.editIco {
  color: #9aa9ff;
}
.deleteIco {
  color: #9aa9ff;
  transition: all 0.6s;
}
.deleteIco:hover {
  color: #ed3237;
}
.attachIco {
  color: #9aa9ff;
}
.pollingIco,
.addIco {
  color: #9aa9ff;
}
.downIco,
.upIco {
  color: #a0a0a0;
  height: 16px;
  width: 22px;
}
.listItemControlGroup {
  display: flex;
}
.agmMeetingFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
  padding-top: 15px;
  border-top: 1px solid #c8d0ff;
}
.agmMeetingFooter .inputGrid {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mr10px {
  margin-right: 10px !important;
}
.submitGrid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  padding-top: 15px;
  border-top: 1px solid #c8d0ff;
}
.viewBtn {
  color: #898989 !important;
  border: 1px solid #dddddd !important;
  min-width: 90px !important;
}
.saveBtn {
  border: 1px solid #8d89ff !important;
  background-color: #6560ff !important;
  color: #fff !important;
  min-width: 90px !important;
}
.editEventPopover {
  padding: 8px 10px;
  /* background-color: #ffffff;
    border-radius: 4px; */
}
.editEventPopover ul {
  margin-inline-start: 0px;
  padding-inline-start: 0px;
  list-style-type: none;
}
.editEventPopoverContain button {
  width: 100%;
  color: rgb(78, 78, 78) !important;
  align-items: center !important;
  justify-content: flex-end !important;
}
.addMeetingUser {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.metUserNm {
  margin-left: 10px !important;
  font-size: 12px !important;
}
.asnUserHead {
  margin-left: 10px !important;
  font-size: 12px !important;
  color: rgb(161, 161, 161);
}

.meetingFreeText {
  border: 1px solid rgb(194, 194, 194);
  border-radius: 4px;
  width: 100%;
  font-size: 12px;
  margin-top: 5px;
}
.center-alignPolling {
  display: flex;
  align-items: center;
}
.center-alignPolling input[type="radio"] {
  visibility: hidden;
  height: 0;
  width: 0;
  position: absolute;
  left: -2900px;
}

.center-alignPolling label {
  display: block;
  /* vertical-align: middle;
    text-align: center;
    cursor: pointer;
    background-color: #454545;
    color: white;
    padding: 5px 10px;
    border-radius: 3px; */
  transition: all 0.8s ease-out;
}
.center-alignPolling input[type="radio"]:checked + label .pollingIco {
  color: #2620f6;
}
.center-alignPolling input[type="radio"]:checked + label .pollingIco svg {
  font-size: 14px !important;
}
.meetingHeadPriview {
  font-size: 16px;
  font-weight: 600 !important;
  border-bottom: 1px solid #000000;
}

.privmtdtl {
  font-size: 13px;
  color: #414141;
}
.privmtdtl strong {
  font-weight: 600 !important;
  font-size: 13px;
}
.agendaPriview {
  margin: 10px 0px 20px;
}
.agendaPriHeading {
  font-size: 14px;
  font-weight: 600 !important;
  color: #000;
  border-bottom: 1px solid #c8d0ff;
}
.agendaListTitle {
  display: block;
  font-size: 12px;
  color: #000;
}
.agendaListDesc {
  display: block;
  font-size: 12px;
  line-height: 15px;
  color: #5c5c5c;
  margin-bottom: 6px;
}
.agendaListAttachment {
  display: flex;
  align-items: center;
}
.agendaListAttachment a {
  border: 1px solid #c8d0ff;
  padding: 2px;
  border-radius: 22px;
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  color: #424242;
  justify-content: center;
  margin: 0 5px 0 0;
  cursor: pointer;
}
.agendaListAttachment a svg {
  font-size: 10px;
}
.meetingCordinator {
  border-top: 1px solid #c8d0ff;
  padding: 10px 0px 0px;
}
.meetingCordinator table {
  border-collapse: collapse;
  width: 100%;
  height: 100px;
  border-width: 1px;
  border-style: hidden;
  margin-left: 0px;
  margin-right: auto;
}
.meetingCordinator table tbody tr {
  height: 21px;
  display: flex;
}
.meetingCordinator table tbody tr.mtingAttendies {
  display: flex;
}
.meetingCordinator table tbody tr td {
  border: hidden;
  height: 21px;
  font-size: 13px;
}

.meetingCordinator table tbody tr td span {
  font-size: 13px;
}
.meetingCordinator table tbody tr td strong {
  font-weight: 500 !important;
}
.assignUserGroupExtInt {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.addExternalUser,
.formElementsIconUserADD {
  /* box-shadow: 3px 4px 14px -3px #7a89b3, -0.2rem -0.2rem 0.5rem #ffffff !important;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      font-size: 7px !important;
      cursor: pointer !important;
      color: #676e7f !important;
      transition: all 0.5s ease !important;
      background: #cfd8e5 !important;
      border: 1px solid #b8c3d3 !important; */
  box-shadow: 3px 4px 14px -3px #a1a9bf, -0.2rem -0.2rem 0.5rem #ffffff !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 7px !important;
  cursor: pointer !important;
  color: #304a6f !important;
  transition: all 0.5s ease !important;
  background: #ccd5e1 !important;
  border: 1px solid #53719b !important;
  height: 36px !important;
  /* width: -moz-fit-content; */
  width: auto;
  border-radius: 20px !important;
  padding: 2px 20px !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  transition: all 0.8s;
}
.addExternalUser:hover,
.formElementsIconUserADD:hover {
  box-shadow: inset 4px 4px 5px -3px #909cbb, inset -3px -4px 6px #ffffff !important;
}
.formElementsIconUserADD span {
  font-size: 12px !important;
  margin-left: 10px;
}
.formElementsIconSelectedEvent {
  /* width: fit-content;
    min-width: 42px !important;
    height: 36px;
    padding: 10px !important;
    border-radius: 46px !important;
    box-shadow: 2px 2px 6px -3px #c8d0e7, -0.2rem -0.2rem 0.5rem #ffffff !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 7px !important;
    cursor: pointer !important;
    color: #9baacf !important;
    transition: all 0.5s ease !important;
    background: #edecff !important;
    border: 1px solid #8683ff !important; */
  /* width: -moz-fit-content; */
  width: auto;
  min-width: 42px !important;
  height: 36px;
  padding: 10px !important;
  border-radius: 46px !important;
  box-shadow: 3px 4px 14px -3px #4b9ed1, -0.2rem -0.2rem 0.5rem #ffffff !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 7px !important;
  cursor: pointer !important;
  color: #ffffff !important;
  transition: all 0.5s ease !important;
  background: #4b9ed1 !important;
  border: 1px solid #4b9ed1 !important;
}
.formElementsIconUserADD.formElementsIconSelectedEvent:hover {
  box-shadow: inset 4px 4px 5px -3px #114f75, inset -3px -4px 6px #a8ddfd !important;
}
/* .formElementsIconSelectedEvent:first-child { */
/* margin-left: 110px !important; */
/* } */
.formElementsIconSelectedEvent svg {
  font-size: 16px;
  color: #ffffff;
}
.formElementsIconSelectedEvent span {
  color: #ffffff;
}
.formElementsIconError svg {
  font-size: 22px;
  color: #ff6404;
} /* add by suranjan  */

.mvEvents {
  top: -72px !important;
}
.outSideUserList {
  list-style: auto;
}
.outSideUserList li {
  font-size: 13px;
}
.outSideUserListHeading {
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500 !important;
}
.selected {
  color: blue;
}

.googleCalendar {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px !important;
  padding-bottom: 12px !important;
  cursor: pointer;
  /* max-width: 145px !important; */
}
.votingRedioGroup {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap !important;
  flex-direction: row !important;
}
.votingRedioLabel {
  margin-left: 4px !important;
  margin-right: 4px !important;
}
.attachIcoSelected {
  color: #2620f6 !important ;
}

.votingRedioLabel .MuiButtonBase-root.MuiRadio-root {
  padding: 1px !important;
}

.e-schedule .e-schedule-toolbar .e-toolbar-items.e-tbar-pos {
  height: 38px;
  min-height: 38px;
}
.e-toolbar .e-toolbar-items.e-tbar-pos {
  display: block;
}

.e-schedule .e-schedule-toolbar {
  background: #f8f9fa;
  border: 0;
  border-bottom: 0;
  box-shadow: 0 1px 2px rgb(0 0 0 / 15%);
  margin-bottom: 3px;
  min-height: 38px;
}

.e-schedule .e-schedule-toolbar .e-toolbar-items.e-tbar-pos > div {
  height: inherit;
}
.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-right {
  right: 0;
}
.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-right,
.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-left {
  position: absolute;
}

.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-left,
.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-center,
.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-right {
  display: table;
  height: 100%;
  top: 0;
}

.block-events.e-schedule .e-timeline-view .e-resource-left-td,
.block-events.e-schedule .e-timeline-month-view .e-resource-left-td {
  width: 170px;
}
.e-schedule .e-timeline-view .e-resource-left-td,
.e-schedule .e-timeline-month-view .e-resource-left-td {
  background-color: #fff;
  border-color: #dee2e6;
  border-style: solid;
  border-width: 0 1px 1px 0;
  color: #343a40;
  vertical-align: middle;
  width: 200px;
}

.e-schedule .e-timeline-view .e-resource-text,
.e-schedule .e-timeline-month-view .e-resource-text {
  color: #212529;
  font-size: 13px;
  overflow: hidden;
  padding: 4px 12px;
  text-overflow: ellipsis;
}
.e-schedule .e-timeline-view .e-date-header-wrap,
.e-schedule .e-timeline-month-view .e-date-header-wrap {
  font-size: 14px;
  overflow: hidden;
  position: relative;
}

.e-schedule .e-timeline-view .e-date-header-wrap table td:first-child,
.e-schedule .e-timeline-view .e-content-wrap table td:first-child,
.e-schedule .e-timeline-month-view .e-date-header-wrap table td:first-child,
.e-schedule .e-timeline-month-view .e-content-wrap table td:first-child {
  border-left-width: 0;
}

.e-schedule .e-timeline-view .e-date-header-wrap table tbody td,
.e-schedule .e-timeline-month-view .e-date-header-wrap table tbody td {
  height: 36px;
  overflow: hidden;
  padding: 8px 0 8px 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.e-schedule .e-timeline-view .e-date-header-wrap table td,
.e-schedule .e-timeline-month-view .e-date-header-wrap table td {
  background-color: #fff;
  border-color: #dee2e6;
  border-style: solid;
  border-width: 0 0 1px 1px;
  color: #343a40;
}

.e-schedule .e-timeline-view .e-date-header-wrap table tbody td > span,
.e-schedule .e-timeline-month-view .e-date-header-wrap table tbody td > span {
  position: relative;
}
.e-schedule .e-timeline-view .e-navigate {
  font-size: 14px;
}

.multi-select-checkbox {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 8px;
  /* max-width: 300px; */
  box-shadow: 0 1px 3px #545454, 0 1px 2px rgba(0, 0, 0, 0.24);
}

.checkbox-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0.5rem;
  user-select: none;
  padding: 0.25rem;
  border-radius: 4px;
}

.checkbox-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.checkbox-item input {
  margin-right: 0.5rem;
}

.multi-select-checkbox label {
  cursor: pointer !important;
}
.multi-select-checkbox input {
  cursor: pointer !important;
}

.fildSubText {
  font-size: 11px;
  line-height: 14px;
  color: #5c5c5c;
  padding: 10px;
  max-width: 400px;
}
[data-testid="view-navigator"]:nth-child(1) {
  display: none;
}

.testSchduler,
.testSchduler tr,
.testSchduler td {
  border: 1px solid black;
  border-collapse: collapse;
}

.testSchduler {
  border: 1px solid black;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.testSchduler .selected {
  background-color: #a0a0a0;
}

.e-schedule .e-vertical-view .e-work-cells.selected {
  background-color: #cedeeb;
}

/* AUDIO RECORDER CSS */
#audioAnalyser .iconfont {
  font-size: 30px;
  font-weight: 700;
  cursor: pointer;
}

#audioAnalyser .btn-box {
  display: flex;
  justify-content: center;
  margin: 20px;
}

#audioAnalyser .btn {
  margin: 0 10px;
  padding: 8px 16px;
  color: white;
  background-color: #272727;
  border: none;
  border-radius: 1px;
}
/* AUDIO RECORDER CSS */
.evTsTooltipPopup {
  border-right: 1px solid black;
  padding: 10px;
}
.evTsTooltipPopup li {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;
  /* border-bottom: 1px solid black; */
  padding-left: 10px !important;
  line-height: 25px;
}
.eventDtlsMainDiv {
  display: flex;
  max-width: 550px !important;
}
.e-appointment {
  cursor: pointer !important;
}
.evntDtslLabel {
  padding-left: 10px !important;
}
.eventDtlsHeaderSch {
  display: flex;
  justify-content: space-between;
  position: relative;
  top: 4px;
  right: 4px;
}
.vcSchedulerEvntDtls {
  cursor: pointer !important;
}
.evntDtslLabelCross {
  cursor: pointer !important;
}

.coustomPopover .MuiPopover-paper {
  border-radius: 12px;
  box-shadow: 0px 5px 34px -5px #232b32a3;
}
.coustomPopover .editEventPopoverContain {
  padding: 12px;
  background-color: #efefef;
}

.coustomPopover .eventDtlsMainDiv {
  flex-direction: column;
}
.coustomPopover .evTsTooltipPopup {
  /* border-right: 1px solid black; */
  border: none;
  padding: 10px;
}

.coustomPopover .popOverActionList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.coustomPopover .popOverActionList li {
  padding: 6px 6px;
  min-width: calc(100% / 3);
}

.coustomPopover .popoverBtn {
  background-color: #efefef !important;
  color: #272727 !important;
  box-shadow: -2px -2px 6px rgba(255, 255, 255, 0.6),
    -2px -2px 4px rgba(255, 255, 255, 0.4),
    2px 2px 2px rgba(255, 255, 255, 0.05), 2px 2px 4px rgba(0, 0, 0, 0.1) !important;
  padding: 6px 16px !important;
  /* margin: 6px 4px; */
  transition: 0.2s ease-in-out;
  justify-content: center !important;
}
.coustomPopover .popoverBtn:hover {
  box-shadow: -6px -6px 14px rgba(255, 255, 255, 0.7),
    -6px -6px 10px rgba(255, 255, 255, 0.5),
    6px 6px 8px rgba(255, 255, 255, 0.075), 6px 6px 10px rgba(0, 0, 0, 0.15) !important;
}
.coustomPopover .popoverBtn:active {
  box-shadow: inset -2px -2px 6px rgba(255, 255, 255, 0.7),
    inset -2px -2px 4px rgba(255, 255, 255, 0.5),
    inset 2px 2px 2px rgba(255, 255, 255, 0.075),
    inset 2px 2px 4px rgba(0, 0, 0, 0.15) !important;
}

.coustomPopover .popHtext {
  color: #818181;
}
.coustomPopover .popDtext {
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.coustomPopover .popJoinVideo {
  height: 20px;
  margin-left: 10px;
}
.popoverBtn svg {
  font-size: 12px !important;
}

.coustomSvhedulerCONVO .overview-content .left-panel {
  height: 70vh;
  width: 100%;
}

.containertest {
  position: relative;
}

.click-point {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: black;
}

.click-point {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  border: 2px dotted black;
  display: block;
}
.line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.cell_Date_Days {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding-left: 10px;
}
.cell_Date {
  font-size: 22px;
  line-height: 22px;
  font-family: Inter;
  color: #fff;
  /* height: 34px;
  width: 34px; */
  margin-bottom: 4px;
}
.cell_Days {
  font-size: 14px;
  line-height: 14px;
  font-family: inter;
  color: #fff;
}

.todayDD {
  background-color: #ff9900;
  padding: 2px 2px;
  border-radius: 34px;
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Convenient_Time_View_Container .template-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.resource-image {
  height: 40px;
  width: 40px;
  border-radius: 30px;
  border: 2px solid #fff;
}
.resource-image img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: contain;
}

.convenientDateTimeFrmGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.convoHeadTxt {
  font-size: 18px;
  color: #ffffff;
  font-family: Inter;
  line-height: 24px;
  text-transform: capitalize;
}

.convoLeftRightCalHeight {
  height: calc(100vh - 310px);
  overflow-y: auto;
}
.resource-name {
  font-size: 16px;
}
.draggbleTimeSpanBorder {
  border: 2px dashed #f39c12;
  border-radius: 4px;
  background-color: transparent;
  width: 100% !important;
  cursor: grab !important;
  left: 0%;
}
.draggbleTimeSpanBorder:focus {
  cursor: grabbing !important;
}
.Convenient_Time_View_Container
  .e-time-cells-wrap
  table.e-schedule-table
  td.e-time-slots {
  border-right: 1px solid #545454;
}

.Convenient_Time_View_Container
  .e-schedule
  .e-vertical-view
  .e-work-cells:last-child {
  border-right-width: 1px;
}
.control-panel {
  color: white !important;
}

.SchduleRightFlotingContainerSettings {
  height: calc(100vh - 80px);
  width: 30vw;
  position: absolute;
  bottom: 0px;
  right: -40vw;
  background-color: #000000;
  transition: all 0.6s;
  animation: schFloting-appear 1s ease forwards;
  overflow: hidden;
  z-index: 999;
}
.right-panel {
  margin-top: 20px;
  margin-left: 30px;
}
.col-right .MuiFormControl-root {
  min-width: 300px;
  width: 300px !important;
}

.schedularSettingsInfo .MuiBadge-colorSecondary {
  background-color: #ff9900 !important;
}

.markAttendanceIcon {
  color: white !important;
  left: 40% !important;
  width: 20px !important;
  padding: 2px !important;
}
