@media screen and (max-width: 1440px) and (min-width: 1024px) {
  .navbar-links .lpItems {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    font-size: 18px;
    text-transform: UPPERCASE;
  }
  .lpItems.centerLogo a img {
    height: 60px;
    width: 60px;
  }

  .showFuntionSec {
    height: 100vh;
    background-color: #1c1c1c;
    padding: 50px 40px 50px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
  }

  .showFuntionContainer .device-mockup.imac {
    padding-bottom: 47vh !important;
  }
  .funtionDescribeText .fdtContainer {
    width: 33vw;
    height: 100%;
    margin-top: 95%;
    /* display: flex; */
    /* align-items: center; */
    /* flex-direction: column; */
  }
  .appDownloadSec {
    height: 100vh;
    background-color: #1c1c1c;
    padding: 50px 100px 50px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  .appDownloadInfo .adnTitle {
    font-size: 30px;
    font-family: Inter;
    color: #fff;
    line-height: 36px;
  }
  .adnLinkGrp a .appStore {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    padding: 10px;
    border: 2px solid #fff;
    border-radius: 12px;
    width: 190px;
  }
  .adnLinkGrp .appstoreIcon svg {
    width: auto;
    height: 32px;
  }
  .adnLinkGrp .appstoreText .headTxt {
    font-size: 24px;
    font-family: inter;
    line-height: 24px;
  }
  .appDownloadInfo .adnInfo {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 50px;
  }
  .latestNewsSecContainer .lnSliderContainer {
    margin-top: 50px;
  }
  .clientSec {
    height: auto !important;
    background-color: #1c1c1c;
    padding: 100px 100px 50px !important;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
  }
  .ourClientContainer .cleintsDtlsWrap {
    height: auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 40px;
    flex-direction: column;
  }
  .cleintsDtlsWrap .clientInfo {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 50px;
    flex-direction: row;
    color: #fff;
  }
  .hexagon-gallery .hex {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 7px;
    width: 132px;
    height: 145px;
    background-color: #ffffff;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  }
  .clientsLogos .hexagon-gallery {
    margin: 0 auto;
    /* margin-top: 50px; */
    /* width: 100%; */
    display: grid;
    grid-template-columns: repeat(13, 0fr);
    grid-auto-rows: 107px;
    grid-gap: 8px;
    padding-bottom: 50px;
  }
  .hex .cleintLogo {
    height: 80px;
    width: 80px;
  }
  .zoyelFooterContainer {
    padding: 100px 100px 50px !important;
    background: rgb(48, 48, 48);
    background: linear-gradient(
      180deg,
      rgba(48, 48, 48, 1) 0%,
      rgba(0, 0, 0, 1) 100%
    );
    border-top: 1px solid #707070;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px !important;
  }
  .zoyelFooterContainer .zoyelFootElementContainer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 20px !important;
  }
  .latestNewsSec {
    height: 100vh;
    background-color: #1c1c1c;
    padding: 100px 100px 50px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  .zoyelFootElementContainer .footerElement {
    width: calc(100% / 4);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
  }

  section.pageNotFoundContainer {
    height: calc(100vh - 150px) !important;
    background-color: #1c1c1c !important;
    padding: 100px 140px 50px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    gap: 10px;
  }
  .pageNotFoundContainer .adnText {
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    font-family: Inter;
    width: 100%;
  }
}
