.flotingProfilecontainer {
  height: calc(100vh - 80px);
  width: 42vw;
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-color: #000000;
  transition: all 0.6s;
  animation: profileFloting 1s ease forwards;
  overflow: hidden;
  z-index: 999;
}
.promptMsg {
  color: #545454;
  font-size: 14px;
  font-family: "Inter";
  text-transform: capitalize;
  font-style: italic;
  font-weight: 600;
}
@keyframes profileFloting {
  from {
    /* right: -40vw; */
    opacity: 0;
    width: 0vw;
  }
  to {
    /* right: 0; */
    opacity: 1;
    width: 42vw;
  }
}
.profileheader {
  order: 1;
  display: flex;
  width: auto;
  padding: 16px 24px 16px 30px;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  background-color: #000;
  border-bottom: 1px solid #545454;
}
.profileDtlContainer {
  order: 2;
  flex: 1 1;
  padding: 15px 30px 10px;
  height: calc(100vh - 235px);
}
.profileFooter {
  order: 3;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 24px 10px 30px;
  border-top: 1px solid #545454;
}
.profileAreaContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #000000;
}
.proheadNm {
  color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
}
.profileDtlContainerArea::-webkit-scrollbar {
  width: 0px !important;
  height: 6px !important;
}

.profileDtlContainerArea::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 1);
}
.profileDtlContainerArea::-webkit-scrollbar-track {
  background: rgb(18 18 18 / 70%);
}

.profileDtlContainerArea {
  overflow: hidden;
  overflow-y: overlay;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 30px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.proUserInfo {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 19px;
  width: 100%;
}
.proUserDtlStat {
  color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
  gap: 10px;
}
.proUser_status {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}
.proImgwthControl {
  position: relative;
}
.proUserImg {
  height: 150px;
  width: 150px;
  border-radius: 80px;
}
.proUserImg img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: contain;
}
.changeUserImg {
  height: 70px;
  width: 150px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 75px;
  border-bottom-right-radius: 75px;
  background: #16161638;
  backdrop-filter: blur(1px);
}
.changeUserImg input[type="file"].inputChangeImg {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 150px;
  height: 75px;
  border-bottom-left-radius: 75px;
  border-bottom-right-radius: 75px;
  cursor: pointer;
}
.changeUserImg svg {
  color: #fff;
  height: 32px;
  width: 32px;
}
/* RadialProgressBar.css start */
.radial-progress {
  transform: rotate(-90deg);
}

.radial-progress-bg {
  fill: none;
  stroke: #2b2b2b;
  stroke-width: 4;
}

.radial-progress-bar {
  fill: none;
  stroke: #3498db;
  stroke-width: 4;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s;
}
.radial-progress text {
  fill: #e0e0e0;
  transform: rotateZ(90deg) translateX(0px) translateY(-50px);
  font-size: 12px;
}

/* RadialProgressBar.css end */

.proUserName {
  font-family: Inter;
  font-size: 22px;
  line-height: 28px;
  color: #fff;
}
.ProUsrDesignation {
  font-family: Inter;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
}
.proUserSkills {
  font-size: 14px;
  font-family: Inter;
  color: #fff;
}
.proUserRegFace {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.proUserRegFace .MuiButtonBase-root.MuiIconButton-root {
  color: #fff;
}

.proUserRegFace .MuiButtonBase-root.MuiIconButton-root svg {
  height: 20px;
  width: 20px;
}

.profileFromContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 14px;
  width: 100%;
}

.profileFromContainer .formElement {
  width: 100%;
  max-width: 260px;
  min-width: 260px;
}
.profileFromContainer .formElement.fullwidth {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}

.profileFromContainer
  .formElement
  .formControl
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-shrink {
  background-color: transparent;
  color: #707070;
  transform: translate(14px, -8px) scale(0.75);
  line-height: 16px;
  color: #fff;
}
.profileFromContainer .formElement .formDatePicker:disabled,
.profileFromContainer
  .formElement
  .formControl
  .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: rgb(116 116 116);
  background: #1a1a1a !important;
  /* border: 1px solid #707070; */
  cursor: no-drop;
}

.profileFromContainer
  .formElement
  .formControl
  .MuiFormLabel-root.MuiInputLabel-root.setCompletionDate {
  font-size: 14px;
  line-height: 14px;
  font-family: Inter;
  color: rgb(255 255 255);
  transform: translate(11px, -6px) scale(0.75);
  background-color: transparent;
  padding: 0px 6px;
}

.profileFromContainer
  .formElement
  .formAutoComInputField
  .MuiButtonBase-root.MuiChip-root {
  background-color: #707070;
  color: #fff;
  font-family: "Inter";
  cursor: pointer;
  height: 26px;
}

.profileFromContainer
  .formElement
  .formAutoComInputField
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiChip-deleteIcon {
  -webkit-tap-highlight-color: transparent;
  color: rgb(251 223 223 / 98%);
  font-size: 16px;
  cursor: pointer;
  margin: 0 5px 0 -6px;
}

.formControl .MuiAutocomplete-root.formAutocompleteField {
  color: #ffffff !important;
  background-color: #333333 !important;
  border-radius: 4px;
}
.profileFromContainer .profileFormElementGroup {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 14px;
  width: 100%;
}

.profileFromContainer
  .react-datepicker__input-container.react-datepicker__view-calendar-icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row-reverse;
}

.profileFromContainer .formElement .formDatePicker {
  padding: 8px 42px 8px 12px;
}

.profileFromContainer .resetPasswordBtn .taskVDContBtn .MuiButton-outlined {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  background: transparent;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #707070;
  display: flex;
  align-items: center;
  color: #707070;
  max-height: 40px;
}

.profileFromContainer .resetPasswordBtn .taskVDContBtn .MuiButton-contained {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  background: #4c4c4c;
  padding: 6px 10px;
  border-radius: 4px;
  border: 1px solid #4c4c4c;
  display: flex;
  align-items: center;
  color: #a2a2a2;
  max-height: 40px;
}
.profileFromContainer
  .resetPasswordBtn
  .taskVDContBtn
  .MuiButton-contained:hover {
  color: #ffffff;
}
.profileFromContainer .resetPasswordBtn .taskVDContBtn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 14px;
}
.MuiInputBase-root.MuiOutlinedInput-root.formTextFieldArea.areaPassword {
  background-color: #333333;
}

.profileFromContainer .areaPassword svg.MuiSvgIcon-root {
  color: #707070;
}
.resetPasswordChange {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 14px;
  flex-direction: column;
}

.resetPasswordContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 14px;
}

.resetPassPolicy {
}

.passpollicyHead {
  font-size: 16px;
  font-family: inter;
  color: #fff;
}

.passPolicyList ul {
  padding-inline-start: 0px;
  color: #707070;
  list-style-type: none;
  font-size: 12px;
}
.policyInd {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.passMatchIcon svg {
  color: #19b500;
  font-size: 16px;
}
.passMissMatchIcon svg {
  color: #ff0000;
  font-size: 16px;
}
ul.preBuildImgList {
  list-style-type: none;
  padding-inline-start: 0;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
ul.preBuildImgList li {
  height: 90px;
  width: 90px;
  border-radius: 45px;
  border: 2px solid #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
ul.preBuildImgList li img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: contain;
  border: 2px solid #fff;
}

ul.preBuildImgList li.imgSelected {
  border: 4px solid #00adef;
}
.fromWithEdtUpldBtn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: calc(100% - 40px);
}

.fromWithEdtUpldBtn .elemntIconBtn.MuiButtonBase-root.MuiIconButton-root {
  height: 28px;
  width: 28px;
  color: #e0e0e0;
  padding: 4px;
}

.proUserNmDeg {
  width: 100%;
}

.profUpProImg {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.profUpProImg .coustFileInputBtn {
  width: 150px;
  color: #fff;
  padding: 10px;
  border: 1px solid #545454;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  gap: 10px;
}
.proImgPicker {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
}
.noSignAvl {
  width: 220px;
  max-width: 260px;
  height: auto;
  border-radius: 4px;
  border: 1px solid #545454;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  padding: 20px;
}
.noSignAvlTxt {
  font-size: 18px;
  font-family: Inter;
  color: #707070;
  font-weight: 600;
}
.btnTouploadSign {
  font-size: 14px;
  font-family: Inter;
  color: #707070;
  font-style: italic;
}
.signatureUploadArea {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
}
.signAvl {
  width: 220px;
  max-width: 260px;
  height: 128px;
  border-radius: 6px;
  border: 1px solid #545454;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  padding: 20px;
  background-color: #fff;
  /* border: 10px solid #fff;
  background-image: repeating-linear-gradient(
      -58deg,
      #333333,
      #333333 0.43px,
      transparent 1px,
      transparent 1.8599999999999999px,
      #333333 3px
    ),
    repeating-linear-gradient(
      32deg,
      #333333,
      #333333 0.43px,
      transparent 1px,
      transparent 1.8599999999999999px,
      #333333 3px
    ),
    repeating-linear-gradient(
      122deg,
      #333333,
      #333333 0.43px,
      transparent 1px,
      transparent 1.8599999999999999px,
      #333333 3px
    ),
    repeating-linear-gradient(
      212deg,
      #333333,
      #333333 0.43px,
      transparent 1px,
      transparent 1.8599999999999999px,
      #333333 3px
    );
  background-size: 1px calc(100% + 5.66px), calc(100% + 5.66px) 1px,
    1px calc(100% + 5.66px), calc(100% + 5.66px) 1px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  animation: borderAnimation 1s infinite linear; */
}
@keyframes borderAnimation {
  from {
    background-position: 0 0, -5.66px 0, 100% -5.66px, 0 100%;
  }
  to {
    background-position: 0 -5.66px, 0 0, 100% 0, -5.66px 100%;
  }
}
.signAvl img {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  object-fit: contain;
}

.signPreviewWithStamp {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 6px;
  min-width: 240px;
}

.proZoomPicker {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2px;
}

.defaultLandingPage {
  color: white !important;
}
