.emailContainerArea {
  height: 100%;
  width: 100%;
}
.emailContainArea {
  height: 100%;
  width: 100%;
}
.emailContainer {
  background-color: transparent;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  padding: 0 0 0 0px;
  height: calc(100vh - 80px);
}

.leftEmailMenuBar {
  background-color: transparent;
  min-width: 320px;
  overflow: hidden;
  width: auto;
  order: 1;
}
.emailMenuButton {
  display: none;
}
.leftEmailMenu {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  max-width: 320px;
  width: 100%;
  background-color: #121212;
  padding-left: 0px;
}
.rightEmailContain {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  overflow: hidden;
  box-flex: 1;
  flex-grow: 1;
  background-color: transparent;
  order: 2;
  padding-right: 70px;
}

.rightMailTopbar .searchUser {
  display: flex;
  width: -webkit-fill-available;
  padding: 5px 9px;
  align-items: center;
  gap: 20px;
  border-radius: 8px;
  background: #272727;
  max-width: 350px;
}
.rightMailTopbar .searchIcon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rightMailTopbar .searchIcon svg {
  color: #a8a8a8;
}
.rightMailTopbar .serchInput {
  width: 100%;
  flex-shrink: 0;
  flex: 1 1;
  color: #ffffff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  background-color: transparent;
  outline: none;
  box-shadow: none;
  border: none;
}
.menuTypeMail,
.menuComposeMail {
  padding: 10px;
  padding-left: 70px;
}
.menuComposeMail {
  background-color: #1c1c1c;
  height: 36px;
}
.composeBtn {
  border-radius: 12px;
  font-size: 1rem;
  background-color: #516787;
  box-shadow: none;
  padding: 14px 20px;
}
.composeBtn svg {
  font-size: 16px !important;
}

.mailType {
  display: flex;
  font-size: 14px;
  padding: 5px 16px;
  height: 28px;
  align-items: center;
  border-radius: 20px;
  transition: all 0.5s;
  cursor: pointer;
  opacity: 0.7;
  margin-bottom: 1px;
}
.mailtypeIcon {
  margin-right: 8px;
  margin-left: -4px;
  height: 20px;
}
.mailType.active {
  background-color: #373737;
  font-weight: 600;
  color: #fff;
  opacity: 1;
}
.mailType.active .mailtypeName,
.mailType.active svg {
  font-weight: 600 !important;
}
.mailType.active:hover {
  background-color: #606060;
}

.mailType:hover {
  background-color: #606060;
  opacity: 1;
}
.rightMailTopbar {
  padding: 10px;
  height: 36px;
  border-left: 0.5px solid #545454;
  border-bottom: 0.5px solid #545454;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.paginationMail .MuiTablePagination-root {
  color: #ffffff !important;
}
.paginationMail .MuiTablePagination-input .MuiSvgIcon-root {
  color: #fff;
}
.paginationMail .MuiTablePagination-actions .Mui-disabled {
  color: #fff;
  opacity: 0.2;
}
.mailListIndActivate {
  background-color: #000;
}
.mailListInd {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 0.5px solid #545454;
  padding: 14px 4px;
  cursor: pointer;
}
.mailIndIconsGroup {
  display: flex;
  width: 60px;
  flex-basis: 60px;
  max-width: 60px;
  order: 1;
}
.stared .MuiCheckbox-root,
.emailmarker .MuiCheckbox-root,
.mailIndIcons .MuiCheckbox-root {
  color: #ffffff;
}
.stared .MuiCheckbox-root.Mui-checked,
.emailmarker .MuiCheckbox-root.Mui-checked,
.mailIndIcons .MuiCheckbox-root.Mui-checked {
  color: #e59b2e !important;
}
.mailUserName {
  flex-basis: 155px;
  min-width: 155px;
  max-width: 155px;
  order: 2;
  padding: 5px 0px;
}
.userFullName {
  font-size: 14px;
  font-weight: 600 !important;
  opacity: 0.7;
  transition: all 0.6s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mailTextInfo {
  margin-left: 10px;
  display: flex;
  flex: 1 1 auto;
  height: auto;
  min-width: 320px;
  order: 3;
}
.mailTextInfo .mailText {
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-overflow: ellipsis;
  width: 550px;
  flex: 1;
  margin-right: 18px;
  white-space: nowrap;
  overflow: hidden;
  opacity: 0.7;
  transition: all 0.6s;
}
.mailTime {
  letter-spacing: normal;
  flex-basis: 90px;
  width: 90px;
  justify-content: flex-end;
  padding-left: 0;
  padding-right: 1px;
  order: 4;
}
.mailTime .mailInOutTime {
  font-size: 12px;
  font-family: Inter;
  width: 100%;
  opacity: 0.7;
  transition: all 0.6s;
}
.mailTime .mailInOutTime span {
  display: flex;
  width: 95px;
}
.mailDltIcon {
  order: 5;
  opacity: 0.7;
  transition: all 0.6s;
}
.mailDltIcon svg {
  height: 20px;
  width: 20px;
}
.mailListInd:hover .mailTime .mailInOutTime,
.mailListInd:hover .mailTextInfo .mailText,
.mailListInd:hover .userFullName,
.mailListInd:hover .mailDltIcon {
  opacity: 1;
}
.rightMailContainerGroup {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  height: 100%;
  overflow-y: hidden;
}

.mailListGroup {
  /* flex-grow: 70;
  max-height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-width: 360px;
  -webkit-box-flex: 1 0 0%;
  -webkit-flex: 1 0 0%;
  flex: 1 0 0%;
  -webkit-box-flex: 1 0 0%;
  -webkit-flex: 1 0 0%;
  flex: 1 0 0%;
  overflow-y: scroll !important; */

  height: calc(100vh - 137px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 360px;
  flex: 1 0;
  overflow-y: scroll !important;
  border-left: 0.5px solid #545454;
  /* background-color: #d9e0e9; */
}
.rightEmailViewContain {
  order: 2;
  padding-right: 70px;
  width: 100%;
  border-left: 0.5px solid #545454;
  height: calc(100vh - 81px);
  overflow: hidden;
}

.emailViewArea .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  max-height: 100% !important;
  box-shadow: none;
}
.emailViewArea::-webkit-scrollbar-track,
#mailListGroup::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.emailViewArea::-webkit-scrollbar,
#mailListGroup::-webkit-scrollbar {
  width: 0.5px;
  background-color: #333333;
}
.emailViewArea::-webkit-scrollbar-thumb,
#mailListGroup::-webkit-scrollbar-thumb {
  background-color: #a0a0a0;
}

.mailViewCreateGroup {
  max-height: calc(100vh - 165px);
  min-width: 344px;
  position: relative;
  -webkit-box-flex: 1 0 0%;
  -webkit-flex: 1 0 0%;
  flex: 1 0 0%;
  flex-grow: 45;
  overflow-y: overlay;
}

/* .mailViewCreateGroup::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.mailViewCreateGroup::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.mailViewCreateGroup::-webkit-scrollbar-thumb {
  background-color: #a0a0a0;
} */

/* compose mail container start */
.composeMailArea {
  visibility: hidden;
  position: fixed;
  right: 92px;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 9999;
}
.composeMailContainer {
  width: 28vw;
  height: 100vh;
  order: 1;
  z-index: 1000;
  float: right;
}
.composrMailPesition {
  width: 28vw;
  position: relative;
  height: 100vh;
  min-height: 1px;
  float: left;
}
.composeMailStart {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.visible {
  visibility: visible;
  -webkit-border-radius: 8px 8px 0 0;
  border-radius: 8px 8px 0 0;
  -webkit-box-shadow: 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}
.composeMailHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #545454;
  color: #fff;
  padding: 8px 10px;
  -webkit-border-radius: 8px 8px 0 0;
  border-radius: 8px 8px 0 0;
}
.compMailControl {
  display: flex;
  align-items: center;
}
.compMailCtrl {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.composeMailSenderInfo {
  padding: 10px 10px 0px;
}
.composeMailAction {
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.composeMailBody {
  padding: 10px 10px;
}
.composeMailSubject {
  padding: 0px 10px;
}
.compMailSubject {
  margin: 0;
  max-width: 100%;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  padding: 0.2em 0em;
  background: #fff;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0px;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
}
.compMailSubject .compMailSubjectInput {
  flex: 1 1;
  width: auto !important;
  outline: none !important;
  border: 0 none !important;
  display: inline-block !important;
  line-height: 1;
  vertical-align: baseline !important;
  padding: 0.4em 0.1em !important;
}
.compMailSubject .compMailSubjectInput:active .compMailSubject {
  border-bottom: 1px solid rgba(102, 154, 206, 0.459) !important;
}

.compMailSubjectInput::placeholder {
  color: #ccc;
}
.composeMailBody {
  min-height: 200px;
}
.composeMailAction {
  min-height: 30px;
}
.textAreaAutosize {
  width: calc(100% - 8px);
  border: 1px solid rgba(34, 36, 38, 0.15);
  outline: none !important;
  font-family: Inter;
  font-size: 14px;
  padding: 4px;
}
.mlmltThread {
  color: #ffffff;
  opacity: 0.6;
  font-size: 14px;
}
.mlshmlt .MuiIconButton-root svg {
  color: #ffffff;
}
.mlmltThread a {
  color: #ffffff;
  opacity: 0.8;
}
.compMailActGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.sendeCompMailBtn {
  display: flex !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  padding: 6px 20px !important;
  height: 34px !important;
  align-items: center !important;
  transition: all 0.5s !important;
  cursor: pointer !important;
  border-radius: 4px !important;
  background-color: #00adef !important;
  color: #fff !important;
}
.composeMailAttacthedFile {
  cursor: pointer;
  color: #1c1c1c;
}
.composeMailDelete {
  cursor: pointer;
  color: #1c1c1c;
}
/* compose mail container end */
/* coustome multi email  start*/
.react-multi-email {
  margin: 0;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  text-align: left;
  line-height: 1.21428571em;
  padding: 0.2em 0em;
  background: #fff;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0px;
  -webkit-transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
}
.react-multi-email > span[data-placeholder] {
  display: block;
  position: absolute;
  left: 0px;
  font-size: 12px;
  top: 12px;
  padding: 0em;
  line-height: 10px;
  color: #c9c9c9;
}
.react-multi-email.focused {
  border-color: #85b7d9;
  background: #fff;
}
.removeMailId svg {
  font-size: 10px;
}
.react-multi-email.empty > span[data-placeholder] {
  display: inline;
  color: #ccc;
  position: absolute;
  left: 0px;
  top: 0.4em;
  padding: 0.4em 0px;
  line-height: 1.21428571em;
}
.react-multi-email.focused > span[data-placeholder] {
  display: block;
}

.react-multi-email > input {
  flex: 1;
  width: auto !important;
  outline: none !important;
  border: 0 none !important;
  display: inline-block !important;
  line-height: 1;
  vertical-align: baseline !important;
  padding: 0.4em 0.1em !important;
  margin-left: 22px;
}

.react-multi-email [data-tag] {
  vertical-align: baseline;
  margin: 0.1em;
  background-color: #f3f3f3;
  background-image: none;
  padding: 2px 6px;
  color: rgba(0, 0, 0, 0.6);
  text-transform: none;
  font-weight: 600;
  border: 0 solid transparent;
  border-radius: 0.2rem;
  -webkit-transition: background 0.1s ease;
  -o-transition: background 0.1s ease;
  transition: background 0.1s ease;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  color: #000;
}

.react-multi-email [data-tag] [data-tag-item] {
  max-width: 100%;
  overflow: hidden;
}
.react-multi-email [data-tag]:first-child {
  margin-left: 22px;
}
.react-multi-email [data-tag] [data-tag-handle] {
  margin-left: 0.3em;
  cursor: pointer;
}

.removeMailId svg {
  font-size: 12px;
}
.mailIndIcons .selectMailCheck,
.mailIndIcons .starCheck,
.mailIndIcons .bookmarkcheck {
  padding: 5px !important;
}
/* coustome multi email  end*/

/* email view container start */
.emailViewContainer {
  padding: 0 0 0 16px;
}
.viewEmailHeader {
  -webkit-align-items: flex-start;
  align-items: flex-start;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  padding: 16px 0 8px 8px;
  border-bottom: 0.5px solid #161616;
}
.emailTitle {
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-flex;
}
.emailSubject {
  font-size: 22px;
  font-weight: 500 !important;
  margin-left: 24px;
}
.emailAction {
  display: flex;
  align-items: center;
  font-size: 0.575rem;
  margin-left: auto;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 3;
  order: 3;
}
.actionBack .MuiIconButton-root,
.actionForward .MuiIconButton-root,
.actionPrint .MuiIconButton-root {
  color: #ffffff;
  height: 30px;
  width: 30px;
}
.mlBsInfoArea {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  padding: 0;
  width: 100%;
}
.mlbiImg {
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  float: none;
  height: 50px;
  padding: 0px 16px 0px 0px;
  min-width: 40px;
}
.mlbiSenderInfo {
  min-width: 0;
  width: 100%;
  margin: 0;
  padding: 4px 0 10px 0;
}
.showMlDetlCntnr {
  margin-bottom: 0px !important;
  background-color: transparent !important;
  border-bottom: 1px solid #545454;
  border-radius: 0px !important;
  box-shadow: none;
}
.emailViewArea {
  max-height: calc(100vh - 145px);
  overflow: hidden;
  overflow-y: overlay;
}
.showMlSummry {
  padding: 0px;
  min-height: 50px !important;
}
.mlbiimgPosition {
  position: relative;
}
.senderImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
}
.sndml {
  text-align: left;
  white-space: nowrap;
  padding-right: 8px;
  vertical-align: top;
  width: 100%;
  display: block;
  max-height: 20px;
  font-size: 12px;
}
.sndml.sndnm .nm {
  font-weight: 600 !important;
  font-size: 14px;

  color: #ffffff;
}
.sndtd {
  text-align: right;
  white-space: nowrap;
  vertical-align: top;
  display: flex;
  align-items: center;
  max-height: 20px;
  font-size: 12px;
}
.mlbistr {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.stared {
  display: flex;
}
.staredCheck {
  padding: 2px !important;
}
.sndact {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
}
.sndact .more svg,
.sndact .replay svg {
  color: #fff;
}
.sndact svg {
  font-size: 1.25rem;
  width: 20px;
  height: 20px;
  /* color: rgb(0 0 0 / 72%); */
}
.snddt {
  color: #797979;
  margin-right: 10px;
}
.mlinsdDtlwa {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 0px 0px 20px 56px;
}
.mailBodyText {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  color: #fff;
}
.mlrecIdlst .MuiIconButton-root svg {
  color: #797979;
}
.emailViewArea .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters,
.emailViewArea .MuiAccordionSummary-content.Mui-expanded {
  margin: 10px 0;
}
.mlatchHead {
  font-weight: 500 !important;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #fff;
  opacity: 0.6;
  font-size: 14px;
}
.mailAttatch {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-top: 1px dotted #545454;
  margin-top: 10px;
}
.mlatchFiles {
  margin-left: -8px;
  margin-bottom: -16px;
}
.mailFile {
  display: block;
  float: left;
  margin: 0 0 10px 10px;
  height: 90px;
  width: 136px;
  position: relative;
  -webkit-user-drag: element;
  user-select: none;
  font-size: 0.875rem;
}
.mlfileAnc {
  background-color: #fff;
  color: #545454;
  outline: none;
  display: inline-block;
  height: 90px;
  width: 136px;
  overflow: hidden;
  position: relative;
  z-index: 0;
  text-decoration: none;
}
.mlfAct {
  position: absolute;
  left: 32px;
  opacity: 0.01;
  bottom: 15px;
  display: flex;
}
.fASG {
  background: #fff;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.mfaVY {
  position: absolute;
  overflow: visible;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  z-index: 2;
}
.mfaZn {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  border-top: 1px solid #e5e5e5;
}
.mfaZn:after {
  content: "";
  font-size: 0;
  position: absolute;
  top: 118px;
  left: 0;
  right: 39px;
  height: 0;
  border-bottom: 1px solid #e5e5e5;
}
.mfaZm {
  top: 0;
  left: 0;
  width: 0;
  height: 118px;
  border-left: 1px solid #e5e5e5;
}
.mfaZm:after {
  content: "";
  font-size: 0;
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 118px;
  border-right: 1px solid #e5e5e5;
}

.mfaSI {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 3;
  height: 20px;
  width: 20px;
  border-right: solid 20px transparent;
}
.mfaSI .mfaSJ {
  position: absolute;
  width: 0;
  height: 0;
  bottom: 0;
  right: -20px;
  border-width: 10px;
  border-style: solid;
  z-index: 3;
  border-color: #545454;
}

.mfaSI:after {
  content: "";
  font-size: 0;
  position: absolute;
  bottom: 0;
  right: -20px;
  background: no-repeat url(../assets/image/mfafter.png) 0px 0px;
  height: 20px;
  width: 40px;
  z-index: 4;
}

.mfaSH {
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  overflow: hidden;
  z-index: 1;
}

.mfaQG.mfaYB {
  opacity: 1;
}
.mfaQG {
  -webkit-transition: opacity 0.5s linear;
  transition: opacity 0.5s linear;
  opacity: 0;
  position: absolute;
  border: none;
  position: absolute;
  top: 0px;
  width: 100%;
}
.mfaYy {
  background-color: #f5f5f5;
  border-top: 1px solid #e5e5e5;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
}
.mailFile:hover .mlfAct {
  opacity: 1;
}
.mailFile:hover .mfaYy {
  top: 0;
  border-top: none;
}
.mfaYA {
  -webkit-align-items: center;
  align-items: center;
  float: left;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  height: 32px;
  -webkit-justify-content: center;
  justify-content: center;
  width: 32px;
}
.mfaSM {
  width: 16px;
  height: 16px;
  border: none;
}
.mfaYz {
  overflow: hidden;
}
.mfaQA {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #777;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  margin-right: 32px;
  margin-top: 8px;
  word-wrap: normal;
}
.mfaV3 {
  -webkit-user-select: none;
}
.mfaYp {
  font-size: 11px;
  color: #aaa;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  display: none;
}
.emailReplayArea {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 34px;
  gap: 10px;
}
.emlrlpyBtn {
  border-radius: 8px !important;
  border-color: #4c4c4c !important;
  color: #707070 !important;
  text-transform: capitalize !important;
  font-size: 14px !important;
}
.emlrlpyBtn:hover {
  border-color: #ffffff !important;
  color: #ffffff !important;
}
.mlrecIdlst span {
  margin-right: 4px;
  color: #797979;
  font-size: 12px !important;
}
/* email view container end */

/* multi thread start */
.emlmlthrcont {
  color: #9c3c7c;
}
.emlmlthrcont p {
  margin: 2px 0px;
  font-size: 12px !important;
}
.emlmlthrcont ul {
  padding-inline-start: 6px;
  list-style-type: none;
}
li.lstMlcont {
  border-left: 1px solid #9c3c7c;
  padding-left: 4px;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 12px !important;
}
.mlthrdBody,
.mlthrdHead {
  margin-bottom: 10px;
}
.mltSigUsrImg img {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}
.mlthrdHead p {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #50103b;
}

/* multi thread end */

/* mail replay start */
.mlreplyBdy .mlinsdDtlwa {
  background: #fff;
  padding: 10px;
  padding-top: 0px;
  box-shadow: 0px 2px 7px -3px #00000069;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: 0px 0px 20px 56px;
}
.emlRplyContainer .mlbiSenderInfo {
  background: #fff;
  padding: 10px;
  padding-bottom: 5px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px -5px 7px -3px #00000069;
}
.rplymailBody {
  width: 100%;
}
.rplymailBody .composeMailBody {
  padding: 0px;
}
.emlRplyContainer .showMlDetlCntnr {
  border: none !important;
}
.emlRplyContainer .composeMailAction {
  padding: 10px 0px;
  width: 100%;
}
.cMailAction {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
}
.cMailAction .MuiButtonBase-root.MuiIconButton-root {
  height: 36px;
  width: 36px;
  padding: 6px;
  background: #ededed;
}
.viewEmlAtchFile {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  width: calc(100% - 12px);
  padding: 6px;
  overflow: hidden;
  overflow-y: overlay;
  max-height: 90px;
}
.atfilEml {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid #d4d4d4; */
  border-radius: 4px;
  padding: 4px;
  gap: 2px;
  background-color: #e1e1e1;
}
.atfilNm {
  color: #1c1c1c;
  font-size: 12px;
  display: -webkit-box;
  width: 132px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  opacity: 0.6;
  text-overflow: ellipsis;
}
.atfilEml .MuiButtonBase-root.MuiIconButton-root {
  height: 26px;
  width: 26px;
  padding: 5px;
  background-color: #f1f1f1;
}
.atfilEml .MuiButtonBase-root.MuiIconButton-root svg {
  font-size: 20px;
}
.MuiButtonBase-root.MuiIconButton-root.mobBackToMailList {
  display: none;
}
/* mail replay start */

@media screen and (max-width: 1600px) and (min-width: 1368px) {
  .composeMailContainer {
    width: 34vw;
    height: 100vh;
    order: 1;
    z-index: 1000;
    float: right;
  }
  .composrMailPesition {
    width: 34vw;
    position: relative;
    height: 100vh;
    min-height: 1px;
    float: left;
  }
}

@media screen and (max-width: 1367px) and (min-width: 1021px) {
  .emailViewContainer {
    min-width: 520px;
    position: static;
  }
  .composeMailContainer {
    width: 38vw;
    height: 100vh;
    order: 1;
    z-index: 1000;
    float: right;
  }
  .composrMailPesition {
    width: 38vw;
    position: relative;
    height: 100vh;
    min-height: 1px;
    float: left;
  }
}
