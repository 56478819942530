.zoeyBotInc {
  z-index: 1024;
  position: fixed;
  bottom: 70px;
  height: 64px;
  width: 64px;
  border-radius: 32px;
  background-color: #fff;
  right: 20px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.zoeyBotInc .botIncIcon {
  height: 28px;
  width: auto;
}

.botFlotingContainerArea {
  height: calc(100vh - 80px);
  width: 40vw;
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-color: #000000;
  z-index: 99;
}
.botFlotingPanel {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 80px);
  overflow: hidden;
  position: relative;
  border-left: 0.1px solid #545454;
  background-color: #000;
}
.botFlotingContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #000000;
}
.botContHeader {
  order: 1;
  display: flex;
  width: auto;
  padding: 16px 24px 16px 24px;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  background-color: #000;
  border-bottom: 1px solid #545454;
}
.botContContain {
  order: 2;
  flex: 1 1;
  /* padding: 15px 25px 10px; */
  height: calc(100vh - 235px);
}
.botContContainDetails {
  overflow: hidden;
  overflow-y: overlay;
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 25px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.botContContainDetails::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}
.botContContainDetails::-webkit-scrollbar-thumb {
  background-color: rgb(197, 195, 195);
}

.botContContainDetails::-webkit-scrollbar-track {
  background: #545454;
}
.botContFooter {
  order: 3;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 24px 10px 24px;
  /* border-top: 1px solid #5454548a; */
}
.botHeadText {
  font-family: Inter;
  font-size: 22px;
  font-weight: 600;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.botInputGrp {
  display: flex;
  width: 100%;
  border-radius: 26px;
  background-color: #474747;
}
.botComntInput {
  height: 100%;
  flex: 1 1;
  border: none;
  outline: none;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: transparent;
  padding: 6px 10px 6px 22px;
  height: 32px;
  color: #fff;
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  width: 100%;
}

#botComntInput::-webkit-input-placeholder,
#botComntInput::-moz-placeholder,
#botComntInput:-ms-input-placeholder,
input#botComntInput:-moz-placeholder {
  color: #fff;
  font-size: 16px;
  font-family: Inter;
  font-weight: 600;
  width: 100%;
}

.botInputbtnGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
  margin-left: 10px;
  max-width: 80px;
}
.botComtSndBtn {
  color: #ffffff !important;
  padding: 0px 16px !important;
}
.botAIBtn {
  color: #ffffff !important;
  opacity: 0.51;
  padding: 8px !important;
}

.botMsg {
  position: relative;
  margin-bottom: 4px;
  padding-left: 0px;
  padding-right: 10px;
  width: calc(100% - 56px);
}
.bMsg {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  width: 100%;
}
.msgImg {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.msgImg img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: contain;
}
.msgBody {
  display: flex;
  flex-direction: column;
  gap: 6px !important;
  width: calc(100% - 56px);
}
.msgUserNm {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
}
.msgBodyTxt {
  color: rgb(255, 255, 255);
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  background-color: #333333;
  border-radius: 5px;
  padding: 10px;
  /* line-height: 25px; */
  margin-top: 10px;
}

.msgFrmUser .msgBodyTxt p {
  margin: 0px 0px 4px 0px;
}
.msgFrmBot .msgBodyTxt p {
  margin: 0px 0px 16px 0px;
}

.zoeyActions {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-end;
}

.trashZoey {
  color: red !important;
}

.createTicketBtn {
  border: 1px solid gray !important;
  border-radius: 5px !important;
  color: white !important;
  margin-top: 5px !important;
}

.typeOfTickets {
  display: flex !important;
  flex-direction: column;
}
.msgBodyTxt .createTicketBtn:last-child {
  margin-left: 5px !important;
}
