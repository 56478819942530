.driveContainerArea {
  height: 100%;
  width: 100%;
}
.driveContainArea {
  height: 100%;
  width: 100%;
}
.driveContainer {
  padding: 0px 70px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  height: calc(100vh - 80px);
}
.ReactTable {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #54545400;
  width: 100%;
  font-family: Inter;
}
.ReactTable .rt-thead .rt-tr {
  text-align: left;
}
.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid #545454;
  height: 48px;
  transition: all 0.4s;
}
.ReactTable .rt-tbody .rt-tr-group:hover {
  background-color: #333333;
}
.rt-thead .rt-tr {
  border-bottom: 1px solid #9d9d9d;
}
.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgb(255 255 255);
}
.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 rgb(255 255 255);
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding: 14px 10px;
  line-height: normal;
  position: relative;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-family: "Inter";
  color: #fff;
}
.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.02);
  font-size: 14px;
  font-weight: 400;
}
.ReactTable .-pagination .-btn {
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 8px;
  padding: 6px;
  font-size: 14px;
  color: rgb(255 255 255);
  background: rgb(255 255 255 / 10%);
  transition: all 0.1s ease;
  cursor: pointer;
  outline-width: 0;
}

.ReactTable .-pagination input,
.ReactTable .-pagination select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #333;
  color: #ffffff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 8px;
  font-weight: normal;
  outline-width: 0;
}

.driveHeadArea {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0px 6px;
  height: 34px;
  width: 100%;
  gap: 20px;
}
.driveHedLft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  flex: 1;
}
button.driveAddBtn {
  background-color: #00adef;
  border-radius: 4px;
  color: #ffffff;
  font-size: 16px;
  line-height: 26px;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  text-transform: capitalize;
  font-family: Inter;
}
button.driveAddBtn:hover {
  background-color: #0485b8;
  border-radius: 4px;
  color: #ffffff;
  font-size: 16px;
  line-height: 26px;
  padding: 4px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  text-transform: capitalize;
  font-family: Inter;
}
button.driveAddBtn svg {
  height: 24px;
  width: 24px;
}
.driveSearch {
  width: 100%;
}
.driveSearch .searchUser .serchInput .MuiInputBase-root.MuiOutlinedInput-root {
  width: 100%;
}
.driveSearch .searchUser {
  display: flex;
  width: -webkit-fill-available;
  padding: 2px 9px;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  background: #272727;
  max-width: 500px;
  width: calc(100% - 18px);
}

.driveSearch .serchInput input {
  width: 100%;
  flex-shrink: 0;
  flex: 1 1;
  color: #ffffff;
  font-family: Inter;
  font-size: 14px;
  line-height: 30px;
  font-style: normal;
  font-weight: 400;
  background-color: transparent !important;
  outline: none;
  box-shadow: none;
  border: none;
  padding: 5px 8px;
}
.driveContainer .rt-td .MuiButtonBase-root.MuiIconButton-root {
  background-color: transparent;
  color: #d2d2d2;
}
.ReactTable .rt-tbody {
  flex: 99999 1 auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow-y: overlay;
  max-height: calc(100vh - 288px);
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.popOverBtnGrp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.popOverContain {
  font-size: 18px;
  color: #000000;
}
.popOverContain p {
  margin-bottom: 50px;
}
.driveContainer .rt-tbody::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

.driveContainer .rt-tbody::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 1);
}

.driveContainer .rt-tbody::-webkit-scrollbar-track {
  background: rgb(18 18 18 / 70%);
}
.driveContainer .ReactTable .rt-td {
  flex: 1 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0px 10px;
  overflow: hidden;
  transition: 0.3s ease;
  transition-property: width, min-width, padding, opacity;
}
.driveSpace {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.driveSpIcon svg {
  height: 32px;
  width: 36px;
  color: #969696;
}
.drvAvlSpace {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  min-width: 200px;
  max-width: 250px;
  width: 100%;
}
.spaceBar {
  width: 100%;
}
.spaceFill {
  background-color: #606060;
  border-radius: 8px;
  position: relative;
  margin: 2px 0;
  height: 6px;
  width: 100%;
}

.spaceFillData {
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
}
.spaceFillData.up50 {
  background-color: #e0e0e0;
}
.spaceFillData.up80 {
  background-color: #d5a600;
}
.spaceFillData.up100 {
  background-color: #ce0000;
}
.spaceDtSE {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.spaceDtSEFill,
.spaceDtSEAvale {
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.12px;
}

.driveHedRight {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
}

.driveViewBtnGrp {
  background-color: #333333;
  border: 1px solid #545454;
  border-radius: 8px !important;
  box-shadow: none !important;
}
.dvBtn {
  padding: 5px !important;
  opacity: 0.4;
  cursor: pointer;
}
.dvBtn svg {
  color: #ffffff;
}
.dvBtn.dViewActive {
  opacity: 1;
}

.drivInfoBtn {
  padding: 5px !important;
  border: 1px solid #545454 !important;
  color: #fff !important;
  opacity: 0.5;
  transition: all 0.2s;
}
.drivInfoBtn:hover {
  opacity: 1;
}

.htmlTooltipUl {
  padding-inline-start: 20px;
}
.drvfileModf,
.drvfileOwner,
.drvfileSize,
.drvfileName {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 4px;
  gap: 6px;
  font-size: 14px;
  font-family: "Inter";
  /* cursor: pointer; */
  height: 100%;
  width: 100%;
}
.drvfileModf svg,
.drvfileOwner svg,
.drvfileSize svg,
.drvfileName svg {
  color: #666666;
}

.rt-th.rt-resizable-header:last-child .rt-resizable-header-content {
  text-align: right;
}

.drvfileActionBtnGrp {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  opacity: 0;
  transition: all 0.4s;
}

.drvfileActionBtnGrp .MuiButtonBase-root.MuiIconButton-root {
  height: 30px;
  width: 30px;
  padding: 4px;
  background-color: #636363;
  transition: all 0.4s;
}
.drvfileActionBtnGrp .MuiButtonBase-root.MuiIconButton-root:hover {
  height: 30px;
  width: 30px;
  padding: 4px;
  background-color: #ffffff;
}
.drvfileActionBtnGrp
  .MuiButtonBase-root.MuiIconButton-root
  svg.MuiSvgIcon-root {
  height: 18px;
  width: 18px;
  color: #ffffff;
  transition: all 0.4s;
}

.drvfileActionBtnGrp
  .MuiButtonBase-root.MuiIconButton-root:hover
  svg.MuiSvgIcon-root {
  color: #000000;
}

.ReactTable .rt-tbody .rt-tr-group .drvfileActionBtnGrp {
  opacity: 1;
}
.drvfileActionBtnGrp
  .MuiButtonBase-root.MuiIconButton-root.drvTrshBtn:hover
  svg {
  color: #ed3237;
}
.drivAddElemntMenu .MuiPaper-root {
  margin-top: 10px;
}
/* .drivAddElemntMenu .MuiPaper-root ul.MuiList-root.MuiList-padding.MuiMenu-list {
} */
.menuwithIcons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  gap: 10px;
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
  color: #272727;
}

.menuwithIcons svg {
  height: 20px;
  width: 20px;
}
.slideColor {
  color: #f44336;
}
.wordColor {
  color: #0078d7;
}
.sheetColor {
  color: #009688;
}

.modelBox {
  border-radius: 8px;
  padding: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  flex-direction: column;
  width: 100%;
}
.modeltitle {
  order: 1;
  width: 100%;
  font-size: 24px;
  font-family: Inter;
  color: #000000;
}
.setModelFileName {
  width: 100%;
  order: 2;
}
.modelInputField {
  padding: 8px 18px;
  border-radius: 8px;
  border: none;
  outline: 1px solid #b2b2b2;
  width: calc(100% - 32px);
  font-family: "Inter";
  font-size: 16px;
  line-height: 24px;
}
.modelInputField:focus-visible {
  outline: 1px solid #00adef;
}
.modelFooter {
  order: 3;
  width: 100%;
  margin-top: 20px;
}
.driveFolderWthBack {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
}

.backDrivBtn button {
  padding: 4px;
  background-color: #333333;
  color: #ffffff;
}
.backDrivBtn button:hover {
  background-color: #272727;
}
.backDrivBtn button svg {
  color: #ffffff;
}
.drivFolderWithName {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.drvPerentFold {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin-right: 28px;
  color: #959595;
  font-size: 14px;
  gap: 5px;
  line-height: 20px;
  letter-spacing: 0.6px;
  font-family: Inter;
  cursor: pointer;
}
.drvPerentFold::after {
  content: "/";
  position: absolute;
  right: -22px;
}
.drvActiveFold {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  position: relative;
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  font-family: Inter;
  cursor: pointer;
}

.sharedToUsersGroup {
  margin-top: 9px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  gap: 14px;
  width: 100%;
  max-height: 40vh;
  overflow-y: auto;
}

.shareToGuestImage {
  height: 26px;
  width: 26px;
  border-radius: 16px;
  border: 2px solid #fff;
  position: relative;
}
.shareToGuestImage img {
  height: 100%;
  width: 100%;
  border-radius: 16px;
  object-fit: cover;
}

.shareToGuestListMainDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  cursor: pointer;
  transition: all 0.6s;
  width: 100%;
  /* justify-content: space-between; */
}

.shareToGuestListDtlsDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  cursor: pointer;
  transition: all 0.6s;
  width: 100%;
  /* justify-content: space-between; */
}

.shareToGuestListActionDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  cursor: pointer;
  transition: all 0.6s;
  width: 100%;
  /* justify-content: space-between; */
}

.shareToGuestName {
  /* color: #fff; */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.grantAccessSelect {
  border: 1px solid black !important;
  border-radius: 10px !important;
}

/* For WebKit-based browsers (Chrome, Safari, etc.) */
.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
  border: 1px solid black;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* For Internet Explorer/Edge */
.custom-scrollbar::-ms-scrollbar {
  width: 5px;
}

.custom-scrollbar::-ms-scrollbar-track {
  background-color: #f1f1f1;
}

.custom-scrollbar::-ms-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.custom-scrollbar::-ms-scrollbar-thumb:hover {
  background-color: #555;
}

/* For Firefox */
.custom-scrollbar {
  scrollbar-color: #888 #f1f1f1;
  scrollbar-width: thin;
}

.closeDilog {
  position: absolute;
  right: 5px;
  top: 5px;
  font-weight: 600 !important;
  font-size: 15px;
  color: #282828;
  z-index: 99;
}

.shareToGuestImage .MuiBadge-dot {
  background-color: rgb(255, 0, 0) !important;
}

.shareDriveLoader {
  max-height: 41px !important;
}

.shareDriveLoader .MuiCircularProgress-root {
  color: white !important;
  width: unset !important;
  height: unset !important;
}

.dfultDarkSecondaryBtn:disabled {
  color: gray !important;
  cursor: not-allowed !important;
}

.dfultPrimaryBtn:disabled {
  background-color: gray !important;
  cursor: not-allowed !important;
}

.selectedFilesContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  position: absolute;
  bottom: 8px;
  gap: 10px;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-contained.drivCheckSlctBtn {
  padding: 8px 10px;
  border-radius: 8px;
  background-color: #606060;
  color: #fff;
}

.selectedFilesTitle {
  font-size: 18px;
  margin: 0;
  color: #fff;
}

.selectedFilesActions {
  display: flex;
}

.selectedFilesActions button {
  margin-left: 10px; /* Add some space between buttons */
}

.closeIcon {
  margin-right: 10px;
}
/* file dpload view start */
.swipUpDocMain {
  /* position: relative; */
  position: absolute;
  bottom: 36px;
}

ul.swipUpDocUL {
  list-style-type: none;
  padding-inline-start: 10px;
  width: calc(100% - 20px);
}
ul.swipUpDocUL li {
  margin-bottom: 12px;
  padding-bottom: 6px;
  margin-top: 6px;
  border-bottom: 1px dashed #b2b2b2;
}
ul.swipUpDocUL li .swipUpDocLI {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;
  font-size: 14px;
  line-height: 14px;
  color: #fff;
}
ul.swipUpDocUL li .swipUpDocLI .animeUp {
  font-size: 12px;
  line-height: 12px;
  opacity: 0.5;
  display: flex;
  align-items: flex-end;
  gap: 4px;
}
.swipUpDocContain.MuiModal-root .MuiPaper-root.MuiPaper-elevation {
  max-width: 420px;
  max-height: 80vh;
  border-radius: 12px;
  background-color: #282828;
  left: 70px;
  bottom: 10px;
}
#swipUpDocContWSpace {
  padding: 0px;
  padding-top: 0px;
  height: 100%;
  overflow: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #545454;
}
.upfileCuntHead {
  position: sticky;
  padding: 10px 16px;
  top: 0px;
  padding-top: 16px;
  background-color: #333;
  color: #fff;
  width: calc(100% - 32px);
  font-size: 18px;
  z-index: 1;
}

.animeUploading {
  display: flex;
  justify-content: space-between;
  width: 30px;
}

.animeUpdot {
  width: 6px;
  height: 6px;
  background-color: #c5c5c5;
  border-radius: 50%;
  animation: animeUploading 1.4s infinite ease-in-out both;
}

.animeUpdot:nth-child(1) {
  animation-delay: -0.32s;
}

.animeUpdot:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes animeUploading {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.MuiButtonBase-root.MuiButton-root.viewUploadingFileBtn {
  position: absolute;
  bottom: -30px;
  min-width: 200px;
  width: max-content;
  padding: 8px 10px;
  border-radius: 8px;
  background-color: #606060;
}
/* file dpload view end */
