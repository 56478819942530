.cleintLogo {
  height: 150px;
  width: 150px;
}
.cleintLogo img {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  object-fit: cover;
}

.hexagon-gallery {
  margin: 0 auto;
  /* margin-top: 50px; */
  /* width: 100%; */
  display: grid;
  grid-template-columns: repeat(13, 0fr);
  grid-auto-rows: 150px;
  grid-gap: 14px;
  padding-bottom: 50px;
}
.hex-hide {
  opacity: 0;
}
.hex-noImg img {
  display: none;
}
.hex {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 7px;
  width: 175px;
  height: 200px;
  background-color: #ffffff;
  -webkit-clip-path: polygon(
    50% 0%,
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%
  );
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.hex:first-child {
  grid-row-start: 1;
  grid-column: 2 / span 2;
}

.hex:nth-child(2) {
  grid-row-start: 1;
  grid-column: 4 / span 2;
}

.hex:nth-child(3) {
  grid-row-start: 1;
  grid-column: 6 / span 2;
}
.hex:nth-child(4) {
  grid-row-start: 1;
  grid-column: 8 / span 2;
}
.hex:nth-child(5) {
  grid-row-start: 1;
  grid-column: 10 / span 2;
}
.hex:nth-child(6) {
  grid-row-start: 1;
  grid-column: 12 / span 2;
}

.hex:nth-child(7) {
  grid-row-start: 2;
  grid-column: 3 / span 2;
}

.hex:nth-child(8) {
  grid-row-start: 2;
  grid-column: 5 / span 2;
}

.hex:nth-child(9) {
  grid-row-start: 2;
  grid-column: 7 / span 2;
}

.hex:nth-child(10) {
  grid-row-start: 2;
  grid-column: 9 / span 2;
}
.hex:nth-child(11) {
  grid-row-start: 2;
  grid-column: 11 / span 2;
}
.hex:nth-child(12) {
  grid-row-start: 2;
  grid-column: 13 / span 2;
}

.hex:nth-child(13) {
  grid-row-start: 3;
  grid-column: 2 / span 2;
}

.hex:nth-child(14) {
  grid-row-start: 3;
  grid-column: 4 / span 2;
}

.hex:nth-child(15) {
  grid-row-start: 3;
  grid-column: 6 / span 2;
}
.hex:nth-child(16) {
  grid-row-start: 3;
  grid-column: 8 / span 2;
}
.hex:nth-child(17) {
  grid-row-start: 3;
  grid-column: 10 / span 2;
}
.hex:nth-child(18) {
  grid-row-start: 3;
  grid-column: 12 / span 2;
}
