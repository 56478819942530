.AnalyticsDashboardContainerArea {
  height: 100%;
  width: 100%;
  display: block;
}
.AnalyticsDashboardContainArea {
  height: 100%;
  width: 100% !important;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.AnalyticsDashboardContainer {
  padding: 10px 70px 10px 70px;
  width: calc(100% - 140px);
  height: calc(100vh - 100px);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
}
.AnalyticsDashboardHeader {
  color: #fff;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
}
.AnalyticsDashboardSubHeader {
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.anDashCardContainArea {
  height: auto;
  width: 100%;
  overflow-y: overlay;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;
}
.anDashIndCard {
  width: calc((100% - 40px) / 3);
  height: auto;
}
.anDashIndCard.manageWidth50 {
  width: calc((100% - 20px) / 2);
  height: auto;
}
.anDashIndCard.manageWidth50 .andCrdGraf {
  min-width: 200px;
}
.anDashCardArea {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  gap: 8px;
  background-color: #292929;
  border: 1px solid #444444;
  border-radius: 12px;
  width: calc(100% - 42px);
  height: calc(100% - 42px);
}

.anDashCardContainArea::-webkit-scrollbar {
  width: 0px !important;
  height: 6px !important;
}
.anDashCardContainArea::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 1);
}
.anDashCardContainArea::-webkit-scrollbar-track {
  background: rgb(18 18 18 / 70%);
}
.react-datepicker__time-list::-webkit-scrollbar {
  width: 4px !important;
  height: 6px !important;
}
.react-datepicker__time-list::-webkit-scrollbar-thumb {
  background-color: rgb(66, 66, 66);
}
.react-datepicker__time-list::-webkit-scrollbar-track {
  background: rgba(211, 211, 211, 0.7);
}
.andCrdNM {
  font-size: 18px;
  font-family: Inter;
  font-weight: 500;
  color: #fff;
}
.andCrdNM .refreshIcon.MuiIconButton-root {
  height: 28px;
  width: 28px;
  opacity: 1;
  padding: 4px;
}
.andCrdFooter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
.centTxtShow {
  position: relative;
  /* width: 180px;
  height: 180px; */
}
.highlighted-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  line-height: normal;
  color: #fff;
  text-transform: UPPERCASE;
  font-weight: 300;
}
.highlighted-text .highDataCount {
  font-size: 24px;
}
.andCrdGraf {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 10px;
  width: 100%;
  height: 100%;
}
.hw100 {
  height: 100%;
  width: 100%;
}
/* .centTxtShow canvas {
  max-height: 180px;
  height: 180px;
} */
.anDLftGrf {
  /* flex: 1; */
  width: 35%;
}
.anDRightGrf {
  /* width: 100%;
  height: 100%; */
  /* flex: 1; */
  width: 65%;
}
.rightFlotingPanel {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 80px);
  overflow: hidden;
  position: relative;
  border-left: 0.1px solid #545454;
  background-color: #000;
}
.rightFlotingContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #000000;
}

.rfContHeader {
  order: 1;
  display: flex;
  width: auto;
  padding: 16px 24px 16px 24px;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  background-color: #000;
  border-bottom: 1px solid #545454;
}

.rfContContain {
  order: 2;
  flex: 1 1;
  padding: 15px 25px 10px;
  height: calc(100vh - 235px);
}
.anDRightGrf.oneGraph100 {
  width: 100%;
}
.rfContFooter {
  order: 3;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 24px 10px 24px;
  border-top: 1px solid #5454548a;
}
.rfcHeadText {
  font-family: Inter;
  font-size: 22px;
  font-weight: 600;
  color: #ffffff;
}

.rfcHActionBtnGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
}

.actionBtn .MuiIconButton-root {
  color: #808080;
  height: 28px;
  width: 28px;
  padding: 4px;
}

.rfContContainDetails {
  overflow: hidden;
  overflow-y: overlay;
  position: relative;
  width: 100%;
  height: 100%;
}
.widthAuto {
  width: auto !important;
}
.width100 {
  width: 100px !important;
  min-width: 100px !important;
}
.width250 {
  width: 250px !important;
  min-width: 250px !important;
}
.mxW-100 {
  max-width: 100px !important;
}
.mt10 {
  margin-top: 10px;
}
.frmText {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.rfContContainDetails .elementFormContainer .formElementGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  /* flex-wrap: wrap; */
  width: 100%;
}
.andHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.andCrdUnitCount span svg {
  color: #b2b2b2;
}
.andCrdUnitCount span {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  color: #b2b2b2;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.andCrdUnitCount {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
span.fbold {
  color: #fff !important;
  font-size: 14px;
  font-weight: 600;
}

.AnalyticsDashboardSubHeader .MuiIconButton-root {
  color: #808080;
  height: 28px;
  width: 28px;
  padding: 4px;
}

.budgetList {
  width: 100%;
  margin-bottom: 20px;
}
.budgetList ul {
  color: #fff;
  list-style-type: decimal;
  margin-block-start: 0px;
  padding-inline-start: 30px;
}
.budgetList ul li {
  border-bottom: 1px solid #1c1c1c;
  padding: 4px 0px 4px 6px;
}
.inputValue {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
}
.inputValue .formElement {
  max-width: 200px;
}

.width13 {
  width: calc((100% - 12px) / 3) !important;
}

.flex-wrap {
  flex-wrap: wrap;
  display: flex;
  width: 100%;
}
.outlineInput.MuiInputBase-root .MuiInputBase-input.MuiOutlinedInput-input {
  padding: 10px 14px;
}
.outlineInput .MuiInputAdornment-root .MuiTypography-root {
  color: #fff;
}
.tright {
  text-align: right !important;
}
.MuiButtonBase-root.MuiButton-root.addNRwBtn {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  background: #4c4c4c;
  padding: 6px 10px;
  border-radius: 4px;
  border: 1px solid #4c4c4c;
  display: flex;
  align-items: center;
  color: #a2a2a2;
  max-height: 32px;
  margin: 0 auto;
}
.MuiButtonBase-root.MuiButton-root.addNRwBtn:hover {
  color: #ffffff;
}

.margAuto {
  margin: 0 auto;
}
.centerpopos {
  display: flex;
  align-items: center;
  justify-content: center;
}
.MuiListSubheader-root.ListSubheader {
  font-style: italic;
  color: #000;
  font-weight: 600;
  font-size: 14px;
  background-color: #e1e1e1;
}
.textOnly.strong {
  font-weight: 700;
  font-size: 16px;
}
.orgDocheaderPrv {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}
.orgDocHedInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 6px;
}
.orgDocHedInfoElement {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  color: #022a3e;
}
.orgDocHedLogo img {
  max-height: 70px;
  width: auto;
  height: 65px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
}
.orgUsrDemogrphy {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}
.preSubHead {
  text-align: center;
  font-weight: 600;
  font-size: 18px !important;
  text-decoration: underline;
}
.orgUserEmpInfo {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
  flex-direction: column;
  font-size: 14px;
  font-family: Inter;
}
.orgLoiDT {
  font-weight: 700;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
}
.LoiDate span {
  font-weight: 400;
}
.loiContain p {
  font-size: 14px;
  font-family: Inter;
}
.loiContain p span {
  margin: 2px 4px;
  font-weight: 600;
}
.docReqArea .highText {
  font-weight: 600;
  font-size: 14px;
}
.docReqArea ul {
  padding-inline-start: 40px;
  list-style-type: decimal;
  font-size: 14px;
  font-family: Inter;
  line-height: 22px;
}
.docReqArea ul li {
  margin-bottom: 5px;
}
.loiFooter p {
  margin: 0px;
  font-size: 14px;
  line-height: 20px;
}
.loiFooter p.highText {
  font-weight: 600;
}
.salaryHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.autoHeight {
  height: auto !important;
}
.documentAddGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
}
.MuiButtonBase-root.MuiButton-root.addDocBtn {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  background: #4c4c4c;
  padding: 8px 14px;
  border-radius: 4px;
  border: 1px solid #4c4c4c;
  display: flex;
  align-items: center;
  color: #a2a2a2;
  height: 40px;
  margin: 0 auto;
}
.MuiButtonBase-root.MuiButton-root.addDocBtn:hover {
  color: #ffffff;
}
.listDocRequierd {
  color: #fff;
  font-size: 14px;
  font-family: Inter;
  width: 100%;
}
.listDocRequierd ul {
  margin: 6px 0px 20px 0px;
  padding-inline-start: 30px;
  list-style-type: decimal;
  width: calc(100% - 30px);
}
.listDocREle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  margin-bottom: 5px;
}
.listDocRequierd ul li .listDocREle .removeDoC {
  opacity: 0.7;
}
.listDocRequierd ul li:hover .listDocREle .removeDoC {
  opacity: 1;
}
.listDocRequierd .MuiButtonBase-root.MuiIconButton-root {
  color: #fff;
  background-color: #141414;
  padding: 6px;
}
.listDocRequierd .MuiButtonBase-root.MuiIconButton-root svg {
  font-size: 16px;
}
.docLstHd {
  font-size: 14px;
  opacity: 0.7;
  font-weight: 300;
}

.elementFormContainer .formControl .MuiButtonBase-root.MuiCheckbox-root {
  color: #707070;
  padding: 4px;
  margin-left: 10px;
}
.formControl .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd {
  justify-content: flex-end !important;
  margin-left: 0px;
  color: #fff;
}
.cuContainArea.FRItem .taskListTable tbody tr td {
  padding-bottom: 4px;
}
.elementWithAddMrElm {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
}

.MuiButtonBase-root.MuiIconButton-root.addMrElemBtn {
  color: #fff;
  background-color: #333;
  border-radius: 4px;
  border: 1px solid #707070;
}
.textHeadCenter {
  width: 100%;
  font-weight: 600;
  text-align: center;
  font-size: 20px;
  margin: 10px 0px;
}
.textWithOptionCol {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;
}
.textWithOptionCol .fromText {
  width: auto;
  color: #ffffff;
  font-family: Inter;
  opacity: 0.7;
}

.MuiPopover-root.MuiMenu-root.MuiModal-root
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded
  .MuiButtonBase-root.MuiCheckbox-root
  svg.MuiSvgIcon-root {
  color: #333333;
}
.infoBtn {
  padding: 2px !important;
}
.infoBtn svg {
  height: 24px;
  width: 24px;
  color: #545454;
}
.elmntWthInfo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.widthAuto {
  width: auto !important;
}

.modelContain.width90vwModel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  min-height: 20vh;
  height: auto;
  max-height: 90vh;
  overflow: hidden;
  overflow-y: overlay;
  /* animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp; */
}
.modHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  width: 100%;
}
.modHeaderWithAction {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
.modHeaderText {
  font-size: 28px;
  font-family: Inter;
  font-weight: 500;
}
.modbtnGrp {
  display: flex;
  align-items: center;
  gap: 4px;
}

.modSearchInputgrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 320px;
  border: 1px solid #b4b4b4;
  background-color: #ffffff;
  border-radius: 8px;
  height: 38px;
}
.modSearchInputgrp .seacrhIcon {
  padding: 8px;
  height: 38px;
  width: 38px;
  color: #333;
  /* background-color: #b4b4b4 !important; */
  border-radius: 0px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.modSerchInput {
  width: 100%;
  flex-shrink: 0;
  flex: 1 1;
  color: #333333;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  background-color: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  height: 100%;
  padding: 0px 10px;
  /* border-right: 1px solid #545454; */
}
.modBody {
  width: 100%;
  height: auto;
}
.modResptable {
  overflow-x: auto;
}
.modResptable table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #141414;
  color: #ffffff;
}

.modResptable table tr th {
  font-size: 14px;
  font-family: Inter;
  padding: 4px !important;

  width: auto;
  cursor: pointer;
  background-color: #c9c9c9;
  border-color: #b2b2b2;
}
.minWidth160 {
  min-width: 150px;
}

.modResptable table th,
.modResptable table td {
  border: 1px solid #b2b2b2;
  border-collapse: collapse;
  text-align: left;
  padding: 4px;
  color: #1c1c1c;
  height: 26px;
}

.textAlign {
  text-align: center !important;
}
.employeeInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.empInElm .titl {
  width: 45px;
}
.empInElm {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-size: 16px;
  font-weight: 600;
  font-family: Inter;
}

.formTextFieldArea input:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 50px #333 inset;
  color: rgb(255, 255, 255) !important;
  -webkit-text-fill-color: #ffffff;
}

.attendanceTable {
  caption-side: bottom;
  margin-bottom: 1rem;
  vertical-align: top;
  /* border-bottom: 1px solid #545454; */
  /* border-top: 1px solid #545454; */
  border: 0 none;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  table-layout: fixed;
  width: 100%;
}

.attendanceTable > thead {
  vertical-align: bottom;
}

.attendanceTable thead tr th {
  padding: 0 10px;
  height: 50px;
  vertical-align: middle;
  background-color: #333333;
  color: #fff;
  font-weight: 500;
  border: none;
}
.hilight {
  background-color: #292929;
}
/* .attendanceTable tbody tr td,
.attendanceTable tbody tr th,
.attendanceTable thead tr th {
  padding: 4px;
  border: 1px solid #545454;
} */
.attendance-icon {
  width: 50px;
  border-color: #545454;
  border-style: solid;
  border-width: 0 0 1px 1px;
  color: rgba(255, 255, 255, 0.87);
  text-align: center;
  font-size: 13px;
  height: 66px;
  padding: 8px;
  padding-left: 15px;
  box-sizing: border-box;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-success svg {
  color: #198754;
}
.text-warning svg {
  color: #ffc107;
}
.text-danger svg {
  color: #dc3545;
}
.col-orange svg {
  color: #ff5e00;
}
.border-bottom-0 {
  border-bottom: 0px;
}
.userWthImg {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 6px);
  gap: 6px;
  padding: 2px 2px 2px 4px;
  cursor: pointer;
}
.attnd-user {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.attnd-user img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.mt-sm-2 h6 {
  margin: 6px 0px;
  font-weight: 400;
  font-size: 14px;
  font-family: "Inter";
  display: -webkit-box;
  width: 130px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #fff;
  text-overflow: ellipsis;
}

.pblDys {
  display: block;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 18px;
}
.attendance-icon {
  cursor: pointer;
}
.mt-sm-2 {
  color: #777777;
  font-size: 12px;
  width: calc(100% - 38px);
}
.overflowHidden {
  overflow: hidden !important;
}
.attendEmployLstTable,
.attendTableChart,
.attendTableHeader {
  border: 0 none;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  table-layout: fixed;
  width: 100%;
}
.tableHeaderContainer table.attendTableHeader tbody th {
  border-color: #545454;
  border-style: solid;
  border-width: 0px 0 1px 1px;
  color: rgba(255, 255, 255, 0.87);
  text-align: center;
  font-size: 13px;
  height: 39px;
  padding: 8px;
  padding-left: 15px;
  box-sizing: border-box;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #292929;
}
.cell-dimention {
  width: 50px;
}
.TableContainer {
  height: 100%;
  width: 100%;
  padding: 0px;
}
.e-attendLib {
  display: block;
  outline: medium none;
  overflow: hidden;
  position: relative;
  -webkit-user-select: none;
  user-select: none;

  background-color: transparent;
  border: none;
  height: 100%;
  width: 100%;
}
.e-attend-table-container {
  display: inline-flex;
  position: relative;
  vertical-align: top;
  height: 100%;
}
.tdWith180 {
  width: 180px;
}

.hideScroolBar::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}
.hideScroolBar::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 1);
}

.hideScroolBar::-webkit-scrollbar-track {
  background: rgb(18 18 18 / 70%);
}
.hideScroolBar6::-webkit-scrollbar {
  width: 0px !important;
  height: 6px !important;
}
.hideScroolBar6::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 1);
}

.hideScroolBar6::-webkit-scrollbar-track {
  background: rgb(18 18 18 / 70%);
}
.atendUserTDHeight {
  height: 64px;
}
.atndEmpLstTbTR:last-child .atendUserTDHeight {
  height: 70px;
}

.modelContain.width90vwModel::-webkit-scrollbar {
  width: 0px !important;
  height: 8px !important;
}
.modelContain.width90vwModel::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 1);
}

.modelContain.width90vwModel::-webkit-scrollbar-track {
  background: rgb(18 18 18 / 70%);
}
.headerWith120 {
  width: 120px;
}
.tableheader {
  overflow-y: auto;
}

.attenadTableLRHeight {
  height: calc(100vh - 278px);
  overflow-y: auto;
}
.padd0 {
  padding: 0px;
  margin: 0px;
  border-spacing: 0px;
}
.twdays {
  width: calc(120px - 2.5px) !important;
}
.attendanceTable tr td,
.attendanceTable tr th {
  border-spacing: 0;
  padding: 0px;
  margin: 0px;
}
.border-bottom-1 {
  border-bottom: 1px solid #545454;
}
.tbHead {
  color: #fff;
  font-weight: 600;
  text-align: center;
  font-size: 12px;
  letter-spacing: 0.5px;
}
.atndEmpLstTbTR {
  border-bottom: 1px solid #545454;
}
.atndEmpLstTbTR:hover {
  background-color: #292929;
}
.hilight.twdays {
  border-color: #545454;
  border-style: solid;
  border-width: 0 0 1px 1px;
}
.dashHeadereleGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}
.dashAttendanceDT {
  width: 110px !important;
  height: 40px;
  outline: none;
  border: none;
  padding-block: 0px;
  padding: 0px 16px 0px 32px !important;
  border-radius: 4px;
  color: #ffffff;
  background-color: #333333;
  font-size: 16px;
  font-family: Inter;
  text-align: right;
}

.present {
  background-color: #16a085;
}
.absent {
  background-color: #c00000;
}
.weekend {
  background-color: #606060;
}
.holiday {
  background-color: #8c7ae6;
}
.leave {
  background-color: #ff7553;
}
.text-others svg {
  color: #fff;
}

.popUpdateAttendArea {
  padding: 16px 16px;
  border-radius: 8px;
  width: 250px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
}

.popUpdateAttendArea .MuiSvgIcon-root.MuiSelect-icon {
  color: rgba(0, 0, 0, 0.6);
}

.popUpdateAttendArea
  .formControl
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-shrink {
  background-color: transparent;
  color: #707070 !important;
  transform: translate(14px, -10px) scale(0.75);
}

.popUpdateAttendArea .formControl .MuiFormLabel-root.MuiInputLabel-root {
  font-size: 14px;
  line-height: 34px;
  font-family: Inter;
  color: #707070 !important;
  transform: translate(14px, 4px);
}

.popAtndHead {
  font-size: 18px !important;
  font-family: Inter;
  color: #000 !important;
  font-weight: 600;
}
.popAtndFoot {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
}
.popAtndUsrDtl {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-family: "Inter";
}
.attendanceDatePicker {
  cursor: pointer;
}
.attendanceDatePicker .react-datepicker__month .react-datepicker__month-text,
.attendanceDatePicker .react-datepicker__month .react-datepicker__quarter-text {
  display: inline-flex;
  width: 3rem;
  margin: 2px;
  height: 2rem;
  align-items: center;
  justify-content: center;
}
.attendanceDatePicker .react-datepicker__month-text--today {
  color: #ff9900;
}
.attendanceDatePicker .react-datepicker__month-text--selected {
  color: #000;
}

.indUsrAttend {
  background-color: #101010;
  background-image: url(../images/art.png);
  height: auto;
  width: calc(100% - 20px);
  background-position: top right;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.usrAttendInfoImg {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.usrInfo {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
  flex-direction: column;
  font-size: 14px;
  font-family: "Inter";
  color: #b5b5b5;
}
.indUsrAttend .userNm {
  font-family: "Russo One", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
  letter-spacing: 1px;
}
.usrAttendInfoImg .usrImg {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}
.usrAttendInfoImg .usrImg img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.dnSlip {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  flex-direction: row;
  font-size: 14px;
  font-family: "Inter";
  color: #b5b5b5;
}
.dnSlip .MuiIconButton-root {
  color: #fff;
}
.dnPayShilp {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 4px;
  flex-direction: column;
}
.dnSlip .MuiButton-root.MuiButton-outlined {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  background: transparent;
  padding: 6px 10px;
  border-radius: 4px;
  border: 1px solid #4c4c4c;
  display: flex;
  align-items: center;
  color: #a2a2a2;
  height: 40px;
  width: 158px;
  transition: all 0.8s;
}

.dnSlip .MuiButton-root.MuiButton-outlined:hover {
  background: #333;
  border: 1px solid #333;
  color: #fff;
}
.MuiFormLabel-root.MuiInputLabel-root.attndInpLabl {
  font-size: 12px;
  font-family: "Inter";
  color: #b5b5b5;
  font-weight: 500;
  text-transform: capitalize;
}
.attndTabText {
  padding: 10px 2px;
  font-size: 14px;
  color: #b2b2b2;
}
.attndTabText.remarkAttend {
  font-size: 12px;
  font-family: "Inter";
  color: #b5b5b5;
  font-weight: 500;
  text-transform: capitalize;
}
.attndRemark {
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  color: #fff;
}
.remarkAttend {
  width: 100%;
  max-width: 300px;
}
.fwModelHeading {
  font-size: 22px;
  font-family: "Inter";
  color: #000;
  font-weight: 600;
}

.emailerPreview {
  width: 600px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.autoWidth {
  width: auto !important;
}
.emailerPreviewImgContainer {
  width: 600px;
  height: 200px;
}
.emailerPreviewImgContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.emailerContain {
  padding: 20px;
  width: calc(100% - 40px);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
}
.emGrttext {
  text-align: left;
  font-size: 18px !important;
  color: #000;
  font-weight: 600;
}
.emText {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  font-family: "Inter";
  text-align: justify;
}
.width120 {
  max-width: 120px !important;
}
.mxW100-2 {
  max-width: calc(50% - 4px) !important;
}
.elementFormContainer.WorkingDaysHours {
  gap: 10px;
}
.WorkingDaysHours .formElementGrp {
  flex-wrap: wrap;
  border-bottom: 1px solid #545454;
  padding-bottom: 6px;
}

.WorkingDaysHours
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  left: -46px;
}
.addPosBtnPrm {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #fff;
  opacity: 1;
  text-overflow: ellipsis;
}
.addNewPosition {
  width: calc((100% - 12px) / 3) !important;
}
/* .addNewPosition .MuiButtonBase-root.MuiButton-root {
  width: 194px !important;
} */
.formElement .formDatePicker.onlyTime {
  padding: 8px 12px 8px 12px;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: 1px solid #707070;
  outline: none;
  border-radius: 4px;
  width: calc(100% - 24px);
  background-color: #333333;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: 26px !important;
  line-height: 26px;
}
.daysName {
  max-width: 100% !important;
  width: 100%;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
}
.copyIcon {
  position: relative;
}
.copyIcon .MuiButtonBase-root.MuiIconButton-root svg {
  color: #b2b2b2;
}

.commentsUpdateAttendance {
  border-radius: 4px;
  height: 26px;
  /* width: 20px; */
  padding: 5px;
  margin-top: 5px;
  border: 1px solid darkgrey;
}
.comDtPicker {
  padding: 8px 12px 8px 42px;
  color: #333333;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: 1px solid #707070;
  outline: none;
  border-radius: 4px;
  width: 100%;
  background-color: #ffffff;
}

.comDtPicker
  .react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.comDtPicker
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.comDtPicker
  .react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::after,
.comDtPicker
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -100px;
}

.formElementRow {
  display: flex;
  gap: 30px;
  align-items: center;
}
.WorkingDaysHours
  .formElement
  .MuiSelect-select.MuiSelect-outlined.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: rgb(255 255 255 / 60%);
}
.fromcardHighlight {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 20px;
  border-bottom: 1px solid #333;
  padding-bottom: 14px;
}
.shiftElemHeding {
  font-size: 20px;
  color: #fff;
  margin-bottom: 6px;
  margin-top: 30px;
  width: 100%;
  padding: 4px 10px;
  background-color: #1c1c1c;
}
.shiftCardGrp {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 92%;
  padding-bottom: 6px;
  margin-bottom: 10px;
  border-bottom: 1px dashed #333;
}
.shiftCheader {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 2px;
  width: 100%;
}
.shiftTitleWIndex {
  color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  font-size: 16px;
}
.MuiButtonBase-root.MuiIconButton-root.removeShiftBtn {
  background-color: #262626;
  color: #f79191;
}
.shiftInfoDtlGrp {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.shiftInfoDtlGrp .formElement {
  width: calc(100% / 2);
}
.shiftTimerGrp {
  display: flex;
  align-items: center;
  margin-left: 20px;
  justify-content: flex-start;
  gap: 10px;
  color: rgba(255, 255, 255, 0.6);
  width: 130px;
}
.defultShiftPlace {
  color: #f57c00;
  margin-left: 26px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  background-color: #1c1c1c;
  border-radius: 6px;
  padding: 6px 14px;
}
.width23 {
  width: calc((100% - 1px) * 2 / 3);
}
.salGropuHead.darkrow {
  font-weight: 600 !important;
  font-size: 16px !important;
  background-color: #0d0d0d !important;
}
.payrollEditor .tskElemHeding {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 1;
}
.bbmp12 {
  width: 100%;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px dashed #545454;
}

.formTextRight .formTextFieldArea .MuiInputBase-root.MuiOutlinedInput-root {
  height: 42px;
  background-color: #333333;
  padding: 10px 8px 10px 12px;
}

.formTextRight
  .formTextFieldArea
  .MuiInputBase-root.MuiOutlinedInput-root
  .MuiInputBase-input.MuiOutlinedInput-input {
  padding: 2px !important;
  font-weight: 600;
  text-align: right;
  background-color: #333333 !important;
}
.jcCenter {
  justify-content: center !important;
}
.mb50 {
  margin-bottom: 50px;
}
.shwithConfig {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.shwithConfig .MuiButtonBase-root.MuiIconButton-root svg {
  font-size: 16px;
  height: 16px;
  width: 16px;
  fill: #fff;
}
.emoVeryLow {
  color: #ff5136;
}
.emolow {
  color: #ffa136;
}
.emonormal {
  color: #fff23a;
}
.emohigh {
  color: #beff3d;
}
.emoveryHigh {
  color: #4ceb14;
}

/* priview invoice start */
.orgDocHedInfo.invoiceHeadElement {
  gap: 2px;
}
.invoTxtSpan {
  width: 100px;
  text-align: right;
  opacity: 0.7;
}
.invoTxthighlight {
  min-width: 100px;
  text-align: left;
}
.invoiceBuyerSellerDtl {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  border-bottom: 0.5px dashed #7a7a7a;
  padding-bottom: 6px;
}
.buySellInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
  padding-bottom: 6px;
}
.bsdtlHed {
  font-size: 12px;
  color: #000;
  opacity: 0.6;
}
.bsname {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}
.bsAddress,
.bsEmail,
.bsGst {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: #000;
}
.invo-Table th {
  background-color: #dfdfdf;
}
table.invo-Table,
.invo-Table th,
.invo-Table td {
  border: 1px solid #1c1c1c;
  border-collapse: collapse;
  vertical-align: top;
  padding: 2px;
}
table.invo-Table,
.invoiceTable {
  width: 100%;
}
.invotext {
  font-size: 12px;
  color: #666666;
  text-align: center;
}
.invoAmount {
  font-size: 14px;
  font-weight: 500;
  text-align: right;
  color: #000;
}
.taxValu,
.dicValu,
.proDName {
  font-size: 14px;
  color: #000;
}
.invotext.productInfoDtl {
  text-align: left;
}
.subtotalTD {
  background-color: #f2f2f2;
}
.subtotalAmount,
.subtotalText {
  text-align: right;
}
.invoCurInfoGrp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 6px 0px;
}
.curnvyInfo {
  font-style: italic;
  font-size: 12px;
  color: #000;
}
.totalText {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.invoTotalAmount {
  background-color: #d4d4d4;
  vertical-align: middle !important;
}
.totalAmount {
  font-size: 14px;
  font-weight: 700;
  text-align: right;
}
.amountWord {
  text-align: right;
  font-style: italic;
}
.invoiceOtherInfoGrp {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}
.invoOtheDtlHeading {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 6px;
  font-style: italic;
}
.invoInfoitem {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}
.invoInfoitem span.text {
  min-width: 90px;
  font-size: 14px;
  color: #545454;
}
.invoInfoitem span.value {
  font-size: 14px;
  color: #000000;
}
.invoTramsC ul {
  font-size: 14px;
  color: #000;
  list-style-type: decimal;
  margin: 0px;
}
.invoiceOtherInfoGrp .orgDocHedInfoElement {
  color: #000000;
}
/* priview invoice end */
.w80 {
  width: 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
}
.w100 {
  width: 100px !important;
  max-width: 100px !important;
  min-width: 100px !important;
}
.inputPadding .formTextFieldArea input {
  padding: 8px 12px 8px 12px;
}

.inputPadding input:disabled {
  -webkit-text-fill-color: #ffffff !important;
  cursor: not-allowed;
}
.w180 {
  width: 180px !important;
  max-width: 180px !important;
  min-width: 180px !important;
}
.adHeaderWthBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}
.w60Per {
  width: 60% !important;
}
.w40Per {
  width: 40% !important;
}
.crtElmGrpTitle {
  text-align: center;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
}
.crtElmGrp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 14px;
  color: #ffffff;
  font-weight: 400;
  row-gap: 1px;
  column-gap: 10px;
}
.crtindEllm {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
}
span.shp {
  background-color: #00008b;
  height: 10px;
  width: 10px;
  display: block;
  border: 0.5px solid #b2b2b2;
}
span.smp {
  background-color: #4682b4;
  height: 10px;
  width: 10px;
  display: block;
  border: 0.5px solid #b2b2b2;
}
span.slp {
  background-color: #add8e6;
  height: 10px;
  width: 10px;
  display: block;
  border: 0.5px solid #b2b2b2;
}
span.hhp {
  background-color: #186126;
  height: 10px;
  width: 10px;
  display: block;
  border: 0.5px solid #b2b2b2;
}
span.hmp {
  background-color: #53992b;
  height: 10px;
  width: 10px;
  display: block;
  border: 0.5px solid #b2b2b2;
}
span.hlp {
  background-color: #a9da39;
  height: 10px;
  width: 10px;
  display: block;
  border: 0.5px solid #b2b2b2;
}
.opFulScrn {
  padding: 4px 10px;
  border-radius: 0px;
}
.MuiIconButton-root.openFullScrnIcon {
  height: 42px;
  width: 42px;
  background-color: #333333;
  border: 1px solid #545454;
}
.MuiIconButton-root.openFullScrnIcon svg {
  color: #b2b2b2;
}
.insideAnDashboard {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}
.insideAnDashboard .leftMenu {
  background-color: #292929;
  padding: 10px;
  border-radius: 8px;
  height: calc(100% - 22px);
  min-width: 300px;
  border: 1px solid #545454;
}
.insideAnDashboard .leftMenu ul {
  padding-inline-start: 0px;
  list-style-type: none;
  margin: 0px;
}
.insideAnDashboard .leftMenu ul .indSideMenu ul {
  padding-inline-start: 6px;
  list-style-type: none;
  margin: 0px;
}
li.indSideMenu {
  padding: 8px 4px;
  cursor: pointer;
  transition: all 0.6s;
  margin-bottom: 10px;
  border-radius: 6px;
  color: #959595;
}
li.indSideMenu:hover {
  background-color: #444444;
}
li.activeSM {
  background-color: #545454;
}
li.indSideMenu:first-child {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.menuName {
  color: #cccccc;
  font-size: 16px;
  font-weight: 400;
  padding: 10px;
  margin-bottom: 6px;
}
.activeSM {
  color: #ff9800 !important;
  font-weight: 600 !important;
}
.rightDetailsAnContain {
  width: calc(100% - 342px);
  height: 100%;
}
.overViewConatiner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.overviewCard {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 8px;
}
.overviewCardInd {
  color: #fff;
  background-color: #292929;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  border: 1px solid #545454;
}
.ovCardVI {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}
.ovCardValue {
  font-size: 2.4em;
}
.ovCardIcons {
  height: 40px;
  width: 40px;
  background-color: #545454;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  box-shadow: rgb(11 11 11) -2px -3px 25px -5px,
    rgb(46 46 46) 0px 11px 10px -5px;
  border: 1px solid #545454;
}
.docStatOnline {
  background-color: #39cb58;
  height: 12px;
  width: 12px;
  border-radius: 100%;
  box-shadow: #000000cf 0 -1px 7px 1px, inset #1f9400 0 -1px 9px,
    #00af00 0 2px 12px;
}
.docStatOfline {
  background-color: #ffa4a4;
  height: 12px;
  width: 12px;
  border-radius: 100%;
  box-shadow: #000000cf 0 -1px 7px 1px, inset #aa0000 0 -1px 9px,
    #940000 0 2px 12px;
}
.detailsAnalyticTableContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #292929;
  border-radius: 8px;
  border: 1px solid #545454;
  height: calc(100% - 42px);
}
.detailsAnalyticTableContainer .w110 {
  width: 110px;
  min-width: 110px !important;
  max-width: 130px;
}
.SpecialityFilter {
  min-width: 220px !important;
}
.overViewConatiner .anDashIndCard.manageWidth50 {
  width: 100%;
  height: 100%;
}
.activeMenuDashbord {
  background-color: #444444;
}
.indSideMenu ul {
  display: none;
}
.indSideMenu.activeMenuDashbord ul {
  display: block;
  margin-top: 8px;
}
.detailsAnalyticTableContainer
  .taskListtableHeader
  span
  .filterActive.MuiButtonBase-root.MuiIconButton-root {
  color: #cccccc;
}
.aqacGrp {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  margin-left: -9px;
  color: #b2b2b2;
  margin-bottom: -10px;
  margin-top: 2px;
}
.aqacInd:first-child {
  border-top: 1px solid #545454;
  border-right: 1px solid #545454;
  width: calc(50% - -3px);
  background-color: #333333;
  cursor: pointer;
}
.aqacInd:last-child {
  border-top: 1px solid #545454;
  width: calc(50% + 9px);
  background-color: #505050;
  cursor: pointer;
}
.anDashCardContainArea .listOfDataConst ul li .lstDataInd .lstValue {
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  flex: 1 1;
  padding: 8px 8px;
  min-height: 20px;
  cursor: pointer;
}
.anDashCardContainArea .listOfDataConst ul li .lstDataInd .lstName {
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  flex: 30% 1;
}
.anDashCardContainArea .todayIndc {
  font-size: 18px;
}
.blink-soft {
  animation: blinker 1.4s linear infinite;
  background-color: #c30000 !important;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.widthPmsManagerTableCol {
  width: "30%";
}
.totalTaskCount {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  color: #fff;
  opacity: 0.8;
}
.totalTaskCount .tskValue {
  font-weight: 600;
  opacity: 1;
}
.ttlTskList {
  position: relative;
  margin-bottom: 10px;
  background-color: #bbbbbb0a;
  padding: 6px 4px;
  border-radius: 6px;
}
.ttlTskList ul {
  margin: 0;
  padding-inline-start: 0;
  list-style-type: none;
  color: #dddddd;
  font-size: 14px;
  font-weight: 400;
}
.ttlTskList ul li {
  margin-bottom: 2px;
}
.ttlTskInd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-bottom: 1px solid #353535;
  padding-bottom: 2px;
}
.ttlTskInd .taskValue {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
.valperc {
  background-color: #404040;
  padding: 2px 6px;
  border-radius: 14px;
  min-width: 50px;
  text-align: right;
}
.valCount {
  color: #fff;
  font-weight: 600;
}
.tskCatg {
  position: absolute;
  transform: rotate(270deg);
  color: #b2b2b2;
  background-color: #292929;
  font-size: 12px;
  font-weight: 400;
  left: -41px;
  top: 45%;
  width: 60px;
  text-align: center;
}
.ttlTskList::before {
  position: absolute;
  content: "";
  border-left: 1px solid #545454;
  height: 100%;
  left: -10px;
}
.listOfTaskData {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.cardFrom .formControl.defultFrmHeight .MuiSelect-select.MuiSelect-outlined {
  padding: 8px 32px 8px 12px;
}

.formControl .MuiSelect-select.MuiSelect-outlined.Mui-disabled {
  -webkit-text-fill-color: #707070 !important;
  cursor: not-allowed;
}

.elementPreviewContainer {
  overflow: hidden !important;
  overflow-y: overlay !important;
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  display: flex;
  background-color: #1c1c1c;
  padding: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 6px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.elePreHeader {
  width: 100%;
}
.elePreHeading {
  text-align: center;
  font-size: 20px;
  color: #dddddd;
  text-transform: uppercase;
}
.employDtlDivGrp {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}
.eleLetSub {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  font-style: italic;
  text-decoration: underline;
  margin-bottom: 20px;
}
.elesubHed {
  margin-right: 6px;
  opacity: 0.7;
}
.containBody {
  display: flex;
  width: 100%;
  font-size: 14px;
  color: #fff;
  margin-bottom: 10px;
}

.signDoc {
  height: 40px;
  width: auto;
}
.signDoc img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.AnalyticsDashboardContainerArea
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after,
.AnalyticsDashboardContainerArea
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  left: -16px;
}
.minWidth155 {
  min-width: 155px !important;
}
.shiftColFlex {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
}

.shiftStartEndInfoGrp {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.shiftStartEndInfoGrp .shiftTimer {
  display: flex;
  align-items: center;
  gap: 4px;
}

.shiftStartEndInfoGrp .shiftTimerGrp {
  width: auto;
}

.shiftStartEndInfoGrp .shiftTimer span {
  display: block;
  position: relative;
  min-width: 70px;
  width: max-content;
}

.op7 svg {
  opacity: 0.6;
}
